import React , {useState} from "react";
import { formServices } from "../../../services/formServices";
import Select from "react-select"

const PostalForm = () => {
    const [refnumber, setRefnumber] = useState("")
    const [fromtitle, setFromtitle] = useState("")
    const [totitle, setTotitle] = useState("")
    const [date, setDate] = useState("")
    const [todate, setTodate] = useState("")
    const [note, setNote] = useState("");
    const [postal, setPostal] = useState("")

    const handlePost = () => {
        return formServices.postalPost(refnumber, fromtitle , totitle , date , todate , note , postal)
    }

    const actions = [
        {label: 'Demo 1', value: 'demo1'},
        {label: 'Demo 2', value: 'demo2'},
        {label: 'Demo 3', value: 'demo3'},
    ]
    return (
        <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-md">
            <p className="text-xl font-bold gg-erp-color ">ADD POSTAL RECEIVED SENT</p>

            <div className="grid lg:grid-cols-2 gap-2">
                <div className="grid grid-cols-2 gap-1">
                    <label for="date" className="w-full py-2">Start Date
                        <input type="date" placeholder="dd-mm-yyyy" name="date" className="form-input" onChange={e => setDate(e.target.value)}/> 
                    </label>

                    <label for="to_date" className="w-full py-2">End Date
                        <input type="date" placeholder="dd-mm-yyyy" name="to_date" className="form-input" onChange={e => setTodate(e.target.value)}/>  
                    </label>
                </div>

                <label for="postal_type" className="w-full py-2">Postal Type
                <Select 
                options={actions} 
                placeholder="Postal Type" 
                isClearable={true} 
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 2,
                    colors: {
                      ...theme.colors,
                      primary25: 'lightCyan',
                      primary: '#ff5722',
                    },
                  })}
                />
                </label>
            </div>
                <label for="note" className="w-full py-2">Note
                    <textarea className="form-input" type="text" placeholder="Note" name="note" onChange={e => setNote(e.target.value)}/> 
                </label>

                <div className="text-right mt-2">
                    <button type="submit" className="submit w-full" onClick = {handlePost}>Submit</button>
                </div>
        </div>
    )
}

export default PostalForm