import React, {useState, useEffect} from "react";
import axios from "axios";
import { SERVER } from "../../../utils";
import Select from "react-select";
// import {formServices} from "../../../services/formServices"
import mdtoast from '@dmuy/toast'
import { authHeader } from "../../../helper/authHeader";

const CourseForm = ({updatetable, currentCourse, requesttype, setRequesttype, setCurrentCourse}) => {

    // const [course, setCourse] = useState("")
    // const [duration, setDuration] = useState("")
    // const url = SERVER + "api/v1/course"
    const[departments , setDepartments] = useState([])
    // const [department, setDepartment] = useState(null)

    const handlePost = async () => {
        const body = {
            course_code: currentCourse.code,
            course_name: currentCourse.name,
            department: currentCourse.department.value,
            status: true
        }
        try {
            if (requesttype == 'post') {
                console.log(body)
                // const url = SERVER + "/apiV1/course/"
                const res = await axios.post("/apiV1/course/", body, { headers: authHeader() });
                mdtoast.success("Course Successfully Created", { duration: 1000 })

            } else {
                console.log(body)
                // const url = SERVER + "/apiV1/course/" + currentCourse.id + "/"
                const res = await axios.put(`/apiV1/course/${currentCourse.id}/`, body, { headers: { 
                    ...authHeader(),      
                    'content-type': 'application/json',
                    'Accept': 'application/json' } });
                mdtoast.success("Course Successfully Edited", { duration: 1000 })
                setRequesttype("post")
            }
            setCurrentCourse({
                "name": "",
                "code": "",
                "department": "",
                status: true
            })

            updatetable()
        } catch (err) {
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }
    }

    useEffect(()=>{
        const getData = async () => {
            try{
                var res = await axios.get("/apiV1/department/" ,  { headers: authHeader() })
                var departmentlist = res.data.map((item)=>{
                    return {"label": item.department_name , "value" : item.id}
                })
                setDepartments(departmentlist)
            }catch(err){
                console.log(err)
            }
        }
        getData()
    } , [window.url])
    
    return(
        <div className="text-sm bg-white pt-4 px-5 pb-4 w-11/12 mx-auto rounded-md">
            <p className="text-xl font-bold gg-erp-color ">COURSE</p>
            <label for="" className="mb-2 w-full py-2">Course
                <input className="form-input" value={currentCourse.name} onChange={e => setCurrentCourse({...currentCourse, name: e.target.value})} placeholder="Course" type="text" name="religion"/>
            </label>
            <label for="department" className="w-full py-2">Select Department
                    <Select
                        options={departments}
                        value={currentCourse.department}
                        onChange={option => setCurrentCourse({...currentCourse,department: option})}
                        placeholder="Select"
                        isClearable={true}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                                ...theme.colors,
                                primary25: 'lightCyan',
                                primary: '#ff5722',
                            },
                        })}
                    />
                </label>
            <label for="" className="mb-2 w-full py-2">Course Code
                <input className="form-input" value={currentCourse.code} onChange={e => setCurrentCourse({...currentCourse, code: e.target.value})} placeholder="Course Code" type="text" name="religion"/>
            </label>

            <hr/>
            <div className="text-right mt-2">
                <button type="submit" onClick={handlePost} className="submit">Submit</button>
            </div>
        </div>
    )
}

export default CourseForm