import React , {useEffect , useState} from "react";
import AddForm from "../../components/forms/Inventory/Add";
import Navbar from "../../components/navbar-components/NavBar"
import Footer from "../../components/Footer"
import Table from "../../components/Table";
import axios from "axios";
import { SERVER } from "../../utils";
import mdtoast from '@dmuy/toast'
import { useDispatch  } from 'react-redux';
import { setSessionLoaded } from "../../redux/info";
import { authHeader } from "../../helper/authHeader"
// import Switch from "react-switch";
// import Empty from "../../components/Empty";

const AddItem = () => {
    const dispatch = useDispatch()

    const [tableItems , setTableItems] = useState([
        {id: null,"Item Name": 0, "Quantity": 0, "Selling Price": 0, "Product Category": 0, "Status" : 0 , "Action" : 0 , empty:true },
    ])

    const [productcat , setProductcat] = useState([]);

    const [requesttype , setRequesttype] = useState("post")
    const[currentsession , setCurrentsession] = useState({
        'id' : null,
        'name' : '',
        'quantity' : '',
        'selling_price' : '',
        'description' : "",
        'product_category' : '',
        'status' : true
    })
 
    const updatetable = async ()=>{
        var productlist = []
        try{
            var res = await axios.get("api/v1/category" , { headers:authHeader()});
            console.log(res.data)
            if (res.data.length>0){
                setProductcat(res.data)
                productlist = res.data
            }
        }catch(err){
            console.log(err);
        }

        try{
            var res = await axios.get("/api/v1/product" , { headers:authHeader()});
            console.log(res.data)
            console.log("product")
            if (res.data.length>0){
                let count = 0
                var newtable = res.data.map(item=>{
                    count++
                    var x = "";
                    productlist.forEach((cat)=>{
                        if(item.product_category==cat.id){
                            x= cat.name
                        }

                    })
                    return {id: item.id,"Sr. No": count,"Item Name": item.name, "Quantity": item.quantity, "Selling Price": item.selling_price, "Product Category": x, "Description" : item.description , "Status" : item.status , "Action" : "action" }
                })
                setTableItems(newtable)
                dispatch(setSessionLoaded())
            }
        }catch(err){
            console.log(err);
        }
    }

    const EditFunction =  async (item)=>{
    setRequesttype("put");
    setCurrentsession({
        'id' : item.id,
        'name' : item["Item Name"],
        'quantity' : item["Quantity"],
        'selling_price' : item['Selling Price'],
        'description' : item["Description"],
        'product_category' : item['Product Category'],
        'status' : item["Status"]
    })
    }

    const DeleteFunction =  async (id)=>{
        // console.log(id + "has been deleted")
        try{
            // const url = SERVER + "/apiV1/session/" + id
            const res = await axios.delete(`/api/v1/product/${id}/`, { headers: authHeader() });
            mdtoast.success("Session Deleted Successfully ", { duration: 1000 })
            setTableItems(tableItems.filter((item)=>item.id !== id))
        }catch (err) {
            console.log(err)
            mdtoast.error("Sessions are dependent and cannot be deleted", { duration: 1000 })
        }
    }

    const current = async (id) => {
        try{
            // const url = SERVER +  + id + "/"
            const res = await axios.get(`/apiV1/active-session/${id}/`, { headers: authHeader() });
            mdtoast.success("Session Updated Successfully", { duration: 1000 })
            updatetable()
        }catch (err){
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }
    }

    useEffect(async ()=>{
        updatetable()
    },[window.url])

    return (
        <>
        <Navbar/>
        <div className="bg-gray-200">
            <div className="mx-auto md:flex md:w-11/12 justify-center bg-gray-200 py-5">
                <div className="md:w-3/12 flex-shrink-0 flex-grow-0">
                    <AddForm updatetable={updatetable} currentsession = {currentsession} requesttype ={requesttype} setCurrentsession = {setCurrentsession} setRequesttype = {setRequesttype} productcat={productcat}/>
                </div>
                <div className="bg-white flex-grow-0 py-3 w-11/12 md:w-9/12 mx-auto rounded-b-md md:rounded-md">
                    <p className="text-xl pb-4 pt-2 w-11/12 mx-auto font-bold gg-erp-color ">ITEM LIST</p>
                    <div className="w-full">
                        <Table tableItems={tableItems} current={current} EditFunction={EditFunction} DeleteFunction={DeleteFunction}/>
                    </div> 
                </div>
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default AddItem