import React, {useState, useEffect} from "react";
import Footer from "../../components/Footer";
import ItemCatForm from "../../components/forms/Inventory/ItemCategory";
import NavBar from "../../components/navbar-components/NavBar";
import Table from "../../components/Table"
import axios from "axios";
import { SERVER } from "../../utils";
import mdtoast from '@dmuy/toast'
import { authHeader } from "../../helper/authHeader";
 
// const tableItems = [
//     {"Name": null,"status": null, empty: true} 
// ]
 
const ItemCategory = () => {

    const [tableItems , setTableItems] = useState([
        {"Item Category": null,"status": null, empty: true} 
    ])

    const [requesttype , setRequesttype] = useState("post")
    const[currentCategory , setCurrentCategory] = useState({
        itemCat: "",
        status: true
    })

    const updatetable = async ()=>{
        try{
            var res = await axios.get("/api/v1/category", { headers: authHeader() });
            console.log(res.data)
            let count = 0

            if (res.data.length>0){
                var newtable = res.data.map(item=>{
                    count++
                    return {"id": item.id,"Sr. No":count, "Item Category": item.name, "Status" : item.name , "Action" : "Action" }
                }) 
                setTableItems(newtable)
                console.log(newtable)
            }
        }catch(err){
            console.log(err);
        } 
    }

    const EditFunction =  async (item) => {
    setRequesttype("put");
    setCurrentCategory({
        'id' : item.id,
        'itemCat' : item['Item Category'],
        'status': true
    })
    }

    const DeleteFunction =  async (id)=>{
        // console.log(id + "has been deleted")
        try{
            // const url = SERVER + "/apiV1/designation/" + id + "/"
            const res = await axios.delete(`/api/v1/category/${id}`, { headers: authHeader() });
            mdtoast.success("Designation Deleted Successfully", { duration: 1000 })
            setTableItems(tableItems.filter((item)=>item.id !== id))
        }catch (err) {
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }
        
    }
 
    useEffect(async ()=>{
        updatetable()
    },[window.url])

    return(
        <>
        <NavBar/>
        <div className="bg-gray-200">
            <div className="mx-auto md:flex md:w-11/12 justify-center bg-gray-200 py-5">
                <div className="md:w-3/12 flex-shrink-0">
                        <ItemCatForm updatetable={updatetable}  requesttype={requesttype} setTableItems={setTableItems} setCurrentCategory={setCurrentCategory} currentCategory={currentCategory} setRequesttype={setRequesttype}/>
                </div>
                <div className="flex items-center bg-white py-3 w-11/12 md:w-9/12 mx-auto rounded-b-md md:rounded-md">
                    <div className="w-full">
                        <p className="text-xl font-bold gg-erp-color w-11/12 mx-auto pb-2">ITEM CATEGORY LIST</p>
                        <Table updatetable={updatetable} DeleteFunction={DeleteFunction} EditFunction={EditFunction} tableItems={tableItems}/>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        </>
    )
};

export default ItemCategory