import React, { useState, useEffect } from "react";
import BackDuesForm from "../../components/forms/Fee/BackDues";
import NavBar from "../../components/navbar-components/NavBar";
import Footer from "../../components/Footer";
import Table from "../../components/Table";
import mdtoast from "@dmuy/toast";
import { SERVER } from "../../utils";
import axios from "axios";
import Select from "react-select"
import Switch from "react-switch";

const AddIncome = () => {

    const [name, setName] = useState("");
    const [accounttype, setAccounttype] = useState("");
    const [accountsubhead, setAccountsubhead] = useState("");
    const [subheadlist, setSubheadlist] = useState([])
    const [payment, setPayment] = useState("")
    const [amount, setAmount] = useState("");
    const [date, setDate] = useState("");
    const [description, setDescription] = useState("");
    const [bank, setBank] = useState("");
    const[banklist, setBanklist] = useState([])
    const [paymentlist, setPaymentlist] = useState([]);
    const [status, setStatus] = useState(true)

    useEffect(() => {
        const getData = async () => {
            try {
                var res = await axios.get(SERVER + "/apiV1/accountsubhead/", { headers: { Authorization : `Basic cmFodWw6MTIzNDU2` } })
                var acclist = res.data.map((item) => {
                    return { "label": item.subhead, "value": item.id }
                })
                setSubheadlist(acclist)
                var res = await axios.get(SERVER + "/apiV1/paymentmethod", { headers: { Authorization : `Basic cmFodWw6MTIzNDU2` } })
                var methodlist = res.data.map((item) => {
                    return { "label": item.payment_method, "value": item.id }
                })
                setPaymentlist(methodlist)
                var res = await axios.get(SERVER + "/apiV1/bankaccount/", { headers: { Authorization : `Basic cmFodWw6MTIzNDU2` } })
                var bankl = res.data.map((item) => {
                    return { "label": item.account_name, "value": item.id }
                })
                setBanklist(bankl)
            } catch (err) {
                console.log(err)
            }
        }
        getData()
    }, [window.url])

    const handlePost = async () => {
        const body = {
            name: name,
            date: date,
            amount: amount,
            description: description,
            account_sub_head: accountsubhead.value,
            payment_method: payment.value,
            bank: bank.value,
            status: status,
            accounttype: accounttype
        }
        try {
            console.log(body)
            const url = SERVER + "/apiV1/income/"
            const res = await axios.post(url, body, { headers: { Authorization : `Basic cmFodWw6MTIzNDU2` } });
            mdtoast.success("Session Successfully Created", { duration: 1000 })
            setName("")
            setAccounttype("")
            setAccountsubhead("");
            setPayment("")
            setAmount("")
            setDate("")
            setDescription("")
            setBank("")

            // formServices.sessionPost(session, start, end, current);
            // updatetable()
        } catch (err) {
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }

    }
    return (
        <>
            <NavBar />
            <div className="bg-gray-200">
                <div className="mx-auto w-full bg-gray-200 py-5">
                    <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-md">
                        <p className="text-xl font-bold gg-erp-color ">ADD INCOME</p>
                        <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-2">
                            <label for="usr" className="w-full py-2">Name
                                <input className="form-input" type="text" placeholder="Enter Here..." name="usr" onChange={e => setName(e.target.value)} />
                            </label>

                            <label for="crse" className="w-full py-2">Acount Type
                                <input className="form-input" type="text" placeholder="Enter Here..." name="crse" onChange={e => setAccounttype(e.target.value)} />
                            </label>

                            <label for="department" className="w-full py-2">Select Account SubHead
                                <Select
                                    options={subheadlist}
                                    value={accountsubhead}
                                    onChange={option => setAccountsubhead(option)}
                                    placeholder="Select"
                                    isClearable={true}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 4,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'lightCyan',
                                            primary: '#ff5722',
                                        },
                                    })}
                                />
                            </label>

                            <label for="department" className="w-full py-2">Bank Name
                                <Select
                                    options={banklist}
                                    value={bank}
                                    onChange={option => setBank(option)}
                                    placeholder="Select"
                                    isClearable={true}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 4,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'lightCyan',
                                            primary: '#ff5722',
                                        },
                                    })}
                                />
                            </label>

                            <label for="department" className="w-full py-2">Select Payment Method
                                <Select
                                    options={paymentlist}
                                    value={payment}
                                    onChange={option => setPayment(option)}
                                    placeholder="Select"
                                    isClearable={true}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 4,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'lightCyan',
                                            primary: '#ff5722',
                                        },
                                    })}
                                />
                            </label>

                            <label for="adm_no" className="w-full py-2">Amount
                                <input className="form-input" type="number" placeholder="Enter Here..." name="adm_no" onChange={e => setAmount(e.target.value)} />
                            </label>

                            <label for="search" className="w-full py-2">Date
                                <input className="form-input" type="date" placeholder="Enter Here..." name="srch" onChange={e => setDate(e.target.value)} />
                            </label>
                            <label for="search" className="w-full py-2">Description
                                <input className="form-input" type="search" placeholder="Enter Here..." name="srch" onChange={e => setDescription(e.target.value)} />
                            </label>
                        

                        </div>
                        <label for="status" className="w-full py-2">
                                <span className="p-2">
                                    <Switch checkedIcon={null} activeBoxShadow={null} width={52} height={26} handleDiameter={21} uncheckedIcon={null} onChange={() => setStatus(!status)} checked={status} onColor="#ff5722" />
                                </span>

                                {/* <input type="checkbox" checked={status} onClick={() => setStatus(!status)}/> */}
                                <span className="px-2">Status</span>
                            </label>
                        <hr />
                        <div className=" text-center mt-2">
                            <button type="submit" className="submit w-full" onClick={handlePost}>Submit</button>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    )
}


export default AddIncome