import React , {useState} from "react";
import { formServices } from "../../../services/formServices";

const ExpenseCategoryForm = () => {
    const [category , setCategory] = useState("");

    const handlePost = () => {
        // return formServices.categoryPost(head)
    }

    return(
        <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-md">
            <p className="text-xl font-bold gg-erp-color ">ADD EXPENSE CATEGORY</p>
            <label for="religion" className="w-full py-2">Head
                <input className="form-input" placeholder="Expense Category Head" type="text" name="religion" onChange={e => setCategory(e.target.value)}/>
            </label>
            <hr/>
            <div className="text-right mt-2">
                <button type="submit" className="submit" onClick={handlePost}>Submit</button>
            </div>
        </div>
    )
}

export default ExpenseCategoryForm