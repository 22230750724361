import React , {useState} from "react";
import { formServices } from "../../../services/formServices";
import Select from "react-select";

const AccountHeadForm = ({updatetable}) => {
    const [accountname , setAccountname] = useState("");
    const [openingbalance , setOpeningbalance] = useState("");
    const [accounttype , setAccounttype] = useState("");
    const [note , setNote] = useState("");

    const handlePost = () => {
        try{
            // formServices.sessionPost(session, start, end, current);
            updatetable()
        }catch(err){
            console.log(err);
        } 
    }

    const accTypes = [
        {label: "Type 1", value: "it"},
        {label: "Type 2", value: "cse"},
        {label: "Type 3", value: "demo"}
    ]

    return(
        <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-md">
            <p className="text-xl font-bold gg-erp-color ">ADD ACCOUNT HEAD</p>
            <label for="religion" className="w-full py-2">Account Name
                <input className="form-input" placeholder="Account Name" type="text" name="religion" onChange={e => setAccountname(e.target.value)}/>
            </label>
            <label for="religion" className="w-full py-2">Opening Balance
                <input className="form-input" placeholder="Opening Balance" type="text" name="religion" onChange={e => setOpeningbalance(e.target.value)}/>
            </label>
            <label for="religion" className="w-full py-2">Account Type
            <Select 
                options={accTypes}
                value={accounttype}
                onChange = {(option)=>setAccounttype(option)}
                placeholder="Select Account Type" 
                isClearable={true} 
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                    ...theme.colors,
                    primary25: 'lightCyan',
                    primary: '#ff5722',
                    },
                })}
            />
            </label>
            <label for="religion" className="w-full py-2">Note
                <input className="form-input" placeholder="" type="text" name="religion" onChange={e => setNote(e.target.value)}/>
            </label>
            <hr/>
            <div className="text-right mt-2">
                <button type="submit" className="submit" onClick={handlePost}>Submit</button>
            </div>
        </div>
    )
}

export default AccountHeadForm