import React, {useState, useEffect} from "react";
import Footer from "../../components/Footer";
import DepartmentForm from "../../components/forms/Academic/Department";
import NavBar from "../../components/navbar-components/NavBar";
import Table from "../../components/Table"
import axios from "axios";
import { SERVER } from "../../utils";
import mdtoast from '@dmuy/toast'
import { authHeader } from "../../helper/authHeader";


const tableItems = [
    {"Name": null,"status": null, empty: true} 
]
 
const Department = () => {

    const [tableItems , setTableItems] = useState([
        {"Department Name": null,"status": null, empty: true} 
    ])

    const [requesttype , setRequesttype] = useState("post")
    const[currentDepartment , setCurrentDepartment] = useState({
        department: "",
        status: true
    })

    const updatetable = async ()=>{
        try{
            var res = await axios.get("/apiV1/department/" , { headers: authHeader() });
            console.log(res.data)
            if (res.data.length>0){
                var newtable = res.data.map(item=>{
                    return {"id": item.id, "Department Name": item.department_name, "Status" : item.status , "Action" : "Action" }
                })
                setTableItems(newtable)
                console.log(newtable)
            }
        }catch(err){
            console.log(err);
        }
    }

    const EditFunction =  async (item) => {
    setRequesttype("put");
    setCurrentDepartment({
        'id' : item.id,
        'department' : item['Department Name'],
        'status': true
    })
    }

    const DeleteFunction =  async (id)=>{
        // console.log(id + "has been deleted")
        try{
            // const url = SERVER + "/apiV1/department/" + id + "/"
            const res = await axios.delete(`/apiV1/department/${id}/`, { headers: authHeader() });
            mdtoast.success("Department Deleted Successfully ", { duration: 1000 })
            // setTableItems(tableItems.filter((item)=>item.id !== id))
            updatetable()
        }catch (err) {
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }
        
    }

    useEffect(async ()=>{
        updatetable()
    },[window.url])

    return(
        <>
        <NavBar/>
        <div className="bg-gray-200">
            <div className="mx-auto md:flex md:w-11/12 justify-center bg-gray-200 py-5">
                <div className="md:w-3/12 flex-shrink-0">
                        <DepartmentForm updatetable={updatetable}  requesttype={requesttype} setTableItems={setTableItems} setCurrentDepartment={setCurrentDepartment} currentDepartment={currentDepartment} setRequesttype={setRequesttype}/>
                </div>
                <div className="flex items-center bg-white py-3 w-11/12 md:w-9/12 mx-auto rounded-b-md md:rounded-md">
                    <div className="w-full">
                        <Table updatetable={updatetable} DeleteFunction={DeleteFunction} EditFunction={EditFunction} tableItems={tableItems}/>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        </>
    )
};

export default Department