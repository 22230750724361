import React , {useState , useEffect} from "react";
import NavBar from "../../components/navbar-components/NavBar";
import Footer from "../../components/Footer";
import Table from "../../components/Table";
import { authHeader } from "../../helper/authHeader";
import axios from "axios";
import { useHistory } from "react-router";
import mdtoast from "@dmuy/toast";

const Shop = () => {
    const history = useHistory()

    const handlePost = async () => {
        const body = currentshop
        try {
                const res = await axios.put("api/v1/shop/1", body, { headers: authHeader() });
                mdtoast.success("Shop Information Successfully Added", { duration: 1000 })
                // history.push("/dashboard")
        } catch (err) {
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }

    }

    const handleImages = async () => {
        const body = new FormData()
        body.append("name", currentshop.name)
        body.append("owmer_name", currentshop.owmer_name)
        body.append("owner_contact", currentshop.owner_contact)
        body.append("address", currentshop.address)
        if ( images.logo !== null && images.logo !== ""){
            body.append("logo",images.logo)
        }
        if ( images.back_img !== null && images.back_img !== ""){
            body.append("back_img",images.back_img)
        }
        try {
            const res = await axios.put("api/v1/shop/1", body, { headers: authHeader() });
            mdtoast.success("Shop Image Successfully Added", { duration: 1000 })
            // history.push("/dashboard")
        } catch (err) {
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }
    }

    useEffect(()=>{
        const getData = async () => {
            try{
                var res = await axios.get("/api/v1/shop/1", { headers: authHeader() });
                console.log(res.data)
                 setCurrentshop(res.data)
            }catch(err){
                console.log(err);
            } 
        }
        getData()
        
    },[window.url])

    const[currentshop , setCurrentshop] = useState({
        "name": "",
        "address": "",
        "owmer_name": "",
        "owner_contact": 0,
    })

    const [images,setImages] = useState({
        "logo": null,
        "back_img": null
    })

    return (
        <>
        <NavBar/>
        <div className="bg-gray-200">
            <div className="mx-auto w-full bg-gray-200 py-5">
                <div className="mx-auto w-11/12 bg-white pt-2 rounded">
                    <p className="w-11/12 mx-auto text-2xl font-bold gg-erp-color py-4 pt-4">SHOP</p>
                    <div className="w-11/12 grid md:grid-cols-2 gap-2 mx-auto">
                        <label for="text" className="py-2 w-full">Name
                            <input type="text" value={currentshop.name} onChange={(e)=>{setCurrentshop({...currentshop , "name" : e.target.value})}} name="sur" className=" mt-2  form-input p-1" />
                        </label>
                        <label for="text" className="py-2 w-full">Owner Name
                            <input type="text" name="sur" className="form-input mt-2  p-1" value={currentshop.owmer_name} onChange={(e)=>{setCurrentshop({...currentshop , "owmer_name" : e.target.value})}} />
                        </label>
                        <label for="text" className="py-2 w-full">Owner Contact
                            <input type="number" name="sur" className="form-input  mt-2  p-1" value={currentshop.owner_contact} onChange={(e)=>{setCurrentshop({...currentshop , "owner_contact" : e.target.value})}} type="number" />
                        </label>
                        <label for="text" className="py-2 w-full">Address
                        <textarea type="text" name="sur" className="form-input mt-2 p-1" value={currentshop.address} onChange={(e)=>{setCurrentshop({...currentshop , "address" : e.target.value})}} />
                        </label>
                    </div>
                    <hr className="w-11/12 mt-4 mx-auto"/>
                    <div className="w-11/12 mx-auto text-right mt-2 ">
                            <button onClick={handlePost} type="submit" className="submit text-xs px-3">Submit</button>
                    </div>
                    <p className="w-11/12 mx-auto text-xl font-bold gg-erp-color py-4 pt-4">IMAGES</p>
                    <div className="w-11/12 mx-auto grid md:grid-cols-2 gap-2 gg-erp-color font-semibold">
                        <label for="file" className="py-2 w-full mb-12">Shop Logo
                            <input type="file" name="sur" className="w-full mt-2 border-0 font-normal text-black text-sm my-2" onChange={(e)=>{setImages({...images , "logo" : e.target.files[0]})}} />
                        </label>
                        <label for="file" className="py-2 w-full mb-12">Background Image
                            <input type="file" name="sur" className="w-full mt-2  border-0 font-normal text-black text-sm my-2" onChange={(e)=>{setImages({...images , "back_img" : e.target.files[0]})}} />
                        </label>
                    </div>
                    <hr className="w-11/12 mx-auto"/>
                    <div className="w-11/12 mx-auto text-right mt-2 pb-6">
                            <button onClick={handleImages} type="submit" className="submit text-xs px-3">Submit</button>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        </> 
    )
}

export default Shop