import React from "react";
import { createPopper } from "@popperjs/core";
import { useSelector, useDispatch} from "react-redux";
import { useHistory } from "react-router";
import { loggedOut } from "../../redux/user";
import { userService } from "../../services/userServices";
import { Link } from "react-router-dom";

const UserDropdown = ({ color }) => {
  // dropdown props
  const dispatch = useDispatch()
  const history = useHistory()
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start"
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  // const {username} = useSelector(state => state.user)

  // const user = JSON.parse(localStorage.getItem('user')).user
  // const username = user.username
  // const role = user.roles.name
  // const mail = user.email
  const username = "demo"
  const role = "demo"
  const mail = "demo"

  const logout = () => {
    userService.logout()
    dispatch(loggedOut)
    history.push("/")
  }
  // bg colors
  let bgColor;
  color === "white"
    ? (bgColor = "bg-blueGray-700")
    : (bgColor = "bg-" + color + "-500");
  return (
    <>
      <div className="w-14">
        <div className="">
          <div className="relative inline-flex align-middle w-full">
            <button
              className="text-white text-xl  px-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
              type="button"
              ref={btnDropdownRef}
              onClick={() => {
                dropdownPopoverShow
                  ? closeDropdownPopover()
                  : openDropdownPopover();
              }}
            >
                <span><i className="bi bi-person-circle"></i><i className="bi bi-caret-down-fill ml-1 text-xs"></i></span>
            </button>
            <div
              ref={popoverDropdownRef}
              className={
                (dropdownPopoverShow ? "block " : "hidden ") +
                "text-base z-50 bg-white float-left py-2 list-none text-left rounded shadow-lg mt-1"
              }
              style={{ minWidth: "10rem" }}
            >
              <div
                className={
                  "text-sm px-4 font-normal block w-full whitespace-nowrap cursor-pointer hover:bg-blue-100"
                }
                onClick={e => e.preventDefault()}
              >
                <span className="font-bold">Hello, {username}</span><br/>
                <span><span className="font-bold">Role:</span> {role}</span>
              </div>
              <hr className="my-1"/>
              <div
                className={
                  "text-sm px-4 font-normal block w-full whitespace-nowrap cursor-pointer hover:bg-blue-100"
                }
                onClick={e => e.preventDefault()}
              >
                <span className="font-bold">Email: </span><span>{mail}</span>
              </div>
              <hr className="my-1"/>
              <Link
                to="/usercreate"
                className={
                  "text-sm px-4 py-1 hover:text-black font-normal block w-full whitespace-nowrap cursor-pointer hover:bg-blue-100"
                }
                // onClick={e => e.preventDefault()}
              >
                <i class="bi bi-person-plus-fill text-base"></i><span className="ml-2"> Create User </span>
              </Link>
              <hr className="my-1"/>
              <div className="h-0 cursor-pointer border border-solid border-t-0 border-blueGray-800 opacity-25" />
              <Link
                to="/editprofile"
                className={
                  "text-sm px-4 py-1 hover:text-black font-normal block w-full whitespace-nowrap cursor-pointer hover:bg-blue-100"
                }
                // onClick={e => e.preventDefault()}
              >
                <i class="bi bi-pencil-square"></i><span className="ml-2"> Edit Profile </span>
              </Link>
              <hr className="my-1"/>
              <div className="h-0 cursor-pointer border border-solid border-t-0 border-blueGray-800 opacity-25" />
              <div
                onClick={logout}
                className={
                  "hover:bg-blue-100 text-sm cursor-pointer py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent "
                }
              >
                <i class="bi bi-box-arrow-left mr-2"></i> Logout
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDropdown