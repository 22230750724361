import React, {useState, useEffect} from "react";
import Footer from "../../components/Footer";
import SemesterForm from "../../components/forms/Academic/Semester";
import NavBar from "../../components/navbar-components/NavBar";
import Table from "../../components/Table"
import axios from "axios";
import { SERVER } from "../../utils";
import mdtoast from '@dmuy/toast'
import { authHeader } from "../../helper/authHeader";

const tableItems = [
    {"Name": null,"status": null, empty: true} 
]
 
const Semester = () => {

    const [tableItems , setTableItems] = useState([
        {"Semester Name": null,"status": null, empty: true} 
    ])

    const [requesttype , setRequesttype] = useState("post")
    const[currentSemester , setCurrentSemester] = useState({
        semester: "",
        status: true
    })

    const updatetable = async ()=>{
        try{
            var res = await axios.get("/apiV1/semester/" , { headers: authHeader() });
            console.log(res.data)
            if (res.data.length>0){
                var newtable = res.data.map(item=>{
                    return {"id": item.id, "Semester Name": item.semester_name, "Status" : true , "Action" : "Action" }
                })
                setTableItems(newtable)
                // console.log(newtable)
            }
        }catch(err){
            console.log(err);
        }
    }

    const EditFunction =  async (item) => {
    setRequesttype("put");
    setCurrentSemester({
        'id' : item.id,
        'semester' : item['Semester Name'],
        'status': true
    })
    }

    const DeleteFunction =  async (id)=>{
        // console.log(id + "has been deleted")
        try{
            // const url = SERVER + "/apiV1/semester/" + id + "/"
            const res = await axios.delete(`/apiV1/semester/${id}/`, { headers: authHeader() });
            mdtoast.success("Semester Deleted Successfully ", { duration: 1000 })
            // setTableItems(tableItems.filter((item)=>item.id !== id))
            updatetable()
        }catch (err) {
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }
        
    }
 
    useEffect(async ()=>{
        updatetable()
    },[window.url])

    return(
        <>
        <NavBar/>
        <div className="bg-gray-200">
            <div className="mx-auto md:flex md:w-11/12 justify-center bg-gray-200 py-5">
                <div className="md:w-3/12 flex-shrink-0">
                        <SemesterForm updatetable={updatetable}  requesttype={requesttype} setTableItems={setTableItems} setCurrentSemester={setCurrentSemester} currentSemester={currentSemester} setRequesttype={setRequesttype}/>
                </div>
                <div className="flex items-center bg-white py-3 w-11/12 md:w-9/12 mx-auto rounded-b-md md:rounded-md">
                    <div className="w-full">
                        <Table updatetable={updatetable} DeleteFunction={DeleteFunction} EditFunction={EditFunction} tableItems={tableItems}/>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        </>
    )
};

export default Semester