import React, { useState, useEffect } from "react";
import NavBar from "../../components/navbar-components/NavBar";
import Footer from "../../components/Footer";
import CourseForm from "../../components/forms/Academic/Course"
import Table from "../../components/Table";
import axios from "axios";
import { SERVER } from "../../utils";
import mdtoast from '@dmuy/toast'
import { authHeader } from "../../helper/authHeader";

const tableItems = [
    {"Course Name": null, Duration: null, empty: true}
]
const Course = () => {

    const [tableItems , setTableItems] = useState([
        {"Course Name": 0, "Department": 0,"Code": 0, "Status" : 0 , "Action" : 0 , empty:true },
    ])

    const [requesttype , setRequesttype] = useState("post")
    const[currentCourse , setCurrentCourse] = useState({
        "name": "",
        "department": "",
        "status": "",
        "code": ""
    })

    const updatetable = async ()=>{
        try{
            var res = await axios.get("/apiV1/course" , { headers: authHeader() });
            console.log(res.data)
            if (res.data.length>0){
                 var newtable = res.data.map(item=>{
                     return {"id": {id: item.id, department: item.department.id}, "Course Code": item.course_code, "Course Name": item.course_name, "Department": item.department.department_name, "Status" : item.status , "Action" : "Action" }
                 })
                 setTableItems(newtable)
            //     console.log(newtable)
            }
        }catch(err){
            console.log(err);
        }
    }

    const EditFunction =  async (item) => {
    setRequesttype("put");
    setCurrentCourse({
        'id' : item.id.id,
        'name' : item['Course Name'],
        'code' : item["Course Code"],
        'department': {label: item["Department"], value: item.id.department},
        'status' : item["Status"]
    })
    }

    const DeleteFunction =  async (id)=>{
        // console.log(id + "has been deleted")
        try{
            // const url = SERVER + "" + id.id
            const res = await axios.delete(`/apiV1/course/${id.id}`, { headers: authHeader() });
            mdtoast.success("Session Deleted Successfully ", { duration: 1000 })
            setTableItems(tableItems.filter((item)=>item.id !== id.id))
            updatetable()
        }catch (err) {
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }
        
    }

    useEffect(async ()=>{
        updatetable()
    },[window.url])

    return (
        <>
        <NavBar/>
        <div className="bg-gray-200">
            <div className="mx-auto md:flex md:w-11/12 justify-center bg-gray-200 py-5">
                <div className="md:w-3/12 flex-shrink-0">
                        <CourseForm updatetable={updatetable}  requesttype={requesttype} setTableItems={setTableItems} setCurrentCourse={setCurrentCourse} currentCourse={currentCourse} setRequesttype={setRequesttype} />
                </div>
                <div className="flex items-center bg-white py-3 w-11/12 md:w-9/12 mx-auto rounded-b-md md:rounded-md">
                    <div className="w-full">
                        <Table updatetable={updatetable} DeleteFunction={DeleteFunction} EditFunction={EditFunction} tableItems={tableItems}/>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default Course