import React, { useState } from "react";
import axios from "axios";
import { SERVER } from "../../../utils";
// import { formServices } from "../../../services/formServices";
import mdtoast from '@dmuy/toast'
import Switch from "react-switch";
import { authHeader } from "../../../helper/authHeader";

const SessionForm = ({ updatetable, currentsession, requesttype, setRequesttype, setCurrentsession }) => {

    // const url = SERVER + "api/";

    const handlePost = async () => {
        const body = {
            session_name: currentsession.session,
            start_date: currentsession.start,
            end_date: currentsession.end,
            current_session: currentsession.current_session,
            status: true
        }
        console.log(body)
        try {
            if (requesttype == 'post') {
                console.log(body)
                // const url = SERVER + "/apiV1/session/"
                const res = await axios.post("/apiV1/session/", body, { headers: authHeader() });
                mdtoast.success("Session Successfully Created", { duration: 1000 })
            } else {
                // const url = SERVER + + currentsession.id + "/"
                const res = await axios.put(`/apiV1/session/${currentsession.id}/`, body, {
                    headers: authHeader()
                });
                mdtoast.success("Session Successfully Edited", { duration: 1000 })
                setRequesttype("post")
            }
            setCurrentsession({
                'id': null,
                'session': '',
                'start': '',
                'end': '',
                'current_session': false,
                'status': true
            })

            // formServices.sessionPost(session, start, end, current);
            updatetable()
        } catch (err) {
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }

    }

    return (
        <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-t-md md:rounded-md rounded-b-none">
            <p className="text-xl font-bold gg-erp-color ">{requesttype=="post" ? "Add Session" : "Edit Session"}</p>
            <label for="session_name" className="w-full py-2">Session Name
                <input className="form-input" value={currentsession.session} onChange={(e) => { setCurrentsession({ ...currentsession, 'session': e.target.value }) }} type="text" placeholder="Session Name" name="session" />
            </label>

            <label for="start" className="w-full py-2">Start Date
                <input type="date" value={currentsession.start} onChange={(e) => { setCurrentsession({ ...currentsession, 'start': e.target.value }) }} placeholder="dd-mm-yyyy" name="start" className="form-input" />
            </label>

            <label for="end" className="w-full py-2">End Date
                <input type="date" placeholder="dd-mm-yyyy" value={currentsession.end} onChange={(e) => { setCurrentsession({ ...currentsession, 'end': e.target.value }) }} name="end" className="form-input" />
            </label>

            {/* <label for="current_session" className="w-full py-2">
                <div>
                    <input type="radio" value={true} name="session true" checked={currentsession.current_session == true} onClick={(e) => setCurrentsession({ ...currentsession, 'current_session': true})}/> Yes
                    <input type="radio" value={false} name="session false" checked={currentsession.current_session == false} onClick={(e) => setCurrentsession({ ...currentsession, 'current_session': false})}/> No
                </div> 
                <input type="checkbox" checked={currentsession.current_session} onClick={() => setCurrentsession({ ...currentsession, 'current_session': !currentsession.current_session})}/>
                <span className="px-2">Current Session</span>
            </label> */}

            {/* <label for="status" className="w-full py-2">
                <span className="px-2">Status</span>
                <br />
                <span className="p-2">
                    <Switch checkedIcon={null} activeBoxShadow={null} width={52} height={26} handleDiameter={21} uncheckedIcon={null} onChange={() => setCurrentsession({ ...currentsession, 'status': !currentsession.status })} checked={currentsession.status} onColor="#ff5722" />
                </span>

            </label> */}

            <label for="status" className="w-full py-2">
                <span className="px-2">Current Session</span>
                <br />
                <span className="p-2">
                    <Switch checkedIcon={null} activeBoxShadow={null} width={52} height={26} handleDiameter={21} uncheckedIcon={null} onChange={() => setCurrentsession({ ...currentsession, 'current_session': !currentsession.current_session })} checked={currentsession.current_session} onColor="#ff5722" />
                </span>

            </label>
            <hr />
            <div className="text-right mt-2">
                <button type="submit" onClick={handlePost} className="submit">Submit</button>
            </div>
        </div>
    )
}

export default SessionForm