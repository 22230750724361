import React, { useState } from "react";
import { Link } from "react-router-dom";
import { formServices } from "../../../services/formServices";

const ExpensesForm = () => {

    const [startdate, setStartdate] = useState("");
    const [enddate, setEnddate] = useState("");

    const handlePost = () => {
        // return formServices.backduesPost(username, course, rollno, status, admissionno, search)
    }

    return (
        <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-md">
            <p className="text-xl font-bold gg-erp-color ">EXPENSE LIST</p>
            <div style={{display:"flex" , justifyContent:"space-between"}}>
            <div className=" text-center mt-2">
                <button type="submit" className="export mt-2" onClick={handlePost}>Export</button>
            </div>
            <div style={{display:"flex"}}>
            <div className=" text-center m-2">
            <Link to="/accounts/AddExpenses">
                <button type="submit" className="submit">Add Expenses</button>
                </Link>
            </div>
            <div className=" text-center m-2">
                <button type="submit" className="submit" onClick={handlePost}>Inactive</button>
            </div>
            </div>
            </div>
            
            
            <div className="grid md:grid-cols-2 gap-2">
                <label for="usr" className="w-full py-2">Start Date
                    <input className="form-input" type="date" placeholder="Start Date" name="usr" onChange={e => setStartdate(e.target.value)} />
                </label>

                <label for="crse" className="w-full py-2">End Date
                    <input className="form-input" type="date" placeholder="End Date" name="crse" onChange={e => setEnddate(e.target.value)} />
                </label>
            </div>
            <hr />
            <div className=" text-center mt-2">
                <button type="submit" className="submit w-full" onClick={handlePost}>Search</button>
            </div>
        </div>
    )
}

export default ExpensesForm