import React, { useState } from "react";
import { formServices } from "../../../services/formServices";
import Select from "react-select"

const MultiGroupForm = () => {

    const [course, setCourse] = useState("");

    const handlePost = () => {
        return formServices(course)
    }

    const courses = [
        {label: "Information Technology", value: "it"},
        {label: "CSE", value: "cse"},
        {label: "Demo", value: "demo"}
    ]

    return (
        <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-md">
            <p className="text-xl font-bold gg-erp-color ">MULTI GROUP ASSIGN</p>

            <label for="crse" className="mb-2 w-full py-2">Select Course
            <Select 
                options={courses}
                value={course}
                onChange = {(option)=>setCourse(option)}
                placeholder="Select Course" 
                isClearable={true} 
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                    ...theme.colors,
                    primary25: 'lightCyan',
                    primary: '#ff5722',
                    },
                })}
            />
            </label>

            <hr />
            <div className="text-right mt-2">
                <button type="submit" className="submit" onClick = {handlePost}>Submit</button>
            </div>
        </div>
    )
}

export default MultiGroupForm