import ExpensesForm from "../../components/forms/Accounts/ExpensesForm";
import React , {useState , useEffect} from "react";
import BackDuesForm from "../../components/forms/Fee/BackDues";
import NavBar from "../../components/navbar-components/NavBar";
import Footer from "../../components/Footer";
import Table from "../../components/Table";
import IncomeForm from "../../components/forms/Accounts/IncomeForm";
import axios from "axios";
import mdtoast from "@dmuy/toast";
import { SERVER } from "../../utils";
import { useHistory } from "react-router-dom";

const Expenses = () => {
    let history = useHistory();
const updatetable = async ()=>{
    try{
        var res = await axios.get( SERVER + "/apiV1/expense" , { headers: { Authorization : `Basic cmFodWw6MTIzNDU2` } });
        console.log(res.data)
        if (res.data.length>0){
            var newtable = res.data.map((item , idx)=>{
                return {"id": item.id,"S.no": idx+1, "Name": item.name, "Account Sub Head": item.account_sub_head.subhead, "Payment Method": item.payment_method.payment_method, "Amount": item.amount, "Date": item.date, "Description":item.description, "Status": item.status, "Action": "action",}
            })
            setTableItems(newtable)
            
        }
    }catch(err){
        mdtoast.error("Error Occured", { duration: 1000 })
    }
}

const EditFunction =  async (item)=>{
    history.push(`EditExpense/${item.id}`) 
}

const DeleteFunction =  async (id)=>{
    // console.log(id + "has been deleted")
    try{
        const url = SERVER + "/apiV1/expense/" + id
        const res = await axios.delete(url, { headers: { Authorization : `Basic cmFodWw6MTIzNDU2` } });
        mdtoast.success("Session Deleted Successfully ", { duration: 1000 })
        setTableItems(tableItems.filter((item)=>item.id !== id))
    }catch (err) {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    }
    
}

    useEffect(()=>{
        updatetable()
    } , [window.url])

    const [tableItems , setTableItems] = useState([
        {"S.no": null, "Name": null, "Account Sub Head": null, "Payment Method": null, "Amount": null, "Date": null, "Description":null, "Status": null, "Action": null, empty: true}
    ])
    return(
        <>
        <NavBar/>
        <div className="bg-gray-200">
            <div className="mx-auto w-full bg-gray-200 py-5">
                    <ExpensesForm/>
                <div className=" bg-white py-3 w-11/12 mx-auto rounded-b-md md:rounded-md">
                    <Table tableItems={tableItems} DeleteFunction={DeleteFunction} EditFunction={EditFunction}/>
                </div>
            </div>
        </div>
        <Footer/>
        </> 
    )
}


export default Expenses