import React, { useState } from "react";
import { formServices } from "../../../services/formServices";

const StaffForm = () => {
    const [tostaff , setTostaff] = useState("");
    const [message , setMessage] = useState("");

    const handlePost = () => {
        return formServices.staffPost(tostaff , message)
    }
    return (
        <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-md">
            <p className="text-xl font-bold gg-erp-color ">PARTICULAR STAFF SMS</p>
            
            <div className="grid gap-2 md:grid-cols-2">   
                <label className="mb-2 w-full py-2" for="to_student">To Staff
                    <input type="search" name="to_student" className="form-input" onChange={e => setTostaff(e.target.value)}/>
                </label>
                <label for="message" className="mb-2 w-full py-2">Message
                    <textarea className="form-input" placeholder="" type="text" name="message" onChange={e => setMessage(e.target.value)}/>
                </label>
            </div>
            <hr/>
            <div className="text-right mt-2">
                <button type="submit" className="submit" onClick = {handlePost}>Send SMS</button>
            </div>
        </div>
    )
}

export default StaffForm