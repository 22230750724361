import React from "react";
import PostalForm from "../../components/forms/Communicate/Postal"
import NavBar from "../../components/navbar-components/NavBar";
import Footer from "../../components/Footer";
import Table from "../../components/Table"

const tableItems = [
    {"S.no": null,"From Title": null,"To Title": null, "Registration No": null,"Date": null, empty:true}
]

const Postal = () => {
    return (
        <>
        <NavBar/>
        <div className="bg-gray-200">
            <div className="mx-auto w-full bg-gray-200 py-5">
                    <PostalForm/>
                <div className=" bg-white py-3 w-11/12 mx-auto rounded-b-md md:rounded-md">
                    <Table tableItems={tableItems}/>
                </div>
            </div>
        </div>
        <Footer/>
        </> 
    )
}

export default Postal