import React from "react";
import ggErp from '../images/gg-erp.png';
import makeInInd from '../images/make-in-india.png';
import msme from '../images/msme.png';
import isoMark from '../images/iso-certified.png'
import '../styles/footer.css'

const Footer = () => {

    return (

        <footer className="text-center w-full text-white mx-auto ">
            <div className="bg-gray-200">
            <div className="orng-border w-11/12 mx-auto ">
            <div className="light-orng-border">
                <section className="parent pt-4 bg-white flex flex-col sm:grid sm:grid-cols-12">
                <div className="col-span-4">
                    <center>
                        <img src={ggErp} alt="footer-logo" className="footer-images"/>
                        <p className="small-black font-light">
                            <span className="gg-erp-color font-semibold small-font">Powered by</span>
                            <br/>
                            © 2021. All rights reserved.
                        </p>
                    </center>
                </div>
                <div className="col-span-8">
                    <div className=" flex justify-center items-center">
                        <div className="">
                            <img src={isoMark} alt="footer-logo " className="small-imgs"/>
                        </div>
                        <div className="mx-8 sm:mx-28">
                            <img src={msme} alt="footer-logo" className="small-imgs"/>
                        </div>
                        <div className="">
                            <img src={makeInInd} alt="footer-logo" className="small-imgs"/>
                        </div>
                    </div>

                    <div className="text-center text-dark p-3 bg-white small-bottom-text ">
                        <span className="iso-text bottom-text">We are an ISO certified company</span>
                        <br/>
                        <div className="text-center text-dark p-3 bg-white small-bottom-text1 ">
                        <span className="col-lg-3 col-md-6 mb-4 mb-md-0"> <span className="gg-erp-color font-semibold">Ph:</span> 9835678006 </span> |
                        <span className="col-lg-3 col-md-6 mb-4 mb-md-0">  <span className="gg-erp-color font-semibold">Email:</span> contact@gungunerp.com </span> |
                        <span className="col-lg-3 col-md-6 mb-4 mb-md-0">  <span className="gg-erp-color font-semibold">Website: </span> <a target="_blank" rel="noreferrer" href="https://gungunerp.com/" className=" hover:from-yellow-600 font-light">gungunerp.com</a></span>
                        </div>
                  </div>  
                </div>
                </section>
            </div>
            </div>   
            </div>   
        </footer>
    )
}

export default Footer