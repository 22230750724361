import React, { useState, useEffect } from "react";
import { formServices } from "../../../services/formServices";
import Search from "../../Search";
import Select from "react-select"
import axios from "axios";
import mdtoast from "@dmuy/toast";
import { authHeader } from "../../../helper/authHeader";

const ManageStudentForm = ({ tableItems, setShowItems, current }) => {
    const [username, setUsername] = useState("");
    const [course, setCourse] = useState("");
    const [EnrollmentNo, setEnrollmentNo] = useState("");
    const [admissionno, setAdmissionno] = useState("");
    const [search, setSearch] = useState("");
    const [courses, setCourses] = useState([])

    useEffect(() => {
        const getData = async () => {
            try {
                var res = await axios.get("/apiV1/course/", { headers: authHeader() })
                // if (departments.length > 0) {
                var validcourses = res.data.map((item) => {
                    return { "label": item.course_name, "value": item.id }
                })
                setCourses(validcourses)
                // }

            } catch (err) {
                mdtoast.error("Error Occured", { duration: 1000 })
            }
        }
        getData()
    }, [])

    const handlePost = () => {
        // return formServices.managestudentPost(username , course , EnrollmentNo , admissionno , search)
        // console.log(username)
        // console.log(course.value)
        // console.log(EnrollmentNo)
        // // console.log(admissionno)
        // console.log(tableItems)

        if (tableItems.length > 0) {
            var newtable = []
            let count = 0
            tableItems.forEach((item, index) => {
                count++
                try { 
                    if ((username === "" && EnrollmentNo === "")) {
                        let data = {
                            "id": item.id,
                            "extra": false,
                            "Sr No.": count,
                            "Username": item.user.username,
                            "Name": item.user.name,
                            "Department": item.department ? item.department.department_name : "Not Available",
                            "Enrollment No": item.user.enrollment_id,
                            "Father's Name": item.user.father_name,
                            "Contact": item.user.contact,
                            "Action": "Action",
                            "extraInfo": {
                                "email": item.user.email,
                                "dob": item.user.dob,
                                "date of admission": item.date_of_admission,
                                "student status": item.student_status,
                                "student religion": item.student_religion,
                                "student category": item.student_category
                            }
                        }
                        if(!current){
                            data = {
                                "id": item.id,
                                "extra": false,
                                "check": "",
                                "Sr No.": count,
                                "Username": item.user.username,
                                "Name": item.user.name,
                                "Department": item.department ? item.department.department_name : "Not Available",
                                "Enrollment No": item.user.enrollment_id,
                                "Father's Name": item.user.father_name,
                                "contact": item.user.contact,
                                "Action": "Action",
                                "extraInfo": {
                                    "email": item.user.email,
                                    "dob": item.user.dob,
                                    "date of admission": item.date_of_admission,
                                    "student status": item.student_status,
                                    "student religion": item.student_religion,
                                    "student category": item.student_category
                                }
                            }
                        }
                        newtable.push(data)
                    }
                    else if(item.user.name.toLowerCase() === username.toLowerCase() || item.user.enrollment_id === EnrollmentNo){
                        let data = {
                            "id": item.user.id,
                            "extra": false,
                            "Sr No.": count,
                            "Name": item.user.name,
                            "Department": item.department ? item.department.department_name : "Not Available",
                            "Enrollment No": item.user.enrollment_id,
                            "Father's Name": item.user.father_name,
                            "contact": item.user.contact,
                            "Action": "Action",
                            "extraInfo": {
                                "email": item.user.email,
                                "dob": item.user.dob,
                                "date_of_admission": item.date_of_admission,
                                "student_status": item.student_status,
                                "student_religion": item.student_religion,
                                "student_category": item.student_category

                            }
                        }
                        if(!current){
                            data = {
                                "id": item.user.id,
                                "extra": false,
                                check: "",
                                "Sr No.": count,
                                "Username": item.user.username,
                                "Name": item.user.name,
                                "Department": item.department ? item.department.department_name : "Not Available",
                                "Enrollment No": item.user.enrollment_id,
                                "Father's Name": item.user.father_name,
                                "contact": item.user.contact,
                                "Action": "Action",
                                "extraInfo": {
                                    "email": item.user.email,
                                    "dob": item.user.dob,
                                    "date of admission": item.date_of_admission,
                                    "student status": item.student_status,
                                    "student religion": item.student_religion,
                                    "student category": item.student_category
                                }
                            }
                        }
                        newtable.push(data)
                    }
                } catch (e) {
                    console.log(e)
                }

            })

            // var newtable = tableItems.map(item=>{
            //     if(item.name == username || item.enrollment_id==EnrollmentNo){
            //         return {"id": item.id, "Name": item.name, "Department": "Not Available", "Enrollment No": item.enrollment_id, "Action" : "Action" }
            //     }    
            // })
            // setTableItems(res.data)
            // console.log(newtable)
            setShowItems(newtable)
        }


    }

    return (
        <div className="text-sm bg-white w-11/12 pt-4 pb-4 mx-auto rounded-md">

            <div className="grid lg:grid-cols-3 gap-2">

                <label for="usr" className="w-full py-2">Name
                    <input className="form-input" type="text" placeholder="Name" name="usr" onChange={e => setUsername(e.target.value)} />
                </label>

                <label for="crse" className="w-full py-2">Course
                    <Select
                        options={courses}
                        value={course}
                        onChange={e => { setCourse(e) }}
                        placeholder="Select Course"
                        isClearable={true}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                                ...theme.colors,
                                primary25: 'lightCyan',
                                primary: '#ff5722',
                            },
                        })}
                    />
                </label>

                <label for="roll_no" className="w-full py-2">Enrollment No.
                    <input className="form-input" type="text" placeholder="Enrollment No." name="roll_no" onChange={e => setEnrollmentNo(e.target.value)} />
                </label>

                {/* <label for="adm_no" className="w-full py-2">Admission No.
                    <input className="form-input" type="text" placeholder="Admission No." name="adm_no" onChange={e => setAdmissionno(e.target.value)} />
                </label> */}

                {/* <label for="search" className="w-full py-2">Search
                    <input className="form-input" type="search" placeholder="Search" name="srch" onChange={e => setSearch(e.target.value)}/> 
                </label> */}


            </div>
            <div className="text-center mt-1">
                <button type="submit" className="submit w-full py-2 mt-4" onClick={handlePost}>Search</button>
            </div>
        </div>
    )
}

export default ManageStudentForm