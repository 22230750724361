import React from "react";
// import ManageStudentForm from "../../components/forms/Student/ManageStudent"
import Footer from "../components/Footer";
import NavBar from "../components/navbar-components/NavBar";
// import Search from "../../components/Search";
import EditProfileForm from "../components/forms/EditProfileForm";

const EditProfile = () => {
    return (
        <>
        <NavBar/>
        <div className="bg-gray-200 py-5">
            <EditProfileForm/>
        </div>
        <Footer/>
        </>
    )
}

export default EditProfile