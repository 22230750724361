import React from "react";
import NavBar from "../../components/navbar-components/NavBar";
import Footer from "../../components/Footer";
import Table from "../../components/Table";

const tableItems = [
    {"Class": null, "General": null, "OBC": null, "EBC": null, "SC": null, "ST": null,empty: true}
]

const DiseCategory = () => {
    return (
        <>
        <NavBar/>
        <div className="bg-gray-200">
            <div className="mx-auto w-full bg-gray-200 py-5">
                <div className="w-11/12 mx-auto pt-4 rounded-t-md bg-white">
                    <div className="w-11/12 mx-auto">
                        <p className="text-xl font-bold gg-erp-color ">DISE REPORT BY CATEGORY</p>
                        <button type="submit" className="submit px-3 py-1">Print</button> 
                    </div>
                </div>
                <div className="pb-4 bg-white py-3 w-11/12 mx-auto rounded-b-md">
                    <Table tableItems={tableItems}/>
                </div>
            </div>
        </div>
        <Footer/>
        </> 
    )
}

export default DiseCategory