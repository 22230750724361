import React, { useState, useEffect } from "react";
import { formServices } from "../../../services/formServices";
import Select from "react-select"
import axios from "axios";
import { SERVER } from "../../../utils";
import mdtoast from '@dmuy/toast'
import { authHeader } from "../../../helper/authHeader";
import { useHistory } from "react-router";

const SessionForm = () => {

    const [username, setUsername] = useState("")
    const [name, setName] = useState("")
    const [status, setStatus] = useState("current")
    // const [admNum, setAdmNum] = useState("")
    const [session, setSession] = useState("")
    // const [regNum, setRegNum] = useState("")
    const [department, setDepartment] = useState("");
    // const [course, setCourse] = useState("")
    const [rollNum, setRollNum] = useState("")
    const [contOne, setContOne] = useState("")
    // const [contTwo, setContTwo] = useState("")
    const [father, setFather] = useState("")
    const [mother, setMother] = useState("")
    const [mail, setMail] = useState(null)
    const [gender, setGender] = useState("")
    const [religion, setReligion] = useState("")
    const [category, setCategory] = useState("")
    const [dob, setDob] = useState("")
    const [doa, setDoa] = useState("")
    const [address, setAddress] = useState("")
    // const [aadhar, setAadhar] = useState("")
    // const [info, setInfo] = useState("");
    const [pincode, setPincode] = useState("")
    const [courselist, setCourselist] = useState([])
    const [courseadded, setCourseadded] = useState([])
    const [departments, setDepartments] = useState([])
    const [sessions, setSessions] = useState([])
    const history = useHistory()
    // const sessions = [
    //     { label: "2019-2020", value: "2019-2020" },
    //     { label: "2020-2021", value: "2020-2021" },
    //     { label: "2021-2022", value: "2021-2022" },
    // ]
    // const departments = [
    //     { label: "Information Technology", value: 1 },
    //     { label: "CSE", value: 2 },
    //     { label: "Demo", value: 3 }
    // ]
    const studentStatus = [
        { label: "Current", value: "current" },
        { label: "Passout", value: "passout" },
        { label: "Dropout", value: "dropout" },
    ]

    const categories = [
        { label: "General", value: "general" },
        { label: "EBC", value: "ebc" },
        { label: "OBC", value: "obc" },
        { label: "SC", value: "sc" },
        { label: "ST", value: "st" },
    ]

    const genders = [
        { label: "Male", value: "male" },
        { label: "Female", value: "female" }
    ]

    const religions = [
        { label: "Hindu", value: "hindu" },
        { label: "Muslim", value: "muslim" },
        { label: "Sikh", value: "sikh" },
        { label: "Christian", value: "ishi" },
    ]

    useEffect(() => {
        const getData = async () => {
            try {
                var res = await axios.get("/apiV1/department/", { headers: authHeader() })
                var departmentlist = res.data.map((item) => {
                    return { "label": item.department_name, "value": item.id }
                })
                setDepartments(departmentlist)
            } catch (err) {
                console.log(err)
            }
        }
        getData()
    }, [window.url])

    useEffect(() => {
        const getData = async () => {
            try {
                var res = await axios.get("/apiV1/semester-session/", { headers: authHeader() })
                var departmentlist = res.data.map((item) => {
                    var labelstring = ""
                    item.course.forEach((item2) => {
                        labelstring += (item2.course_name + ",")
                    })
                    labelstring = item.semester.semester_name + "-" + labelstring
                    return { "label": labelstring, "value": item.id, "session": item.session.id }
                })
                setSessions(departmentlist)
            } catch (err) {
                console.log(err)
            }
        }
        getData()
    }, [window.url])

    useEffect(() => {
        const getData = async () => {
            try {
                var res = await axios.get("/apiV1/course/", { headers: authHeader() })
                if (res.data.length > 0) {
                    var validcourses = res.data.map((item) => { return { "label": item.course_name, "value": item.id } }
                    )
                    // var validcourses = res.data.map((item)=>
                    //     (item.department == department.value) ? {"label": item.course_name , "value" : item.id} : null
                    // )
                    setCourselist(validcourses)
                }

            } catch (err) {
                console.log(err)
            }
        }
        getData()
    }, [department])

    // {
    //     "email": "kundansingh602@gmail.com",
    //     "name": "Manoj",
    //     "contact": "0",
    //     "password": "manoj",
    //     "profile_image": null,
    //     "fateher_name": "Test",
    //     "mother_name": "test",
    //     "dob": null,
    //     "address": "90 Fit Road, Bypass Patna",
    //     "pin_code": 800025,
    //     "username": "asdf",
    //     "enrollment_id": null,
    //     "student_profile": {
    //         "department": 2,
    //         "session_admit": 29,
    //         "date_of_admission": null,
    //         "student_status": "current",
    //         "student_religion": "hindu",
    //         "student_category": "general"
    //     },
    //     "student_session": {
    //         "semester_session": 1
    //     }
    // }
    const handlePost = async () => {
        // formServices.addstudentPost(username , status.value , admNum , regNum , name , department.value , rollNum , contOne , contTwo , father , mother , mail , gender.value , religion.value , category.value , dob , doa , address , aadhar , info , session.value)
        // const url = SERVER + "/apiV1/student-admission/"

        const body = {

            "email": mail,
            "name": name,
            "contact": contOne,
            "password": "default pass",
            "profile_image": null,
            "father_name": father,
            "mother_name": mother,
            "dob": dob,
            "address": address,
            "gender": gender.value,
            "pin_code": pincode,
            // "username": username,
            "enrollment_id": rollNum,
            "student_profile": {
                "department": department.value,
                "session_admit": session.session,
                "date_of_admission": doa,
                "student_status": status,
                "student_religion": religion.value,
                "student_category": category.value
            },
            "student_session": {
                "semester_session": session.value
            }
        }
        console.log(body)
        try {
            const res = await axios.post("/apiV1/student-admission/", body, { headers: authHeader() });
            // console.log(body)
            mdtoast.success("Student Successfully Created", { duration: 1000 })
            history.push("/students/current")

            // setName("")
            // setSession("")
            // setDepartment("");
            // setRollNum("")
            // setContOne("")
            // setFather("")
            // setMother("")
            // setMail("")
            // setGender("")
            // setReligion("")
            // setCategory("")
            // setDob("")
            // setDoa("")
            // setAddress("")

            // setPincode("")


        } catch (err) {

            mdtoast.error("Error Occured", { duration: 1000 })
        }
    }

    //session 

    return (
        <div className="text-sm  bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-md">
            <p className="text-xl font-bold gg-erp-color ">ADD STUDENT</p>
            <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-2">

                {/* <label for="username" className="w-full py-2">Username
                    <input className="form-input" disabled={true} type="text" placeholder="Username" name="username" onChange={e => setUsername(e.target.value)} />
                </label> */}

                <label for="username" className="w-full py-2">Name
                    <input className="form-input" type="text" placeholder="Name" name="username" onChange={e => setName(e.target.value)} />
                </label>
                {/* 
                <label for="status" className="w-full py-2">Student Status
                    <Select
                        options={studentStatus}
                        value={status}
                        onChange={(option) => setStatus(option)}
                        placeholder="Select Status"
                        isClearable={true}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                                ...theme.colors,
                                primary25: 'lightCyan',
                                primary: '#ff5722',
                            },
                        })}
                    />
                </label> */}

                {/* <label for="reg_num" className="w-full py-2">Registration No.
                    <input className="form-input" type="text" placeholder="Registration No." name="reg_num" onChange={e => setRegNum(e.target.value)} />
                </label> */}

                {/* <label for="name" className="w-full py-2">Name
                    <input className="form-input" type="text" placeholder="Name" name="name" onChange={e => setName(e.target.value)} />
                </label> */}

                <label for="department" className="w-full py-2">Select Department
                    <Select
                        options={departments}
                        value={department}
                        onChange={(option) => setDepartment(option)}
                        placeholder="Select Department"
                        isClearable={true}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                                ...theme.colors,
                                primary25: 'lightCyan',
                                primary: '#ff5722',
                            },
                        })}
                    />
                </label>

                {/* <label for="department" className="w-full py-2">Select Course
                    <Select
                        options={courselist}
                        value={courseadded}
                        onChange={(option) => setCourseadded(option)}
                        placeholder="Select Course"
                        isClearable={true}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                                ...theme.colors,
                                primary25: 'lightCyan',
                                primary: '#ff5722',
                            },
                        })}
                    />
                </label> */}

                <label for="session" className="w-full py-2">Select Session
                    <Select
                        options={sessions}
                        placeholder="Select Session"
                        value={session}
                        onChange={(option) => setSession(option)}
                        isClearable={true}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                                ...theme.colors,
                                primary25: 'lightCyan',
                                primary: '#ff5722',
                            },
                        })}
                    />
                </label>

                <label for="roll_no" className="w-full py-2">Enrollment No.
                    <input className="form-input" type="text" placeholder="Enrollment No." name="roll_no" onChange={e => setRollNum(e.target.value)} />
                </label>

                <label for="whtsapp_no" className="w-full py-2">Contact
                    <input className="form-input" type="number" name="whtsapp_no" onChange={e => setContOne(e.target.value)} />
                </label>

                {/* <label for="sms_no" className="w-full py-2">Contact 2
                    <input className="form-input" type="text" name="sms_no" onChange={e => setContTwo(e.target.value)} />
                </label> */}

                <label for="father_name" className="w-full py-2">Father's Name
                    <input className="form-input" type="text" placeholder="Father Name" name="father_name" onChange={e => setFather(e.target.value)} />
                </label>

                <label for="mother_name" className="w-full py-2">Mother's Name
                    <input className="form-input" type="text" placeholder="Mother Name" name="mother_name" onChange={e => setMother(e.target.value)} />
                </label>

                <label for="email" className="w-full py-2">Email
                    <input className="form-input" type="email" placeholder="Email" name="email" onChange={e => setMail(e.target.value)} />
                </label>

                <label for="gender" className="w-full py-2">Gender
                    <Select
                        options={genders}
                        value={gender}
                        onChange={(option) => setGender(option)}
                        placeholder="Select Gender"
                        isClearable={true}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                                ...theme.colors,
                                primary25: 'lightCyan',
                                primary: '#ff5722',
                            },
                        })}
                    />
                </label>

                <label for="religion" className="w-full py-2">Religion
                    <Select
                        options={religions}
                        value={religion}
                        onChange={(option) => setReligion(option)}
                        placeholder="Select Religion"
                        isClearable={true}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                                ...theme.colors,
                                primary25: 'lightCyan',
                                primary: '#ff5722',
                            },
                        })}
                    />
                </label>

                <label for="category" className="w-full py-2">Category
                    <Select
                        options={categories}
                        value={category}
                        onChange={(option) => setCategory(option)}
                        placeholder="Select Category"
                        isClearable={true}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                                ...theme.colors,
                                primary25: 'lightCyan',
                                primary: '#ff5722',
                            },
                        })}
                    />
                </label>

                <label for="dob" className="w-full py-2">Date Of Birth
                    <input type="date" placeholder="dd-mm-yyyy" name="dob" className="form-input" onChange={e => setDob(e.target.value)} />
                </label>

                <label for="doadm" className="w-full py-2">Date Of Admission
                    <input type="date" placeholder="dd-mm-yyyy" name="doadm" className="form-input" onChange={e => setDoa(e.target.value)} />
                </label>

                {/* <label for="address" className="w-full py-2">Address
                    <input className="form-input" type="text" placeholder="Address" name="address" onChange={e => setAddress(e.target.value)} />
                </label> */}

                <label for="pincode" className="w-full py-2">Pincode
                    <input className="form-input" type="number" placeholder="PinCode" name="aadhar" onChange={e => setPincode(e.target.value)} />
                </label>

            </div>

            <label className="w-full" for="add_info"> Address
                <textarea placeholder="Address" className="form-input" onChange={e => setAddress(e.target.value)} />
                {/* <input className="form-input" type="text" placeholder="Address" name="address" onChange={e => setAddress(e.target.value)} /> */}
                <span className="text-xs">Total Characters: {address.length} | Maximum Characters Allowed: 100</span>
            </label>
            <hr />
            <div className="text-right mt-2">
                <button type="submit" className="submit" onClick={handlePost}>Submit</button>
            </div>
        </div>
    )
}

export default SessionForm