import React from "react";
import TermForm from "../../components/forms/Fee/Term";
import NavBar from "../../components/navbar-components/NavBar";
import Footer from "../../components/Footer";
import Table from "../../components/Table"

const tableItems = [
    {"Term Name": null, "Start Date": null, "End Date": null, "Due Date": null, empty: true }
]

const Term = () => {
    return (
        <>
        <NavBar/>
        <div className="bg-gray-200">
            <div className="mx-auto md:flex md:w-11/12 justify-center bg-gray-200 py-5">
                <div className="md:w-3/12 flex-shrink-0">
                        <TermForm/>
                </div>
                <div className="flex items-center bg-white py-3 w-11/12 md:w-9/12 mx-auto rounded-b-md md:rounded-md">
                    <div className="w-full">
                        <Table tableItems={tableItems}/>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        </>    )
}

export default Term