import React from "react";
import Footer from "../../components/Footer";
import ParticularForm from "../../components/forms/Communicate/Particular"
import NavBar from "../../components/navbar-components/NavBar";

const Student = () => {
    return (
        <>
        <NavBar/>
        <div className="bg-gray-200 py-5">
            <ParticularForm/>
        </div>
        <Footer/>
        </>
    )
}

export default Student