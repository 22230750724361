import React from "react";
import axios from "axios";
import mdtoast from '@dmuy/toast'
import { SERVER } from "../../../utils";
import { authHeader } from "../../../helper/authHeader"

const DesignationForm = ({updatetable, currentDesignation, requesttype, setRequesttype, setCurrentDesignation}) => {

    const handlePost = async () => {
        const body = {
            designation: currentDesignation.designation
        }
        try {
            if (requesttype == 'post') {
                console.log(body)
                // const url = SERVER + 
                const res = await axios.post("/apiV1/designation/", body, { headers: { ...authHeader() } });
                mdtoast.success("Designation Successfully Created", { duration: 1000 })

            } else {
                console.log(body)
                // const url = SERVER + "" + currentDesignation.id + "/"
                const res = await axios.put(`/apiV1/designation/${currentDesignation.id}/`, body, { headers: { 
                    ...authHeader(),      
                    'content-type': 'application/json',
                    'Accept': 'application/json' } });
                mdtoast.success("Designation Successfully Edited", { duration: 1000 })
                console.log(res.headers)
                setRequesttype("post")
            }
            setCurrentDesignation({
                designation: ""
            })

            updatetable()
        } catch (err) {
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }
    } 
    

    return (
        <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-t-md md:rounded-md rounded-b-none">
            <p className="text-xl font-bold gg-erp-color ">CREATE DESIGNATION</p>
            <label for="religion" className="mb-2 w-full py-2">Designation Name
                <input className="form-input" placeholder="Designation Name" type="text" name="religion" value={currentDesignation.designation} onChange={e => setCurrentDesignation({...currentDesignation, designation: e.target.value})}/>
            </label>
            <hr/>
            <div className="text-right mt-2">
                <button onClick={handlePost} type="submit" className="submit">Submit</button>
            </div>
        </div>
    )
}

export default DesignationForm