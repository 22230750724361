import React from "react";
import AddForm from "../../components/forms/Staff/Add"
import Footer from "../../components/Footer";
import NavBar from "../../components/navbar-components/NavBar";

const Add = () => {
    return (
        <>
        <NavBar/>
        <div className="bg-gray-200 py-5">
            <AddForm/>
        </div>
        <Footer/>
        </>
    )
}

export default Add