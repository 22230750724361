import React, { useState, useEffect } from "react";
import ManageStudentForm from "../../components/forms/Student/ManageStudent"
import Footer from "../../components/Footer";
import NavBar from "../../components/navbar-components/NavBar";
import Search from "../../components/Search";
import Table from "../../components/Table";
import axios from "axios";
import mdtoast from "@dmuy/toast";
import { SERVER } from "../../utils";
import { Switch } from "@headlessui/react";
import Empty from "../../components/Empty";
import { useHistory } from "react-router";
import { authHeader } from "../../helper/authHeader";

const Passout = () => {
    let history = useHistory();
    const [tableItems, setTableItems] = useState([]);
    const [showItems, setShowItems] = useState([
        { "Name": 0, "Department": 0, "Roll No.": 0, "Enrollment No": 0, "Action": "action", empty: true }
    ])
    const [list, setList] = useState([])

    const handleCheckbox = id => {
        let newArr = [...list];
        if (list.includes(id)) {
            newArr = newArr.filter(item => item !== id)
            return setList([...newArr])
        }
        newArr.push(String(id))
        return setList(newArr)
    }


    useEffect(async () => {
        updatetable()
    }, [window.url])

    const updatetable = async () => {
        try {
            var res = await axios.get("/apiV1/student-profile/", { headers: authHeader() });
            res.data = res.data.filter(data => data.student_status == "passout") 
            // console.log(res.data)
            if (res.data.length > 0) {
                let count = 0
                var newtable = res.data.map(item => {
                    count++
                    return { "id": item.user.id,extra: false, "Sr. No": count, "Name": item.user.name,Username: item.user.username, "Department": (item.department != null ? item.department.department_name : null ), "Enrollment No": item.user.enrollment_id ,"Father's Name": item.user.father_name, extraInfo: {Email: item.user.email,Contact: item.user.contact, "Mother's Name": item.mother_name,"Date of Admission": item.user.date_of_admission,"Date of birth": item.user.dob,Address: item.user.address, "Pin Code": item.user.pin_code, Religion: item.user.student_religion, Category: item.user.student_category}, "Action": "Action" }
                }) 
                // console.log("this is res data")
                // console.log(res.data)
                setTableItems(res.data)
                // setShowItems(newtable)
                // console.log(newtable)
            }
        } catch (err) {
            console.log(err);
        }
    }


    const EditFunction = async (item) => {
        history.push("/students/EditStudent/" + item.id);
    }

    const DeleteFunction = async (id) => {
        mdtoast.error("Students Cannot be Deleted", { duration: 1000 })
    }

    const handleStatus = async (status) => {
        try{
            const id = list.join(",")
            const res = await axios.get("/apiV1/dropoutgraduatemanagement/", { headers: authHeader(), params: {user:id, student_status: status}})
            updatetable()
            mdtoast.success("Student Successfully Updated", { duration: 1000 })
        }catch(err){
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }
 
    }

    return (
        <>
            <NavBar />
            <div className="bg-gray-200">
                <div className="mx-auto w-full bg-gray-200 py-5">
                    <div className="text-sm bg-white pt-4 pb-4  w-11/12 mx-auto rounded-md">
                        <p className="text-xl font-bold gg-erp-color text-center ">PASSOUT STUDENT LIST</p>
                        <ManageStudentForm tableItems={tableItems} current={false} setShowItems={setShowItems} />
                        <div className="w-11/12 mx-auto">
                            <button type="submit" className="submit text-xs mr-2" onClick={() => handleStatus("current")}>Re-admit Student</button>
                        </div>
                        <div className=" bg-white py-3 mx-auto rounded-b-md md:rounded-md">
                            <Table tableItems={showItems} setTable={setTableItems} handleCheckbox={handleCheckbox} EditFunction={EditFunction} DeleteFunction={DeleteFunction} />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Passout