import React from "react";
import NavBar from "../../components/navbar-components/NavBar";
import Footer from "../../components/Footer";

const FeePayment = () => {
    return (
        <>
        <NavBar/>
        <div className="bg-gray-200 py-5">

        </div>
        <Footer/>
        </>
    )
}

export default FeePayment