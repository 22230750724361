import React , {useState} from "react";
import { formServices } from "../../../services/formServices";

const TermForm = () => {

    const [term, setTerm] = useState("");
    const [startdate, setStartdate] = useState("");
    const [enddate, setEnddate] = useState("");
    const [duedate, setDuedate] = useState("");

    const handlePost = () => {
        return formServices.termPost(term , startdate , enddate , duedate)
    }

    return (
        <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-md">
            <p className="text-xl font-bold gg-erp-color ">FEE TERM</p>
            <label for="term" className="w-full py-2">Term Name
                <input className="form-input" type="text" placeholder="Term Name" name="term" onChange={e => setTerm(e.target.value)} /> 
            </label>

            <label for="start" className="w-full py-2">Start Date
                <input type="date" placeholder="dd-mm-yyyy" name="start" className="form-input" onChange={e => setStartdate(e.target.value)} /> 
            </label>

            <label for="end" className="w-full py-2">End Date
                <input type="date" placeholder="dd-mm-yyyy" name="end" className="form-input" onChange={e => setEnddate(e.target.value)} />  
            </label>

            <label for="due" className="w-full py-2">Due Date
                <input type="date" placeholder="dd-mm-yyyy" name="due" className="form-input" onChange={e => setDuedate(e.target.value)} />  
            </label>

            <hr/>
            <div className="text-right mt-2">
                <button type="submit" className="submit" onClick = {handlePost}>Submit</button>
            </div>
        </div>
    )
}

export default TermForm