// THIS FILE CONTAINS POST FUNCTIONS
import axios from "axios";
import { SERVER } from "../utils";
import mdtoast from '@dmuy/toast'
import { authHeader } from "../helper/authHeader";
 
// Academic 

const coursePost = (course, duration) => {
    const url = SERVER + "api/v1/course"
    const body = {
        course: course,
        duration: duration
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Course Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

// Configuration

const categoryPost = (category) => {
    const url = SERVER + "api/v1/"
    const body = {
        category: category,
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Category Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const paymentPost = (payment) => {
    const url = SERVER + "/apiV1/paymentmethod/"
    const body = {
        payment_method: payment,
        status: true
    }
    axios.post(
        url,
        body,
        { headers: { Authorization : `Basic cmFodWw6MTIzNDU2` } }
    // axios.post(
    //     url,
    //     body,
    //     { headers: authHeader()}
    ).then(res => {
        console.log(res)
        mdtoast.success("Payment Method Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const religionPost = (religion) => {
    const url = SERVER + "api/v1/"
    const body = {
        religion: religion,
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Religion Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const infoPost = (institute, shortname, address, affiliation, registration, officeNo, helpNum, mail, api, website) => {
    const url = SERVER + "api/v1/"
    const body = {
        institute_name: institute,
        shortname: shortname,
        address: address,
        affiliation: affiliation,
        reg_num: registration,
        contact1: officeNo,
        contact2: helpNum,
        email: mail,
        api: api,
        website: website
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("College Information Successfully Updated", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const sessionPost = (session, start, end, current) => {
    // const url = SERVER + "api/v1"
    const url = SERVER + "/apiV1/session/"
    const body = {
        session_name: session,
        start_date: start,
        end_date: end,
        // is_active: current
    }
    axios.post(url, body, { headers: { Authorization : `Basic cmFodWw6MTIzNDU2` } })
        .then(res => {
            console.log("this is result")
            console.log(res)
            mdtoast.success("Session Successfully Created", { duration: 1000 })
        }).catch(err => {
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        })
}

//Communicate folders

const eventPost = (event, img, from, to, days, desc) => {
    const url = SERVER + "api/v1/course"
    const body = {
        event, img, from, to, days, desc
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Event Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const groupPost = (superadmin, admin, teacher, librarian, employee, driver, parent, student, principal, academicinc, message) => {
    const url = SERVER + "api/v1/course"
    const body = {
        superadmin, admin, teacher, librarian, employee, driver, parent, student, principal, academicinc, message
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Group Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const holidayPost = (holiday, from, to, days, desc) => {
    const url = SERVER + "api/v1/course"
    const body = {
        holiday, from, to, days, desc
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Holiday Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}


const postalPost = (refnumber, fromtitle, totitle, date, todate, note, postal) => {
    const url = SERVER + "api/v1/course"
    const body = {
        refnumber, fromtitle, totitle, date, todate, note, postal
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Postal Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const staffPost = (tostaff, message) => {
    const url = SERVER + "api/v1/course"
    const body = {
        tostaff, message
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Staff Message Successfully Sent", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const messagestudentPost = (tostudent, message) => {
    const url = SERVER + "api/v1/course"
    const body = {
        tostudent, message
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Student Message Successfully Sent", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

// Fees folder

const backduesPost = (username, course, rollno, status, admissionno, search) => {
    const url = SERVER + "api/v1/course"
    const body = {
        username, course, rollno, status, admissionno, search
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Back Dues Post Successful", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const demandPost = (course, balancemore, balanceless, term, fee, status, note) => {
    const url = SERVER + "api/v1/course"
    const body = {
        course, balancemore, balanceless, term, fee, status, note
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Fee Demand Notice Post Successful", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}


const feegroupPost = (course, groupname) => {
    const url = SERVER + "api/v1/course"
    const body = {
        course, groupname
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Fee Group Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const multigroupPost = (course) => {
    const url = SERVER + "api/v1/course"
    const body = {
        course
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Multi Group Assign Successful", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const reminderPost = (course, balancemore, balanceless, term, fee, status) => {
    const url = SERVER + "api/v1/course"
    const body = {
        course, balancemore, balanceless, term, fee, status
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Reminder Successfully Sent", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const termPost = (term, startdate, enddate, duedate) => {
    const url = SERVER + "api/v1/course"
    const body = {
        term, startdate, enddate, duedate
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Fee Term Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const typePost = (fee, description) => {
    const url = SERVER + "api/v1/course"
    const body = {
        fee, description
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Fee Type Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

// reports folder

const adjustmentPost = (startdate, enddate, course, balancemore, balanceless, term, fee, status) => {
    const url = SERVER + "api/v1/course"
    const body = {
        startdate, enddate, course, balancemore, balanceless, term, fee, status
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Course Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const balancePost = (course, balancemore, balanceless, term, fee, status) => {
    const url = SERVER + "api/v1/course"
    const body = {
        course, balancemore, balanceless, term, fee, status
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Course Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const collectionPost = (startdate, enddate, course, balancemore, balanceless, term, fee, status) => {
    const url = SERVER + "api/v1/course"
    const body = {
        startdate, enddate, course, balancemore, balanceless, term, fee, status
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Course Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const coursewisePost = (course, term, fee, status) => {
    const url = SERVER + "api/v1/course"
    const body = {
        course, term, fee, status
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Course Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const deletedfeereceiptPost = (startdate, enddate, course) => {
    const url = SERVER + "api/v1/course"
    const body = {
        startdate, enddate, course
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Course Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const receiptwisePost = (startdate, enddate, course, payment, search) => {
    const url = SERVER + "api/v1/course"
    const body = {
        startdate, enddate, course, payment, search
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Course Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}


const semesterbalancePost = (course, balancemore, balanceless, term, fee, status) => {
    const url = SERVER + "api/v1/course"
    const body = {
        course, balancemore, balanceless, term, fee, status
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Course Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const semestercollectionPost = (course, balancemore, balanceless, term, fee, status) => {
    const url = SERVER + "api/v1/course"
    const body = {
        course, balancemore, balanceless, term, fee, status
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Course Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const studentwithmorePost = (course, term, status) => {
    const url = SERVER + "api/v1/course"
    const body = {
        course, term, status
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Course Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

// staff folder

const staffaddPost = (username, password, name, contact1, contact2, email, gender, dob, staffrole, department, designation, joining, qualification, address, fathername) => {
    const url = SERVER + "api/v1/course"
    const body = {
        username, password, name, contact1, contact2, email, gender, dob, staffrole, department, designation, joining, qualification, address, fathername
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Course Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const staffmanagePost = (role, username, search) => {
    const url = SERVER + "api/v1/course"
    const body = {
        role, username, search
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Course Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const addstudentPost = (username, status, admNum, regNum, name, course, rollNum, contOne, contTwo, father, mother, mail, gender, religion, category, dob, doa, address, aadhar, info , session) => {
    const url = SERVER + "/apiV1/user/"
    const body = {
        username,
        status,
        regNum,
        name,
        course,
        rollNum,
        "primary_contact" : contOne,
        "secondary_contact" : contTwo,
        "fateher_name": father,
        "mother_name" : mother,
        "email":mail,
        gender,
        religion,
        category,
        dob,
        "date_joined" : doa,
        address,
        aadhar,
        info,
        session
    }
    axios.post(
        url,
        body,
        { headers: { Authorization : `Basic cmFodWw6MTIzNDU2` } }
    ).then(res => {
        console.log(res)
        mdtoast.success("Course Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const managedropoutPost = (username, course, rollno, admissionno, search) => {
    const url = SERVER + "api/v1/course"
    const body = {
        username, course, rollno, admissionno, search
    }
    return axios.post(
        url,
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Course Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const managepassoutPost = (username, course, rollno, admissionno, search) => {
    // const url = SERVER + 
    const body = {
        username, course, rollno, admissionno, search
    }
    return axios.post(
        "api/v1/course",
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Course Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const managestudentPost = (username, course, rollno, admissionno, search) => {
    // const url = SERVER + 
    const body = {
        username, course, rollno, admissionno, search
    }
    return axios.post(
        "api/v1/course",
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Course Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}

const editprofilePost = (profilepicture, name, email, contactno, whatsappno) => {
    // const url = SERVER + 
    const body = {
        profilepicture, name, email, contactno, whatsappno
    }
    return axios.post(
        "api/v1/course",
        body
    ).then(res => {
        console.log(res)
        mdtoast.success("Course Successfully Created", { duration: 1000 })
    }).catch(err => {
        console.log(err)
        mdtoast.error("Error Occured", { duration: 1000 })
    })
}


export const formServices = {
    coursePost,
    infoPost,
    categoryPost,
    paymentPost,
    religionPost,
    sessionPost,
    eventPost,
    groupPost,
    holidayPost,
    postalPost,
    staffPost,
    messagestudentPost,
    backduesPost,
    demandPost,
    feegroupPost,
    multigroupPost,
    reminderPost,
    termPost,
    typePost,
    adjustmentPost,
    balancePost,
    collectionPost,
    coursewisePost,
    deletedfeereceiptPost,
    receiptwisePost,
    semesterbalancePost,
    semestercollectionPost,
    studentwithmorePost,
    staffaddPost,
    staffmanagePost,
    addstudentPost,
    managedropoutPost,
    managepassoutPost,
    managestudentPost,
    editprofilePost
}