import React, { useState } from "react";
import Select from "react-select";

const CertificateForm = ({heading, type, handlePost, currentCertificate, setCurrentCertificate}) => {

    const [username , setUsername] = useState("");
    const [course , setCourse] = useState("");
    const [rollno , setRollno] = useState("");
    const [admissionno , setAdmissionno] = useState("");
    const [certName, setCertName] = useState("")
    const [date, setDate] = useState("")
    const [authName, setAuthName] = useState("")
    const [search, setSearch] = useState("")

    const courses = [
        {label: "Information Technology", value: "it"},
        {label: "CSE", value: "cse"},
        {label: "Demo", value: "demo"}
    ]

    return (
        <div className="text-sm bg-white pt-4 px-5 pb-4 w-11/12 mx-auto rounded-md">
            <p className="text-xl font-bold gg-erp-color ">{heading}</p>
            
            <div className={"grid gap-2" + (type === "search" ? " md:grid-cols-2 lg:grid-cols-3" : " md:grid-cols-2 lg:grid-cols-4")}>
            
                <label for="usr" className="w-full py-2">Username
                    <input value={currentCertificate.user} className="form-input" type="text" placeholder="Username" name="usr" onChange={e => setCurrentCertificate({...currentCertificate, user: e.target.value})} /> 
                </label>

                { type === "search" ? 
                    <label for="crse" className="w-full py-2">Course
                    <Select 
                    options={courses}
                    value={currentCertificate.course}
                    onChange={e => setCurrentCertificate({...currentCertificate, user: e})}
                    placeholder="Select Course" 
                    isClearable={true} 
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                        ...theme.colors,
                        primary25: 'lightCyan',
                        primary: '#ff5722',
                        },
                    })}
                    />
                    </label> : null
                }

                { type === "search" ? 
                    <label for="name" className="w-full py-2">Student Name
                        <input className="form-input" type="text" placeholder="Name" name="roll_no" onChange={e => setCurrentCertificate({...currentCertificate, student: e.target.value})} /> 
                    </label> : null
                }

                { type === "search" ? 
                    <label for="adm_no" className="w-full py-2">Admission No.
                        <input onChange={e => setCurrentCertificate({...currentCertificate, admission: e.target.value})} className="form-input" type="text" placeholder="Admission No." name="adm_no" /> 
                    </label>: null
                }

                <label for="iss" className="w-full py-2">Issue Certificate Name
                    <input value={currentCertificate.name} onChange={e => setCurrentCertificate({...currentCertificate, name: e.target.value})} className="form-input" type="text" placeholder="Issue Certificate Name" name="adm_no"  /> 
                </label>

                <label for="issdate" className="w-full py-2">Issue Date
                    <input value={currentCertificate.date} onChange={e => setCurrentCertificate({...currentCertificate, date: e.target.value})} className="form-input" type="date" placeholder="Issue Date" name="adm_no" onChange={e => setDate(e.target.value)} /> 
                </label>

                <label for="auth" className="w-full py-2">Name of Issuing Authority
                    <input value={currentCertificate.authority} onChange={e => setCurrentCertificate({...currentCertificate, authority: e.target.value})} className="form-input" type="text" placeholder="Name of Issuing Authority" name="adm_no" onChange={e => setAuthName(e.target.value)} /> 
                </label>

                { type === "search" ? 
                    <label for="search" className="w-full py-2">Search
                        <input value={currentCertificate.search} onChange={e => setCurrentCertificate({...currentCertificate, search: e.target.value})} className="form-input" type="search" placeholder="Search" name="srch" onChange={e => setSearch(e.target.value)}/> 
                    </label> : null
                }

                {type === "search" ? null : 
                    <label for="name" className={"w-full py-2" + (type==="search" ? "" : " lg:col-span-2")}>Attachment
                        <input className="form-input p-1" type="file" placeholder="Name" name="roll_no" onChange={e => setRollno(e.target.value)} /> 
                    </label>
                }

                <div className={"text-right mt-1" + (type==="search" ? "" : " lg:col-span-2")}>
                    <button type="submit" className="submit w-full py-1.5 mt-4" onClick = {handlePost}>{type === "search" ? "Search" : "Submit"}</button>
                </div>
            </div>
        </div>
    )
}

export default CertificateForm