import React from "react";
import '../../../styles/button.css'

const SMSForm = () => {

    return (
        <div className="bg-white p-5 w-11/12 mx-auto rounded-t-md md:rounded-md rounded-b-none">

            <p className="text-xl font-bold gg-erp-color mb-2">SMS Setting</p>

            <div className="grid w-full md:grid-cols-2 lg:grid-cols-4">
                
                <label for="fee" className="toggle font-medium lg:mt-4"><span className="mr-2"> Fee SMS </span>
                    <input className=" toggle__input" type="checkbox" id="fee"/>
                    <span className="toggle-track">
                    <span className="toggle-indicator">
                    </span>
			        </span>
                </label>

                <label for="reg" className="toggle font-medium lg:mt-4"><span className="mr-2"> Admission SMS </span>
                    <input className=" toggle__input" type="checkbox" id="reg"/>
                    <span className="toggle-track">
                    <span className="toggle-indicator">
                    </span>
			        </span>
                </label>

                <label for="vis" className="toggle font-medium lg:mt-4"><span className="mr-2"> Visitor SMS </span>
                    <input className=" toggle__input" type="checkbox" id="vis"/>
                    <span className="toggle-track">
                    <span className="toggle-indicator">
                    </span>
			        </span>
                </label>

                <label for="query" className="toggle font-medium"><span className="mr-2"> Admission Query SMS </span>
                    <input className=" toggle__input" type="checkbox" id="query"/>
                    <span className="toggle-track">
                    <span className="toggle-indicator">
                    </span>
			        </span>
                </label>

            </div>
            <hr className="mt-3"/>
            <div className=" text-right pt-2">
                <button className="submit" type="submit">Submit</button>
            </div>
        </div>
    )
}

export default SMSForm