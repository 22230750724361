import React, { useState, useEffect } from "react";
import axios from "axios";
import { SERVER } from "../../../utils";
// import { formServices } from "../../../services/formServices";
import mdtoast from '@dmuy/toast'
import Switch from "react-switch";
import { authHeader } from "../../../helper/authHeader";
import Select from "react-select"


const BundleForm = ({ updatetable, currentsession, requesttype, setRequesttype, setCurrentsession  }) => {

    // const url = SERVER + "api/";
    const categories = []
    const [products, setProducts] = useState([])
    const handlePost = async () => {
        const body = {
            bundle_name: currentsession.bundle_name,
            items: currentsession.items.map(data => data.value)
        }
        console.log(body)
        try {
            if (requesttype == 'post') {
                console.log(body)
                // const url = SERVER + "/apiV1/session/"
                const res = await axios.post("/api/v1/bundle", body, { headers: authHeader() });
                mdtoast.success("Item Bundle Successfully Created", { duration: 1000 })
            } else {
                // const url = SERVER + + currentsession.id + "/"
                const res = await axios.put(`/api/v1/bundle/${currentsession.id}`, body, {
                    headers: authHeader()
                });
                mdtoast.success("Item Bundle Successfully Edited", { duration: 1000 })
                setRequesttype("post")
            }
            setCurrentsession({
                "id": "",
                "bundle_name": "",
                "items": []
            })

            // formServices.sessionPost(session, start, end, current);
            updatetable()
        } catch (err) {
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }

    }

    useEffect(() => {
        const getData = async () => {
            try {
                var res = await axios.get("/api/v1/product", { headers: authHeader() })
                var productlist = res.data.map((item) => {
                    return { "label": item.name, "value": item.id }
                })
                setProducts(productlist)
            } catch (err) {
                console.log(err)
            }
        }
        getData()
    }, [window.url])


    useEffect(() => {
       
    }, [window.url , currentsession.id])

    return (
        <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-t-md md:rounded-md rounded-b-none">
            <p className="text-xl font-bold gg-erp-color ">{requesttype=="post" ? "ITEM BUNDLE" : "EDIT BUNDLE"}</p>

            <label for="bundle_name" className="w-full py-2">Bundle Name
                <input className="form-input" value={currentsession.bundle_name} onChange={(e) => { setCurrentsession({ ...currentsession, 'bundle_name': e.target.value }) }} type="text" placeholder="Bundle Name" name="session" />
            </label>

            <label for="item" className="w-full py-2">Item
                {/* <input className="form-input" value={currentsession.session} onChange={(e) => { setCurrentsession({ ...currentsession, 'session': e.target.value }) }} type="text" placeholder="Session Name" name="session" /> */}
                <Select
                        options={products}
                        value={currentsession.items}
                        onChange={(option) => setCurrentsession({...currentsession,items: [...option]})}
                        isMulti
                        placeholder="Select Item"
                        isClearable={true}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                                ...theme.colors,
                                primary25: 'lightCyan',
                                primary: '#ff5722',
                            },
                        })}
                    />
            </label>
            <hr />
            <div className="text-right mt-2">
                <button type="submit" onClick={handlePost} className="submit">Submit</button>
            </div>
        </div>
    )
}

export default BundleForm