import React, { useState } from "react";
import NavBar from "../../components/navbar-components/NavBar";
import Footer from "../../components/Footer";
import Table from "../../components/Table";
import axios from "axios";
import { authHeader } from "../../helper/authHeader";
import mdtoast from "@dmuy/toast";

const tableItems = [
    {"Class": null, "General": null, "OBC": null, "EBC": null, "SC": null, "ST": null,empty: true}
]

const SupplierForm = () => {
    const [data,setData] = useState({
        "name": "",
        "company_name": "",
        "company_address": "",
        "email": "",
        "phone_no": "",
        "desc": ""
    })
    const handlePost = async () => {
        const body = {...data}
        console.log(body)
        try {
            const res = await axios.post("api/v1/supplier", body, { headers: authHeader() });
            mdtoast.success("Supplier Successfully Added", { duration: 1000 })
            setData({
                "name": "",
                "company_name": "",
                "company_address": "",
                "email": "",
                "phone_no": "",
                "desc": ""
            })
        } catch (err) {
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }
    }

    return (
        <>
        <NavBar/>
        <div className="bg-gray-200">
            <div className="mx-auto w-full bg-gray-200 py-5">
                <div className="mx-auto w-11/12 bg-white pt-2 rounded">
                    <p className="w-11/12 mx-auto text-2xl font-bold gg-erp-color py-4 pt-4">ADD SUPPLIER</p>
                    <p className="pt-2 w-11/12 mx-auto text-lG font-bold py-4">MAIN PARAMETERS</p>
                    <div className="w-11/12 text-sm grid md:grid-cols-2 gap-2 mx-auto">
                        <label for="text" className="py-2 w-full">Name
                            <input type="text" name="sur" className="form-input p-1" value={data.name} onChange={e => setData({...data,name: e.target.value })}/>
                        </label>
                        <label for="text" className="py-2 w-full">Company Name
                            <input type="text" name="sur" className="form-input p-1" value={data.company_name} onChange={e => setData({...data,company_name: e.target.value })}/>
                        </label>
                        <label for="text" className="py-2 w-full">Company Address
                            <input type="text" name="sur" className="form-input p-1" value={data.company_address} onChange={e => setData({...data,company_address: e.target.value })} />
                        </label>
                        <label for="text" className="py-2 w-full">Email
                            <input type="text" name="sur" className="form-input p-1" value={data.email} onChange={e => setData({...data,email: e.target.value })}/>
                        </label>
                        <label for="text" className="py-2 w-full">Phone No.
                            <input type="text" name="sur" className="form-input p-1" value={data.phone_no} onChange={e => setData({...data,phone_no: e.target.value })}/>
                        </label>
                        <label for="text" className="py-2 w-full">Description
                            <textarea type="text" name="sur" className="form-input p-1" value={data.desc} onChange={e => setData({...data,desc: e.target.value })}/>
                        </label>
                    </div>
                    <hr className="w-11/12 mx-auto"/>
                    <div className="w-11/12 mx-auto text-right mt-2 py-2 pb-4">
                            <button onClick={handlePost} type="submit" className="submit text-sm px-4">Submit</button>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        </> 
    )
}

export default SupplierForm