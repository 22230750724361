import React , {useState , useEffect} from "react";
import Footer from "../../components/Footer";
import NavBar from "../../components/navbar-components/NavBar";
import CategoryForm from "../../components/forms/Configuration/Category"
import Table from "../../components/Table";
import IncomeCategoryForm from "../../components/forms/Accounts/IncomeCategoryForm";
import AccountHeadForm from "../../components/forms/Accounts/AccountHeadForm";
import { SERVER } from "../../utils";
import axios from "axios";

// const tableItems = [
//     { "Sr no.": null, "Head": null, "Status": null, "Action": null, empty: true }
// ]

const AccountHead = () => {

    const [tableItems , setTableItems] = useState([
        { "Sr no.": null, "Head": null, "Status": null, "Action": null, empty: true }
    ])

    const EditFunction =  async (id)=>{
        console.log(id + "has been edited")
    }

    const DeleteFunction =  async (id)=>{
        console.log(id + "has been deleted")
    }

    const updatetable = async ()=>{
        try{
            var res = await axios.get( SERVER + "/apiV1/accounthead/" , { headers: { Authorization : `Basic cmFodWw6MTIzNDU2` } });
            console.log(res.data)
            if (res.data.length>0){
                var newtable = res.data.map((item , idx)=>{
                    return {"Sr no.": idx+1, "Head": item.head, "Status": item.status, "Action" : "Action" }
                })
                setTableItems(newtable)
            }
        }catch(err){
            console.log(err);
        }
    }

    useEffect(async ()=>{
        updatetable()
    },[window.url])



    return (
        <>
            <NavBar />
            <div className="bg-gray-200">
                <div className="mx-auto md:flex md:w-11/12 justify-center bg-gray-200 py-5">
                    <div className="md:w-3/12 flex-shrink-0">
                        <AccountHeadForm updatetable={updatetable} />
                    </div>
                    <div className="flex items-center bg-white py-3 w-11/12 md:w-9/12 mx-auto rounded-b-md md:rounded-md">
                        <div className="w-full">
                            <div className="text-right mr-5 w-11/12 mx-auto text-sm">
                                <button type="submit" className="submit">Inactive</button>
                            </div>
                            <Table tableItems={tableItems} EditFunction={EditFunction} DeleteFunction={DeleteFunction} />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default AccountHead