import React, { useState, useEffect } from "react";
import NavBar from "../../components/navbar-components/NavBar";
import Footer from "../../components/Footer";
import Table from "../../components/Table";
import Select from "react-select"

// const tableItems = [
//     {"S.no": null, "Name": null, "Account Head": null, "Payment Method": null, "Amount": null, "Date": null, "Description":null, empty: true}
// ]
const ExpenseReport = () => {
    const [startdate, setStartdate] = useState("");
    const [enddate, setEnddate] = useState("");
    const [category, setCategory] = useState("");
    const [paymentmethod, setPaymentmethod] = useState("");
    const [groupby, setGroupby] = useState(false)
    const [tableItems, setTableItems] = useState([
        { "S.no": null, "Name": null, "Account Head": null, "Payment Method": null, "Amount": null, "Date": null, "Description": null, empty: true }
    ])

    const categories = [
        {label: "SC", value: "it"},
        {label: "ST", value: "cse"},
        {label: "OBC", value: "demo"}
    ]
    const payMethods = [
        {label: "Method 1", value: "it"},
        {label: "Method 2", value: "cse"},
        {label: "Method 3", value: "demo"}
    ]

    useEffect(() => {
        console.log("groupby changed", groupby)
        if (groupby) {
            setTableItems([
                { "S.no": null, "Head": null, empty: true }
            ])
        } else {
            setTableItems([
                { "S.no": null, "Name": null, "Account Head": null, "Payment Method": null, "Amount": null, "Date": null, "Description": null, empty: true }
            ])
        }
    }, [groupby])

    const handlePost = () => {

    }
    return (
        <>
            <NavBar />
            <div className="bg-gray-200">
                <div className="mx-auto w-full bg-gray-200 py-5">
                    <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-md">
                        <p className="text-xl font-bold gg-erp-color ">EXPENSE REPORT</p>
                        <label>
                            Group By Expense Category
                            <input
                                name="isGoing"
                                type="checkbox"
                                checked={groupby}
                                className="ml-2"
                                onChange={e => setGroupby(e.target.checked)} />
                        </label>

                        <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-2">
                            <label for="usr" className="w-full py-2">Start Date
                                <input className="form-input" type="date" placeholder="Start Date" name="usr" onChange={e => setStartdate(e.target.value)} />
                            </label>

                            <label for="crse" className="w-full py-2">End Date
                                <input className="form-input" type="date" placeholder="End Date" name="crse" onChange={e => setEnddate(e.target.value)} />
                            </label>
                            <label for="crse" className="w-full py-2">Select Category
                            <Select 
                                options={categories}
                                value={category}
                                onChange = {(option)=>setCategory(option)}
                                placeholder="Select Category" 
                                isClearable={true} 
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                    ...theme.colors,
                                    primary25: 'lightCyan',
                                    primary: '#ff5722',
                                    },
                                })}
                            />
                            </label>
                            <label for="crse" className="w-full py-2">Payment Method
                            <Select 
                                options={payMethods}
                                value={paymentmethod}
                                onChange = {(option)=>setPaymentmethod(option)}
                                placeholder="Select Payment Method" 
                                isClearable={true} 
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                    ...theme.colors,
                                    primary25: 'lightCyan',
                                    primary: '#ff5722',
                                    },
                                })}
                            />
                            </label>

                        </div>
                        <hr />
                        <div className=" text-center mt-2">
                            <button type="submit" className="submit w-full" onClick={handlePost}>Search</button>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div className="text-center mr-2">
                                <button type="submit" className="submit" onClick={handlePost}>Print Report</button>
                            </div>
                            <div className=" text-center">
                                <button type="submit" className="export mt-2 text-sm" onClick={handlePost}>Export to Excel</button>
                            </div>
                        </div>
                    </div>
                    <div className=" bg-white py-3 w-11/12 mx-auto rounded-b-md md:rounded-md">
                        <Table tableItems={tableItems} />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}


export default ExpenseReport