import React from "react";
import axios from "axios";
import mdtoast from '@dmuy/toast'
import { SERVER } from "../../../utils";
import { authHeader } from "../../../helper/authHeader"

const SemesterForm = ({updatetable, currentSemester, requesttype, setRequesttype, setCurrentSemester}) => {

    const handlePost = async () => {
        const body = {
            semester_name: currentSemester.semester,
            status: true
        }
        try {
            if (requesttype == 'post') {
                // const url = SERVER + "/apiV1/semester/"
                const res = await axios.post("/apiV1/semester/", body, { headers: authHeader() });
                mdtoast.success("Semester Successfully Created", { duration: 1000 })

            } else {
                console.log(body)
                // const url = SERVER + "/apiV1/semester/" + currentSemester.id + "/"
                const res = await axios.put(`/apiV1/semester/${currentSemester.id}/`, body, { headers: { 
                    ...authHeader(),      
                    'content-type': 'application/json',
                    'Accept': 'application/json' } });
                mdtoast.success("Semester Successfully Edited", { duration: 1000 })
                setRequesttype("post")
            }
            setCurrentSemester({
                semester: "",
                status: true
            })

            updatetable()
        } catch (err) {
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }
    } 
    

    return (
        <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-t-md md:rounded-md rounded-b-none">
            <p className="text-xl font-bold gg-erp-color ">ADD SEMESTER</p>
            <label for="religion" className="mb-2 w-full py-2">Semester Name
                <input className="form-input" placeholder="Semester Name" type="text" name="religion" value={currentSemester.semester} onChange={e => setCurrentSemester({...currentSemester, semester: e.target.value})}/>
            </label>
            <hr/>
            <div className="text-right mt-2">
                <button onClick={handlePost} type="submit" className="submit">Submit</button>
            </div>
        </div>
    )
}

export default SemesterForm