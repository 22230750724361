import React, {useState, useEffect} from "react";
import ManageForm from "../../components/forms/Staff/Manage"
import Footer from "../../components/Footer";
import NavBar from "../../components/navbar-components/NavBar";
import Table from "../../components/Table";
import axios from "axios";
import { SERVER } from "../../utils";
import { authHeader } from "../../helper/authHeader";
import mdtoast from "@dmuy/toast";

const tableItems = [
    {"S.no": null,"Name": null, "Registration No": null, empty:true}
]


const Manage = () => {

    const [tableItems, setTableItems] = useState([]);
    const [showItems, setShowItems] = useState([
        { "id": "", "Sr. No": "", Username: "", "Name": "", "Department": "", "Designation": "", "Qualification": "", Contact: "", "Father's Name": "", "Action": "Action", empty: true }
    ])

    useEffect(async () => {
        updatetable()
    }, [window.url])

    // "id": 7,
    // "user": {
    //     "id": "713fe5e6-96fb-46e8-b37c-07a7631a7a95",
    //     "email": "newname@gmail.com",
    //     "name": "newname",
    //     "contact": "1234557",
    //     "profile_image": null,
    //     "father_name": "asc",
    //     "mother_name": "",
    //     "dob": "2022-01-01",
    //     "address": "ac",
    //     "pin_code": 12345,
    //     "username": "casc",
    //     "enrollment_id": null,
    //     "password": "pbkdf2_sha256$260000$evqxO9QTxn45YhI8NJ7Q1r$4mQ6rqaGUmRDaeEmrmoxkeVhTyx00vyX+CisqHJ3+oQ="
    // },
    // "date_of_joining": "2021-12-09",
    // "qualification": "acsac",
    // "department": 8,
    // "designation": null
    // }

    const updatetable = async () => {
        try {
            var res = await axios.get("/apiV1/staff-profile/", { headers: authHeader() });
            if (res.data.length > 0) {
                let count = 0
                var newtable = res.data.map(item => {
                    count++
                    return { "id": item.id,extra: false, "Sr. No": count, Username: item.user.username, "Name": item.user.name, "Department": (item.department != null ? item.department : null ), "Designation": item.designation, "Qualification": item.qualification, Contact: item.user.contact, "Father's Name": item.user.father_name, extraInfo: {Email: item.user.email, "Date of Joining": item.user.date_of_admission,"Date of birth": item.user.dob,Address: item.user.address, "Pin Code": item.user.pin_code, Religion: item.user.student_religion, Category: item.user.student_category}, "Action": "Action" }
                }) 
                setTableItems(res.data)
                setShowItems(newtable)
                // console.log(newtable)
            }
        } catch (err) {
            console.log(err);
        }
    }

    const DeleteFunction =  async (id)=>{
        try{
            // const url = SERVER + "/apiV1/staff-profile/" + id
            const res = await axios.delete(`/apiV1/staff-profile/${id}/`, { headers: authHeader() });
            mdtoast.success("Staff Deleted Successfully ", { duration: 1000 })
            setTableItems(tableItems.filter((item)=>item.id !== id))
        }catch (err) {
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }
        // return mdtoast.error("Staff cannot be deleted", { duration: 1000 })
    }

    const EditFunction = async () => {
        return mdtoast.error("Edit function is in development", { duration: 1000 })
    }
    return (
        <>
        <NavBar/>
        <div className="bg-gray-200">
            <div className="mx-auto w-full bg-gray-200 py-5">
                    <ManageForm tableItems={tableItems} setShowItems={setShowItems}/>
                <div className=" bg-white py-3 w-11/12 mx-auto rounded-b-md md:rounded-md">
                    <Table tableItems={showItems} DeleteFunction={DeleteFunction} EditFunction={EditFunction} setTable={setTableItems}/>
                </div>
            </div>
        </div>
        <Footer/>
        </> 
    )
}

export default Manage