import React , {useState, useEffect} from 'react'
// import { formServices } from '../../../services/formServices';
import Select from 'react-select';
import { SERVER } from "../../../utils";
import axios from 'axios';
import mdtoast from '@dmuy/toast';
import { authHeader } from '../../../helper/authHeader';

const AddForm = () => {
    
    const [username , setUsername] = useState("");
    const [password , setPassword] = useState("");
    const [name , setName] = useState("");
    const [contact1 , setContact1] = useState("");
    const [pinCode , setPin] = useState("");
    const [email , setEmail] = useState("");
    const [gender , setGender] = useState("");
    const [dob, setDob] = useState("");
    const [role , setRole] = useState("");
    const [department , setDepartment] = useState("");
    const [designation , setDesignation] = useState("");
    const [joining , setJoining] = useState("");
    const [qualification , setQualification] = useState("");
    const [address , setAddress] = useState("");
    const [fathername , setFathername] = useState("");

    const [departments,setDepartments] = useState([])
    const [designations,setDesignations] = useState([])

    const roles = [
        {label: "Superadmin", value: 1},
        {label: "Admin", value: 2},
        {label: "Coordinator", value: 3},
        {label: "Accountant", value: 4}
    ]

    const genders = [
        {label: "Male", value: "male"},
        {label: "Female", value: "female"}
    ]

    // const designations = [
    //     {label: "Designation 1", value: "dep1"},
    //     {label: "Designation 2", value: "dep2"}
    // ]

    useEffect(()=>{
        const getData = async () => {
            try{
                var res = await axios.get("/apiV1/department/" ,  { headers: authHeader() })
                var departmentlist = res.data.map((item)=>{
                    return {"label": item.department_name , "value" : item.id}
                })
                setDepartments(departmentlist)
            }catch(err){
                console.log(err)
            }
        }
        getData()
    } , [window.url])

    useEffect(()=>{
        const getData = async () => {
            try{
                var res = await axios.get("/apiV1/designation/" ,  { headers: authHeader() })
                var designationlist = res.data.map((item)=>{
                    return {"label": item.designation , "value" : item.id}
                })
                setDesignations(designationlist)
            }catch(err){
                console.log(err)
            }
        }
        getData()
    } , [window.url])



    // const handlePost = () => {
    //     return formServices.staffaddPost( username , password , name , contact1 , contact2 , email , gender , dob , role , department , designation , joining , qualification , address , fathername)
    // }

    const handlePost = async () => {
        // formServices.addstudentPost(username , status.value , admNum , regNum , name , department.value , rollNum , contOne , contTwo , father , mother , mail , gender.value , religion.value , category.value , dob , doa , address , aadhar , info , session.value)
        // const url = SERVER + "/apiV1/add-staff/"
    
        const body ={
            "email": email,
            "name": name,
            "contact": contact1,
            "password": password,
            "profile_image": null,
            "father_name": fathername,
            "roles": role.value,
            "dob": dob,
            "address": address,
            "pin_code": pinCode,
            // "username": username,
            "staff_profile": {
                "department": department.value,
                "designation": designation.value,
                "date_of_joining": joining,
                "qualification": qualification
            } 
        }
        console.log(body)
        try {
            const res = await axios.post("/apiV1/add-staff/", body, { headers: authHeader() });
            console.log(body)
            mdtoast.success("Staff Successfully Created", { duration: 1000 })
            setUsername("")
            setPassword("")
            setName("")
            setContact1("")
            setPin("")
            setEmail("")
            setGender("")
            setDob("")
            setDepartment("")
            setDesignation("")
            setJoining("")
            setQualification("")
            setAddress("")
            setFathername("")
            setRole("")
        } catch (err) {
            mdtoast.error("Error Occured", { duration: 1000 })
        }
    }

    return(
    <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-md">
        <p className="text-xl font-bold gg-erp-color ">STAFF DATABASE</p>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-2">
        <label for="usr" className=" mb-2 w-full py-2">Username
            <input className="form-input" disabled={true} placeholder="Username" value={username} type="text" name="usr" onChange={e => setUsername(e.target.value)}/>
        </label>

        <label for="psd" className="mb-2 w-full py-2">Password
            <input className="form-input" placeholder="Password" value={password} type="text" name="usr" onChange={e => setPassword(e.target.value)}/>
        </label>

        <label for="name" className="mb-2 w-full py-2">Name
            <input className="form-input" placeholder="Name" type="text" value={name} name="usr" onChange={e => setName(e.target.value)}/>
        </label>

        <label for="cont1" className="mb-2 w-full py-2">Contact
            <input className="form-input" type="text" name="cont1" value={contact1} onChange={e => setContact1(e.target.value)}/>
        </label>

        <label for="mail" className="mb-2 w-full py-2">Email
            <input className="form-input" type="email" name="mail" value={email} onChange={e => setEmail(e.target.value)}/>
        </label>

        <label for="gender" className="mb-2 w-full py-2">Gender
        <Select 
                options={genders}
                value={gender}
                onChange = {(option)=>setGender(option)}
                placeholder="Select Gender" 
                isClearable={true} 
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                      ...theme.colors,
                      primary25: 'lightCyan',
                      primary: '#ff5722',
                    },
                  })}
                />
        </label>

        <label for="dob" className="mb-2 w-full py-2">Date Of Birth
            <input className="form-input" type="date" name="dob" value={dob} onChange={e => setDob(e.target.value)}/>
        </label>

        <label for="role" className="mb-2 w-full py-2">Staff Role
            <Select 
                options={roles}
                value={role}
                onChange = {(option)=>setRole(option)}
                placeholder="Select Role" 
                isClearable={true} 
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                      ...theme.colors,
                      primary25: 'lightCyan',
                      primary: '#ff5722',
                    },
                  })}
            />
            {/* <input className="form-input" type="text" name="doj" value={role} onChange={e => setRole(e.target.value)}/> */}
        </label>

        <label for="dep" className="mb-2 w-full py-2">Department
            <Select 
                options={departments}
                value={department}
                onChange = {(option)=>setDepartment(option)}
                placeholder="Select Department" 
                isClearable={true} 
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                      ...theme.colors,
                      primary25: 'lightCyan',
                      primary: '#ff5722',
                    },
                  })}
                />
        </label>

        <label for="des" className="mb-2 w-full py-2">Designation
            <Select 
                options={designations}
                value={designation}
                onChange = {(option)=>setDesignation(option)}
                placeholder="Select Designation" 
                isClearable={true} 
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                      ...theme.colors,
                      primary25: 'lightCyan',
                      primary: '#ff5722',
                    },
                  })}
                />
            {/* <input className="form-input" type="text" name="doj" value={designation} onChange={e => setDesignation(e.target.value)}/> */}
        </label>
        
        <label for="doj" className="mb-2 w-full py-2">Date Of Joining
            <input className="form-input" type="date" name="doj" value={joining} onChange={e => setJoining(e.target.value)}/>
        </label>
        
        <label for="qual" className="mb-2 w-full py-2">Qualification
            <input className="form-input" type="text" name="qual" value={qualification} onChange={e => setQualification(e.target.value)}/>
        </label>
        
        <label for="addr" className="mb-2 w-full py-2">Address
            <input className="form-input" type="text" name="addr" value={address} onChange={e => setAddress(e.target.value)}/>
        </label>
        
        <label for="cont2" className="mb-2 w-full py-2">Pin Code
            <input className="form-input" type="text" name="cont2" value={pinCode} onChange={e => setPin(e.target.value)}/>
        </label>

        <label for="father" className="mb-2 w-full py-2">Father Name
            <input className="form-input" type="text" name="father" value={fathername} onChange={e => setFathername(e.target.value)}/>
        </label>

        </div>

        <hr/>
        <div className="text-right mt-2">
            <button type="submit" className="submit" onClick = {handlePost}>Submit</button>
        </div>
    </div>
    )
}

export default AddForm