import React, { useState, useEffect } from "react";
import NavBar from "../../components/navbar-components/NavBar";
import Footer from "../../components/Footer";
import Table from "../../components/Table";
import Dropdown from "@material-tailwind/react/Dropdown"
import Button from "@material-tailwind/react/Button";
import DropdownItem from "@material-tailwind/react/DropdownItem"
import { Link } from "react-router-dom";

const tableItems = [
    { "S.no": null, "Date": null, "Fee Income": null, "Inventory Income": null, "Other Income": null, "Total Income": null, "Expense": null, "Inventory Expense":null, "Total Expense": null, "Balance":null, empty: true }
]
const AccountReport = () => {
    const [startdate, setStartdate] = useState("");
    const [enddate, setEnddate] = useState("");
    const [category, setCategory] = useState("");
    const [paymentmethod, setPaymentmethod] = useState("");
    const [groupby, setGroupby] = useState(false)
    // const [tableItems, setTableItems] = useState([
    //     { "S.no": null, "Name": null, "Account Head": null, "Payment Method": null, "Amount": null, "Date": null, "Description": null, empty: true }
    // ])

    // useEffect(() => {
    //     console.log("groupby changed", groupby)
    //     if (groupby) {
    //         setTableItems([
    //             { "S.no": null, "Head": null, empty: true }
    //         ])
    //     } else {
    //         setTableItems([
    //             { "S.no": null, "Name": null, "Account Head": null, "Payment Method": null, "Amount": null, "Date": null, "Description": null, empty: true }
    //         ])
    //     }
    // }, [groupby])

    const handlePost = () => {

    }
    return (
        <>
            <NavBar />
            <div className="bg-gray-200">
                <div className="mx-auto w-full bg-gray-200 py-5">
                    <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-md">
                        <div className="md:flex justify-between grid">
                        <p className="text-xl font-bold gg-erp-color ">DAILY/MONTHLY ACCOUNTS REPORT</p>
                        <Dropdown
                                // color={"rgba(31, 41, 55,)"}
                                placement="bottom-start"
                                buttonText="Account Type"
                                buttonType="filled"
                                size="sm"
                                rounded={false}
                                block={false}
                                color = "red"
                                ripple="light"
                                className="mt-4"
                            >
                             
                                        <DropdownItem
                                            // href={item.link ? "/#/React/" + item.link : "#"}
                                            color={"red"}
                                            ripple="light"
                                        >
                                            Daily Accounts
                                        </DropdownItem>
                                        <DropdownItem
                                            // href={item.link ? "/#/React/" + item.link : "#"}
                                            color={"red"}
                                            ripple="light"
                                        >
                                            Monthly Accounts
                                        </DropdownItem>
                                
                            </Dropdown>
                        </div>

                        <div className="grid md:grid-cols-2 gap-2">
                           
                            <label for="usr" className="w-full py-2">Start Date
                                <input className="form-input" type="date" placeholder="Start Date" name="usr" onChange={e => setStartdate(e.target.value)} />
                            </label>

                            <label for="crse" className="w-full py-2">End Date
                                <input className="form-input" type="date" placeholder="End Date" name="crse" onChange={e => setEnddate(e.target.value)} />
                            </label>
                   
                        </div>
                        <hr />
                        <div className=" text-center mt-2">
                            <button type="submit" className="submit w-full" onClick={handlePost}>Search</button>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div className=" text-center mr-2">
                                <button type="submit" className="submit" onClick={handlePost}>Print Report</button>
                            </div>
                            <div className=" text-center">
                                <button type="submit" className="export mt-2" onClick={handlePost}>Export to Excel</button>
                            </div>
                        </div>
                    </div>
                    <div className=" bg-white py-3 w-11/12 mx-auto rounded-b-md md:rounded-md">
                        <Table tableItems={tableItems} />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}


export default AccountReport