import React , {useState} from "react";
import { formServices } from "../../../services/formServices";

const HolidayForm = () => {
    const [holiday, setHoliday] = useState("")
    const [from, setFrom] = useState("")
    const [to, setTo] = useState("")
    const [days, setDays] = useState("")
    const [desc, setDesc] = useState("")

    const handlePost = () => {
        return formServices.holidayPost(holiday, from , to , days , desc)
    }

    return (
        <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-md">
            <p className="text-xl font-bold gg-erp-color ">HOLIDAYS</p>
            
            <div className="grid gap-2 md:grid-cols-2 lg:grid-cols-4">   
                <label for="session_name" className="w-full py-2">Holiday's Name
                    <input className="form-input" type="text" placeholder="Holiday's Name" name="holiday name" onChange={e => setHoliday(e.target.value)}/> 
                </label>

                <label for="from_date" className="w-full py-2">From Date
                    <input type="date" placeholder="dd-mm-yyyy" name="from_date" className="form-input" onChange={e => setFrom(e.target.value)}/> 
                </label>

                <label for="to_date" className="w-full py-2">To Date
                    <input type="date" placeholder="dd-mm-yyyy" name="to_date" className="form-input" onChange={e => setTo(e.target.value)}/>  
                </label>

                <label for="no_of_days" className="w-full py-2">No of Days
                    <input type="number" placeholder="No. of Days" name="no_of_days" className="form-input" onChange={e => setDays(e.target.value)}/>  
                </label>
            </div>

            <label for="description" className="mb-2 w-full py-2">Description
                <textarea className="w-full form-input"  type="text" name="description" onChange={e => setDesc(e.target.value)}/>
            </label>
            <hr/>
            <div className="text-right mt-2">
                <button type="submit" className="submit" onClick = {handlePost}>Submit</button>
            </div>
        </div>
    )
}

export default HolidayForm