import React from "react";
import axios from "axios";
import mdtoast from '@dmuy/toast'
import { SERVER } from "../../../utils";
import { authHeader } from "../../../helper/authHeader"
import Switch from "react-switch";

const ItemCatForm = ({ updatetable, currentCategory, requesttype, setRequesttype, setCurrentCategory }) => {

    const handlePost = async () => {
        // console.log(currentCategory)
        const body = {
            name: currentCategory.itemCat,
            status : currentCategory.status
        }
        try {
            if (requesttype == 'post') {
                console.log(body)
                // const url = SERVER + 
                const res = await axios.post("/api/v1/category", body, { headers: { ...authHeader() } });
                mdtoast.success("Category Successfully Created", { duration: 1000 })

            } else {
                console.log(body)
                // const url = SERVER + "" + currentDesignation.id + "/"
                const res = await axios.put(`/api/v1/category/${currentCategory.id}`, body, { headers: authHeader() });
                mdtoast.success("Category Successfully Edited", { duration: 1000 })
                console.log(res.headers)
                setRequesttype("post")
            }
            setCurrentCategory({
                itemCat: "",
                status : true
            })

            updatetable()
        } catch (err) {
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }
    }


    return (
        <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-t-md md:rounded-md rounded-b-none">
            <p className="text-xl font-bold gg-erp-color ">ITEM CATEGORY</p>
            <label for="religion" className="mb-2 w-full py-2">Item Category
                <input className="form-input" placeholder="Category Name" type="text" name="category" value={currentCategory.itemCat} onChange={e => setCurrentCategory({ ...currentCategory, itemCat: e.target.value })} />
            </label>
            <label for="religion" className="mb-2 w-full py-2">Status 
            <span className="p-2">
                <Switch checkedIcon={null} activeBoxShadow={null} width={52} height={26} handleDiameter={21} uncheckedIcon={null} checked={currentCategory.status} onColor="#ff5722" onChange={e => setCurrentCategory({ ...currentCategory, status: !currentCategory.status })} />
            </span>
            </label>
            <hr />
            <div className="text-right mt-2">
                <button onClick={handlePost} type="submit" className="submit">Submit</button>
            </div>
        </div>
    )
}

export default ItemCatForm