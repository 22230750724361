import React from "react";
// import ggErp from "../images/g-logo.png"
import bg from "../images/iso-certified.png"

const InfoCard = ({bgImg, logo, url, institute, address, contactTwo, contactOne, email}) => {
    const image = "https://i.ytimg.com/vi/4gPjozJ0y0g/maxresdefault.jpg"
    return (
        <>
        <div className="w-11/12 relative mx-auto h-auto rounded-md shadow-md overflow-hidden">
            <img className="w-100 " src={image} alt="pp" />
            <div style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",

            }} 
            className="flex absolute items-center w-3/5 justify-center">
                <div className="h-auto text-center text-white ">
                    <div className="bg-black bg-opacity-50 rounded-md p-2 ">   
                        <div className="py-2 md:py-4">
                            <img className="mx-auto rounded-full w-32 h-32 md:w-40 sm:h-36 sm:w-36 md:h-40 lg:w-48 lg:h-48 object-cover bg-white mb-2" src={logo} alt="Man looking at item at a store" />
                        </div> 
                        <p className="text-3xl font-bold">{institute}</p>
                        <p className="md:w-10/12 w-full mx-auto text-xs sm:text-sm md:text-base lg:text-lg">
                        <span className="font-bold">Address: </span> {address} || <span className="font-bold">Office no: </span>{contactTwo} || <span className="font-bold">Phone No: </span>{contactOne} || <span className="font-bold">Email: </span>{email}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default InfoCard