import React from "react";

const SubForm = () => {
    return (
        <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-md">
            <p className="text-xl font-bold gg-erp-color ">CREATE SUBJECT</p>
            <label for="extra_subject" className="mb-2 w-full py-2">Subject Name
                <input className="form-input" placeholder="Subject Name" type="text" name="extra_subject"/>
            </label>
            <hr/>
            <div className="text-right mt-2">
                <button type="submit" className="submit">Submit</button>
            </div>
        </div>
    )
}

export default SubForm