import React , {useState, useEffect} from "react";
import { formServices } from "../../../services/formServices";
import axios from "axios";
import Select from "react-select";
import { authHeader } from "../../../helper/authHeader";

const ManageForm = ({tableItems, setShowItems}) => {
    
    const [designation , setDesignation] = useState("");
    const [username , setUsername] = useState("");
    const [search , setSearch] = useState("");
 
    const [designations, setDesignations] =useState([]);
    // const designations = [
    //     {label: "Superadmin", value: 1},
    //     {label: "Admin", value: 2},
    //     {label: "Coordinator", value: 3},
    //     {label: "Accountant", value: 4}
    // ]
    // IS_SUPERADMIN = 1
    // IS_ADMIN = 2
    // IS_COORDINATOR = 3
    // IS_ACCOUNTANT = 4
    // IS_STUDENT = 5
    // "id": 7,
    // "user": {
    //     "id": "713fe5e6-96fb-46e8-b37c-07a7631a7a95",
    //     "email": "newname@gmail.com",
    //     "name": "newname",
    //     "contact": "1234557",
    //     "profile_image": null,
    //     "father_name": "asc",
    //     "mother_name": "",
    //     "dob": "2022-01-01",
    //     "address": "ac",
    //     "pin_code": 12345,
    //     "username": "casc",
    //     "enrollment_id": null,
    //     "password": "pbkdf2_sha256$260000$evqxO9QTxn45YhI8NJ7Q1r$4mQ6rqaGUmRDaeEmrmoxkeVhTyx00vyX+CisqHJ3+oQ="
    // },
    // "date_of_joining": "2021-12-09",
    // "qualification": "acsac",
    // "department": 8,
    // "designation": null
    // }

    useEffect(()=>{
        const getData = async () => {
            try{
                var res = await axios.get("/apiV1/designation/" ,  { headers: authHeader() })
                var designationlist = res.data.map((item)=>{
                    return {"label": item.designation , "value" : item.id}
                })
                setDesignations(designationlist)
            }catch(err){
                console.log(err)
            }
        }
        getData()
    } , [window.url])

    const handlePost = () => {

        if (tableItems.length > 0) {
            var newtable = []
            tableItems.forEach((item, index) => {
                if (username === "" ) {
                    newtable.push({ "id": item.id, "Name": item.name, "Department": "Not Available", "Enrollment No": item.enrollment_id, "Action": "Action" })
                }
                else if (item.name.toLowerCase() === username.toLowerCase()) {
                    newtable.push({ "id": item.id, "Name": item.user.name, "Department": item.department, "Designation": item.designation , "Action": "Action" })
                }
            })

            // var newtable = tableItems.map(item=>{
            //     if(item.name == username || item.enrollment_id==EnrollmentNo){
            //         return {"id": item.id, "Name": item.name, "Department": "Not Available", "Enrollment No": item.enrollment_id, "Action" : "Action" }
            //     }    
            // })
            // setTableItems(res.data)
            // console.log(newtable)
            setShowItems(newtable)
            // console.log(newtable)
        }


    }

    return (
        <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-md">
            <p className="text-xl font-bold gg-erp-color ">STAFF DATABASE</p>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-2">
                <label for="designation" className="mb-2 w-full py-2">Designation
                <Select 
                options={designations}
                value={designation}
                onChange = {(option)=>setDesignation(option)}
                placeholder="Select Designation" 
                isClearable={true} 
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                      ...theme.colors,
                      primary25: 'lightCyan',
                      primary: '#ff5722',
                    },
                  })}
                />
                </label>

                <label for="usr" className="mb-2 w-full py-2">Username
                    <input className="form-input" placeholder="Username" type="text" name="usr" onChange={e => setUsername(e.target.value)}/>
                </label>

                <label for="srch" className="mb-2 w-full py-2">Search
                    <input className="form-input" placeholder="Search Name/Number/Designation" type="text" name="usr" onChange={e => setSearch(e.target.value)}/>
                </label>
                <div className="text-center md:mt-5">
                    <button type="submit" className="w-full submit py-2" onClick = {handlePost}>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default ManageForm