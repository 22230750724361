import React from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import App from "./pages/App"

// Dashboard
import Dashboard from "./pages/dashboard/Dashboard";
import EditProfile from "./pages/EditProfilePage";

//accounts
import Income from "./pages/accounts/Income";
import Expenses from "./pages/accounts/Expenses";
import AccountHead from "./pages/accounts/AccountHead";
import AccountSummary from "./pages/accounts/AccountSummary";
import AccoutReport from "./pages/accounts/AccountReport";
import IncomeCategory from "./pages/accounts/IncomeCategory";
import IncomeReport from "./pages/accounts/IncomeReport";
import ExpenseReport from "./pages/accounts/ExpenseReport";
import ExpenseCategory from "./pages/accounts/ExpenseCategory";

// Configuration
import Session from "./pages/configuration/Session";
import Info from "./pages/configuration/Info";
import SMS from "./pages/configuration/SMS";
import Designation from "./pages/configuration/Designation";

// Academics
import Course from "./pages/academic/Course"
import Subject from "./pages/academic/Subject"
import Semester from "./pages/academic/Semester"
import SemesterSession from "./pages/academic/SemesterSession"
import Department from "./pages/academic/Department"

//Communicate
import Event from "./pages/communicate/Event"
import Student from "./pages/communicate/Student"
import Holiday from "./pages/communicate/Holiday"
import Staff from "./pages/communicate/Staff"
import Postal from "./pages/communicate/Postal"
import Group from "./pages/communicate/Group"
import Number from "./pages/communicate/Number"

//Student
import Admission from "./pages/student/Admission";
import Current from "./pages/student/Current";
import Passout from "./pages/student/Passout";
import Dropout from "./pages/student/Dropout";
import DropOrPass from "./pages/student/DropOrPass";
import Cerficate from "./pages/student/Certificate";

//Staff
import Add from "./pages/staff/Add";
import Manage from "./pages/staff/Manage";

//Fee
import Assign from "./pages/fee/Assign"
import BackDues from "./pages/fee/BackDues"
import Demand from "./pages/fee/Demand";
import GroupFee from "./pages/fee/GroupFee";
import Term from "./pages/fee/Term"
import Type from "./pages/fee/Type"
import FeePayment from "./pages/fee/FeePayment";
import Reminder from "./pages/fee/Reminder";

// Reports 
import Adjustment from "./pages/reports/Adjustment"
import Balance from "./pages/reports/Balance"
import Collection from "./pages/reports/Collection"
import CourseWise from "./pages/reports/CourseWise"
import DeletedFeeReceipt from "./pages/reports/DeletedFeeReceipt"
import SemesterBalance from "./pages/reports/SemesterBalance"
import SemesterCollection from "./pages/reports/SemesterCollection"
import StudentWithMore from "./pages/reports/StudentWithMore"
import ReceiptWise from "./pages/reports/ReceiptWise";
import DiseCategory from "./pages/reports/DiseCategory";
import DiseGender from "./pages/reports/DiseGender";
import DiseReligion from "./pages/reports/DiseReligion";

//dummy route
import Dummy from "./pages/Dummy";

import AddIncome from "./pages/accounts/AddIncome";
import AddExpenses from "./pages/accounts/AddExpenses";
import EditIncome from "./pages/accounts/EditIncome";
import EditExpense from "./pages/accounts/EditExpense";
import EditStudent from "./pages/student/EditStudent";
import ItemCategory from "./pages/Inventory/ItemCategory";
import AddItem from "./pages/Inventory/Add";
import Bundle from "./pages/Inventory/Bundle";
import Supplier from "./pages/Inventory/Supplier";
import AddPurchase from "./pages/Inventory/AddPurchase";
import Purchase from "./pages/Inventory/Purchase";
import Sell from "./pages/Inventory/Sell";
import SupplierForm from "./pages/Inventory/SupplierForm";
import AddSell from "./pages/Inventory/AddSell";
import Shop from "./pages/Inventory/Shop";
import CreateUser from "./pages/Inventory/User";

const Routes = () => {

    return (
        <HashRouter basename="/React">
            <Switch>
                <Route exact path="/">
                    <App />
                </Route>
                <Route exact path="/login" >
                    <Login />
                </Route>
                <Route exact path="/register" >
                    <Register />
                </Route>

                <Route exact path="/dummy" >
                    <Dummy />
                </Route>

                <Route exact path="/usercreate">
                    <CreateUser/>
                </Route>


                {/* INVENTORY */}
                <Route exact path="/inventory/category">
                    <ItemCategory/>
                </Route>
                <Route exact path="/inventory/add">
                    <AddItem/>
                </Route>
                <Route exact path="/inventory/bundle">
                    <Bundle/>
                </Route>
                <Route exact path="/inventory/supplier">
                    <Supplier/>
                </Route>

                <Route exact path="/inventory/shop">
                   <Shop/>
                </Route>
                <Route exact path="/inventory/purchase">
                    <Purchase/>
                </Route>

                <Route exact path="/inventory/addsell">
                    <AddSell/>
                </Route>

                <Route exact path="/inventory/addpurchase">
                    <AddPurchase/>
                </Route>

                <Route exact path="/inventory/sell">
                    <Sell/>
                </Route>
                <Route exact path="/inventory/supplierform">
                    <SupplierForm/>
                </Route>
                

                {/* <-- Dashboard --> */}
                <Route exact path="/dashboard" >
                    <Dashboard />
                </Route>

                <Route exact path="/editprofile" >
                    <EditProfile />
                </Route>

                {/* Accounts */}                    
                <Route exact path="/accounts/IncomeCategory" >
                    <IncomeCategory />
                </Route>
                <Route exact path="/accounts/ExpenseCategory" >
                    <ExpenseCategory />
                </Route>
                <Route exact path="/accounts/AccountHead" >
                    <AccountHead />
                </Route>
                <Route exact path="/accounts/Income" >
                    <Income />
                </Route>
                <Route exact path="/accounts/Expenses" >
                    <Expenses />
                </Route>
                <Route exact path="/accounts/IncomeReport" >
                    <IncomeReport />
                </Route>
                <Route exact path="/accounts/ExpenseReport" >
                    <ExpenseReport />
                </Route>
                <Route exact path="/accounts/AccountReport" >
                    <AccoutReport />
                </Route>
                <Route exact path="/accounts/AccountSummary" >
                    <AccountSummary />
                </Route>
                <Route exact path="/accounts/AddIncome" >
                    <AddIncome />
                </Route>

                <Route path="/accounts/EditIncome/:id" render = {(props)=> < EditIncome {...props}/>} />
                <Route path="/accounts/EditExpense/:id" render = {(props)=> < EditExpense {...props}/>} />
                
                <Route exact path="/accounts/AddExpenses" >
                    <AddExpenses />
                </Route>

                {/* <-- Configuration --> */}
                <Route exact path="/configuration/session" >
                    <Session />
                </Route>
                <Route exact path="/configuration/designation" >
                    <Designation />
                </Route>
                <Route exact path="/configuration/info" >
                    <Info />
                </Route>
                <Route exact path="/configuration/sms" >
                    <SMS />
                </Route>



                {/* Academic */}
                <Route exact path="/academic/course" >
                    <Course />
                </Route>
                <Route exact path="/academic/department" >
                    <Department />
                </Route>
                <Route exact path="/academic/semester" >
                    <Semester />
                </Route>
                <Route exact path="/academic/semester-session" >
                    <SemesterSession />
                </Route>
                <Route exact path="/academic/extra" >
                    <Subject />
                </Route>

                {/* Communicate */}
                <Route exact path="/communicate/sms/group" >
                    <Group />
                </Route>
                <Route exact path="/communicate/sms/student" >
                    <Student />
                </Route>
                <Route exact path="/communicate/staff" >
                    <Staff />
                </Route>
                <Route exact path="/communicate/number" >
                    <Number />
                </Route>
                <Route exact path="/communicate/holiday" >
                    <Holiday />
                </Route>
                <Route exact path="/communicate/event" >
                    <Event />
                </Route>
                <Route exact path="/communicate/postal" >
                    <Postal />
                </Route>

                {/* Student */}
                <Route exact path="/students/admission" >
                    <Admission />
                </Route>
                <Route path="/students/EditStudent/:id" render = {(props)=> < EditStudent {...props}/>} />
                <Route exact path="/students/current" >
                    <Current />
                </Route>
                <Route exact path="/students/passout" >
                    <Passout />
                </Route>
                <Route exact path="/students/dropout" >
                    <Dropout />
                </Route>
                <Route exact path="/students/manage" >
                    <DropOrPass />
                </Route>
                <Route exact path="/students/certificates" >
                    <Cerficate />
                </Route>

                {/* Staff */}
                <Route exact path="/staff/add" >
                    <Add />
                </Route>
                <Route exact path="/staff/manage" >
                    <Manage />
                </Route>

                {/* Student fee */}
                <Route exact path="/students/fee/type" >
                    <Type />
                </Route>
                <Route exact path="/students/fee/group" >
                    <GroupFee />
                </Route>
                <Route exact path="/students/fee/term" >
                    <Term />
                </Route>
                <Route exact path="/students/assign" >
                    <Assign />
                </Route>
                <Route exact path="/students/dues" >
                    <BackDues />
                </Route>
                <Route exact path="/students/payment" >
                    <FeePayment />
                </Route>
                <Route exact path="/students/notice" >
                    <Demand />
                </Route>
                <Route exact path="/students/reminder" >
                    <Reminder />
                </Route>

                {/* Reports */}
                <Route exact path="/reports/receipt" >
                    <ReceiptWise />
                </Route>
                <Route exact path="/reports/fee/collection" >
                    <Collection />
                </Route>
                <Route exact path="/reports/semester" >
                    <SemesterCollection />
                </Route>
                <Route exact path="/reports/balance" >
                    <Balance />
                </Route>
                <Route exact path="/reports/semester/balance" >
                    <SemesterBalance />
                </Route>
                <Route exact path="/reports/adjustment" >
                    <Adjustment />
                </Route>
                <Route exact path="/reports/course" >
                    <CourseWise />
                </Route>
                <Route exact path="/reports/student/balance" >
                    <StudentWithMore />
                </Route>
                <Route exact path="/reports/deleted" >
                    <DeletedFeeReceipt />
                </Route>
                <Route exact path="/reports/religion" >
                    <DiseReligion />
                </Route>
                <Route exact path="/reports/category" >
                    <DiseCategory />
                </Route>
                <Route exact path="/reports/gender" >
                    <DiseGender />
                </Route>
            </Switch>
        </HashRouter>
    )
}

export default Routes