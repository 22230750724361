// const ENVIRONMENT_STAGE = "testing";
 const ENVIRONMENT_STAGE = "production";
// const ENVIRONMENT_STAGE = "development";

var serverUrl = "http://localhost:8000";

switch (ENVIRONMENT_STAGE) {
  case "testing":
    // serverUrl = "http://92.205.25.183:8888/";
    serverUrl = "https://apidemocollege.gungunerp.in"
    break;
  case "production":
    // serverUrl = "http://3.109.186.155:8000/";
    serverUrl = "https://apidemocollege.gungunerp.in"
  // default:
  //   serverUrl = "http://localhost:8000/";
  //   break;
}

export const SERVER = serverUrl;