import React, { useState } from "react";
// import Downshift from 'downshift'
import Select from "react-select"
// import Switch from "../components/Switch";
import Switch from "react-switch";
import Table from "../components/Table"
import AsyncSelect from "react-select"
import axios from "axios";
import { SERVER } from "../utils";

const items = [
    {value: 'apple'},
    {value: 'pear'},
    {value: 'orange'},
    {value: 'grape'},
    {value: 'banana'},
  ]

const actions = [
  { label: "Add", value: 1 },
  { label: "Edit", value: 2 },
  { label: "Delete", value: 3 }
];

  
const Dummy = () => {

    const options = [
        {value: 'one', label: 'One'},
        {value: 'two', label: 'Two'}
    ]

    const [tableItems, setTable] = useState([
      {extra: false,"check":"","S.no": "1", "Name": "Demo Name", "Course": "Demo", "Session": "Demo", "Status": true, "id": "0"},
      {extra: false,"check":"","S.no": "2", "Name": "Demo Name", "Course": "Demo", "Session": "Demo", "Status": true,"id": "1"},
      {extra: false,"check":"","S.no": "3", "Name": "Demo Name", "Course": "Demo", "Session": "Demo", "Status": true,"id": "2"},
      {extra: false,"check":"","S.no": "4", "Name": "Demo Name", "Course": "Demo", "Session": "Demo", "Status": true,"id": "3"},
    ])

    const url = SERVER + "/apiV1/session/"
    const loadOptions = async () => {
      const res = await axios.get(url, { headers: { Authorization : `Basic cmFodWw6MTIzNDU2` } });
      return res.data
    }

    const defaultOptions = async () => {
      const res = await axios.post(url,{ headers: { Authorization : `Basic cmFodWw6MTIzNDU2` } });
      console.log(res.data)
    }
    defaultOptions()
    // return (
    //     <div className="w-full h-screen bg-gray-900">
    //     <Downshift
    //         onChange={selection =>
    //         alert(selection ? `You selected ${selection.value}` : 'Selection Cleared')
    //         }
    //         itemToString={item => (item ? item.value : '')}
    //     >
    //     {({
    //     getInputProps,
    //     getItemProps,
    //     getLabelProps,
    //     getMenuProps,
    //     isOpen,
    //     inputValue,
    //     highlightedIndex,
    //     selectedItem,
    //     getRootProps,
    //     }) => (
    //     <div>
    //         <label {...getLabelProps()}>Enter a fruit</label>
    //         <div
    //         className="w-11/12 form-input"
    //         {...getRootProps({}, {suppressRefError: true})}
    //         >
    //         <input {...getInputProps()} className=""/>
    //         </div>
    //         <ul {...getMenuProps()}>
    //         {isOpen
    //             ? items
    //                 .filter(item => !inputValue || item.value.includes(inputValue))
    //                 .map((item, index) => (
    //                 <li
    //                     {...getItemProps({
    //                     key: item.value,
    //                     index,
    //                     item,
    //                     style: {
    //                         backgroundColor:
    //                         highlightedIndex === index ? 'lightgray' : 'white',
    //                         fontWeight: selectedItem === item ? 'bold' : 'normal',
    //                     },
    //                     })}
    //                 >
    //                     {item.value}
    //                 </li>
    //                 ))
    //             : null}
    //         </ul>
    //     </div>
    //     )}
    //     </Downshift>,
    //         </div>
    // )
    const [check, setCheck] = useState(false)
    return (
        <div className="w-11/12 mx-auto">
                <Table setTable={setTable} tableItems={tableItems}/>
        </div>
    )
}

export default Dummy