import React, {useState, useEffect} from "react";
import CertificateForm from "../../components/forms/Student/Certificate"
import Footer from "../../components/Footer";
import NavBar from "../../components/navbar-components/NavBar";
// import Search from "../../components/Search";
import axios from "axios";
import Table from "../../components/Table";
import { SERVER } from "../../utils";
import { authHeader } from "../../helper/authHeader";
import mdtoast from "@dmuy/toast";

const Certificate = () => {


    const [tableItems , setTableItems] = useState([
        {"Semester Name": null,"status": null, empty: true} 
    ])

    const [requesttype , setRequesttype] = useState("post")
    const[currentCertificate , setCurrentCertificate] = useState({
        "name": null,
        "date": null,
        "authority": null,
        "attachment": null,
        "user": null
    })
    const [submit, setSubmit] = useState({
        "name": null,
        "date": null,
        "authority": null,
        "attachment": null,
        "user": null
    })
    // "id": 1,


    const updatetable = async ()=>{
        try{
            var res = await axios.get( SERVER + "/apiV1/certificate/" , { headers: authHeader() });
            console.log(res.data)
            if (res.data.length>0){
                var newtable = res.data.map(item=>{
                    return {"id": item.id, "Certificate Name": item.issue_certificate_name, "Username": item.user, "Date": item.issue_date,"Issuing Authority Name": item.authority_name ,Attachment: "N/A","Action" : "Action" }
                })
                setTableItems(newtable)
                console.log(newtable)
            }
        }catch(err){
            console.log(err);
        }
    }

    const EditFunction =  async (item) => {
    setRequesttype("put");
    setSubmit({
        'id' : item.id,
        'user': item['Username'],
        'name' : item['Certificate Name'],
        'date': item['Issue Date'],
        'authority': item['Issuing Authority Name']
    })
    }

    const DeleteFunction =  async (id)=>{
        // console.log(id + "has been deleted")
        try{
            const url = SERVER + "/apiV1/certificate/" + id + "/"
            const res = await axios.delete(url, { headers: authHeader() });
            mdtoast.success("Certificate Deleted Successfully ", { duration: 1000 })
            setTableItems(tableItems.filter((item)=>item.id !== id))
        }catch (err) {
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }
        
    }

    useEffect(async ()=>{
        updatetable()
    },[window.url])

    const handlePost = async () => {
        const body = {
            issue_certificate_name: submit.name,
            issue_date: submit.date,
            authority_name: submit.authority,
            attachment: null,
            user: submit.user
        }
        try {
            if (requesttype == 'post') {
                console.log(body)
                const url = SERVER + "/apiV1/certificate/"
                const res = await axios.post(url, body, { headers:  authHeader() });
                mdtoast.success("Certificate Successfully Added", { duration: 1000 })

            } else { 
                console.log(body)
                const url = SERVER + "/apiV1/certificate/" + submit.id + "/"
                const res = await axios.put(url, body, { headers: { 
                    ...authHeader(),      
                    'content-type': 'application/json',
                    'Accept': 'application/json' } });
                mdtoast.success("Certificate Successfully Edited", { duration: 1000 })
                setRequesttype("post")
            }
            setSubmit({
                "name": null,
                "date": null,
                "authority": null,
                "attachment": null,
                "user": null
            })
            updatetable()
        } catch (err) {
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }
    }

    return (
        <>
        <NavBar/>
        <div className="bg-gray-200">
            <div className="pt-5">
                <CertificateForm handlePost={handlePost} currentCertificate={submit} setCurrentCertificate={setSubmit} heading={"ADD CERTIFICATE"} type={"submit"}/>
            </div>
            <div className="mx-auto w-full bg-gray-200 pt-2 pb-5">
                    <CertificateForm heading={"SEARCH CERTIFICATE"} setCurrentCertificate={setCurrentCertificate} currentCertificate={currentCertificate} type={"search"}/>
                <div className=" bg-white py-3 w-11/12 mx-auto rounded-b-md md:rounded-md">
                    <Table DeleteFunction={DeleteFunction} tableItems={tableItems}/>
                </div>
            </div>
        </div>
        <Footer/>
        </> 
    )
}

export default Certificate