import React from "react";
import Dropdown from "@material-tailwind/react/Dropdown"
import Button from "@material-tailwind/react/Button";
import DropdownLink from "@material-tailwind/react/DropdownLink"
import { Link } from "react-router-dom";

export default function DropDownMenu(props) {
    const {item} = props
    const {name, list} = props.item

    if(item.list.length === 0){
        return (
        <Button
            color="rgba(31, 41, 55,)"
            buttonType="filled"
            size="sm"
            rounded={false}
            block={props.block}
            iconOnly={false}
            ripple="light"
        >
            <Link to={item.link} className="text-white hover:text-white w-full">
                <p className="text-sm">{name[0]}<span style={{"text-transform":"lowercase"}}>{name.substring(1)}</span></p>
            </Link>
        </Button>
        )
    }
    return (
        <Dropdown
            color={"rgba(31, 41, 55,)"}
            placement="bottom-start"
            buttonText={name}
            buttonType="filled"
            size="sm"
            rounded={false}
            block={props.block}
            ripple="light"
        >
            {list.map(item => {
                return (
                    <DropdownLink
                        href={item.link ? "/#/React/" + item.link : "#"}
                        color={"orange"}
                        ripple="light"
                    >
                        {item.name}
                    </DropdownLink>
                )
            })}
        </Dropdown>
    )
}