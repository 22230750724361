import { createSlice } from "@reduxjs/toolkit";

// "id": 2,
// "college_name": "Demo College",
// "college_image": "https://d1ee6aozb8ipmj.cloudfront.net/democollege/media/college_image/Logo_G_JPG_9w2g4lL.jpg",
// "short_name": "DC",
// "address": "Bangalore",
// "background_image": "https://d1ee6aozb8ipmj.cloudfront.net/democollege/media/",
// "signature": "https://d1ee6aozb8ipmj.cloudfront.net/democollege/media/",
// "image": "https://d1ee6aozb8ipmj.cloudfront.net/democollege/media/",
// "username_prefix": "asdfg",
// "phone_number": "9876543210",
// "email": "contact@gungunerp.com",
// "affilation_no": "11",
// "registration_no": "null",
// "office_no": "01",
export const infoSlice = createSlice({
    name: "dashInfo",
    initialState: {
        institute: "",
        logo: "",
        bgImg: "",
        regNum: null,
        contactOne: null,
        contactTwo: null,
        address: null,
        email: null,
        signature: "",
        boardLogo: "",
        isLoaded : false,
        sessionLoaded: false,
    },
    reducers: {
        setInfo: (state, action) => {
            state.institute = action.payload.college_name;
            state.logo = action.payload.college_image;
            state.bgImg = action.payload.background_image;
            state.regNum = action.payload.registration_no;
            state.contactOne = action.payload.phone_number;
            state.contactTwo = action.payload.office_no;
            state.signature = action.payload.signature;
            state.email = action.payload.email;
            state.address = action.payload.address;
            state.boardLogo = action.payload.image
        },
        setLoaded: (state) => {
            state.isLoaded = true
        },
        setSessionLoaded: (state) => {
            state.sessionLoaded = !state.sessionLoaded
        }
    }
})

export const { setInfo, setLoaded, setSessionLoaded } = infoSlice.actions

export default infoSlice.reducer