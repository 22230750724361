import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { SERVER } from "../utils";
import mdtoast from '@dmuy/toast'
import axios from 'axios'
import { userService } from "../services/userServices";
import { useDispatch, useSelector } from "react-redux";
import {setToken, setUsername, setPassword, loggedIn } from "../redux/user";
import { setLoaded, setInfo } from "../redux/info";
import '../styles/login.css'
import { authHeader } from "../helper/authHeader";
// import logo from "../images/logo.png"
import ggErp from "../images/gg-erp.png"

const Login = () => {

    const url = SERVER;
    const history = useHistory();
    const { username, password, token } = useSelector((state) => state.user)
    const { logo, bgImg, isLoaded, institute, address, contactOne, contactTwo, email } = useSelector(state => state.info) 
    const dispatch = useDispatch();

    const handleLogin = () => {
        // const loginUrl = url + "api/v1/users/login"
        // const loginUrl = "http://3.109.186.155:8000/api/token"

        // const bodyParameters = {
        //     username: username,
        //     password: password,
        //     method: "json"
        // }
        // axios.post(
        //     loginUrl,
        //     bodyParameters
        // )

        userService.login(username,password)
        .then(res => {
            dispatch(setToken(res.data.token))
            console.log(token)
            dispatch(loggedIn())
            history.push("/dashboard")
        })
        .catch(err => {
            mdtoast.error('Username or Password Incorrect', { duration: 1000 })
            dispatch(setPassword(""))
            console.log(err)
        })
    }
    // https://apidemocollege.gungunerp.in/apiV1/institute-web/?id=2
    useEffect(() => {
        axios.get( "/apiV1/institute-web/?id=2",{ headers: authHeader() })
        .then(res => {
          dispatch(setInfo(res.data))
          console.log(res)
          // mdtoast.success('Success Loading', { duration: 1000})
        }).then(() => {
              dispatch(setLoaded())
        })
        .catch(err => {
          console.log(err)
          mdtoast.error('An Error Occured While Loading', { duration: 1000})
        })
    },[])

    return (
        <div>
            <div className="card0">
                <div className=" d-flex flex-lg-row-reverse flex-column-reverse">
                    <div className="card card1">
                        <div className="row justify-content-center my-auto py-5">
                            <div className="col-md-8 col-10 my-4">
                                <div className="row justify-content-center mx-auto px-3 mb-1 rounded"> <img id="logo" alt="gungunerp logo" src={ggErp}/> </div>
                                <h5 className="mb-5 text-lg text-center heading gg-erp-color font-bold">Powered By</h5>
                                <h6 className="msg-info">Please log in to your account</h6>
                              
                                <div className="form-group"> <input type="text" id="usr" onChange={e => dispatch(setUsername(e.target.value))} name="usr" placeholder="Username" className="form-control login-input mb-3 focus:ring-1 focus:ring-yellow-500" /> </div>
                                <div className="form-group">  <input type="password" id="psw" onChange={e => dispatch(setPassword(e.target.value))} name="psw" placeholder="Password" value={password} className="login-input form-control" /> </div>
                                
                                <div className="row justify-content-center my-3 px-3"> 
                                  <button type="submit" onClick={handleLogin}
                                    className="group relative w-full flex justify-center py-2.5 px-4 border border-transparent text-xl font-medium rounded-xl text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
                                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                    <svg className="h-5 w-5 text-yellow-400 group-hover:text-yellow-400" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                        d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                        clip-rule="evenodd" />
                                    </svg>
                                    </span>
                                      Login
                                  </button>
                                </div>

                                <div className="row justify-content-center"> <a href="#"><small className="text-muted">Forgot Password?</small></a> </div>
                            </div>
                        </div>

                        <div className="bottom text-center ">
                            <p className="sm-text mx-auto"> <span>© 2021 </span><a target="_blank" rel="noreferrer" href="https://gungunerp.com/">Gungun ERP Solution Pvt Ltd.</a> <span> All Rights Reserved </span></p>
                        </div>
                    </div>
                    <div className="card2 flex items-center justify-center">
                        <div className="my-auto mx-md-5 px-md-5 right text-center p-3">
                          <img src={logo} alt="logo" className="rounded-full w-2/4 h-auto inline-block mb-1"/>
                          <h3 className="text-white text-2xl font-bold">{institute}</h3> <small className="text-lg text-white"><span className="font-bold">Address: </span>{address} || <span className="font-bold">Office no: </span>{contactTwo} || <span className="font-bold">Phone no: </span>{contactOne} || <span className="font-bold">Email: </span>{email}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login