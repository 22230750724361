import React , {useState} from "react";
import { formServices } from "../../../services/formServices";
import Select from "react-select";

const DeletedFeeReceiptForm = () => {
    const [startdate , setStartDate] = useState("");
    const [enddate , setEndDate] = useState("");
    const [course , setCourse] = useState("");

    const handlePost = () => {
        return formServices.deletedfeereceiptPost( startdate , enddate , course)
    }

    const courses = [
        {label: "Information Technology", value: "it"},
        {label: "CSE", value: "cse"},
        {label: "Demo", value: "demo"}
    ]
    
    return (
        <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-md">
        <p className="text-xl font-bold gg-erp-color ">DELETED FEE RECEIPT</p>
        
        <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-2">
            <label for="strt" className="mb-2 w-full py-2">Start Date
                <input className="form-input"  type="date" name="start" onChange={e => setStartDate(e.target.value)}/>
            </label>
            
            <label for="end" className="mb-2 w-full py-2">End Date
                <input className="form-input"  type="DATE" name="end" onChange={e => setEndDate(e.target.value)}/>
            </label>

            <label for="crse" className="mb-2 w-full py-2">Select Course
            <Select 
                options={courses}
                value={course}
                onChange = {(option)=>setCourse(option)}
                placeholder="Select Course" 
                isClearable={true} 
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                    ...theme.colors,
                    primary25: 'lightCyan',
                    primary: '#ff5722',
                    },
                })}
            />
            </label>

            <div className="text-center mt-2.5">
                <button type="submit" className="submit w-full p-2 mt-3" onClick = {handlePost}>Search</button>
            </div>
        </div>
    </div>
    )
}

export default DeletedFeeReceiptForm