import React from "react";

const Empty = () => {
    return (
        <div className=" py-4 text-gray-300 text-center items-center mx-auto border-1 w-11/12">
            <i className="bi bi-inbox text-7xl"></i>
            <p>No Data</p>
        </div>
    )
}

export default Empty