import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios'
import mdtoast from '@dmuy/toast'
import logo from "../images/logo.png"
import ggErp from "../images/gg-erp.png"
import '../styles/login.css'

const Register = () => {
    const history = useHistory()
    const [username, setUsername] = useState("")
    const [mail, setMail] = useState("")
    const [password, setPassword] = useState("")
    const url = process.env.REACT_APP_URL

    const handleRegister = () => {

        const registerUrl = url + "/api/register"

        const bodyParameters = {
            name: username,
            email: mail,
            password: password
        }
        axios.post(
            registerUrl,
            bodyParameters
        )
        .then(response => {
            mdtoast.success('Registration Successful!', { duration: 1000 })
            console.log(response)
            history.push('/login')
        })
        .catch(err => {
            mdtoast.error('Registration Failed! An Error Occured', { duration: 1000 })
            console.log(err)
        })
    }

    return (
        <div>
            <div className="card0">
                <div className="d-flex flex-lg-row-reverse flex-column-reverse">
                    <div className="card card1">
                        <div className="row justify-content-center my-auto py-5">
                            <div className="col-md-8 col-10 my-4">
                                <div className="row justify-content-center px-3 mb-3"> <img id="logo" alt="gungunerp logo" src={ggErp}/> </div>
                                <h5 className="mb-5 text-center heading">Lorem ipsum dolor sit amet</h5>
                                <h6 className="msg-info">Please log in to your account</h6>
                              
                                <div className="form-group"> <input type="text" id="usr" onChange={e => setUsername(e.target.value)} name="usr" placeholder="Username" className="form-control mb-3 focus:ring-1 focus:ring-yellow-500" /> </div>
                                <div className="form-group">  <input type="email" id="mail" onChange={e => setMail(e.target.value)} name="psw" placeholder="E-mail" className="form-control mb-1" /> </div>
                                <div className="form-group">  <input type="password" id="psw" onChange={e => setPassword(e.target.value)} name="psw" placeholder="Password" value={password} className="form-control" /> </div>
                                
                                <div className="row justify-content-center my-3 px-3"> 
                                  <button type="submit" onClick={handleRegister}
                                    className="group relative w-full flex justify-center py-2.5 px-4 border border-transparent text-xl font-medium rounded-xl text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
                                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-yellow-400 group-hover:text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                                        <path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clipRule="evenodd" />
                                    </svg>
                                    </span>
                                      Register
                                  </button>
                                </div>
                            </div>
                        </div>
                        <div className="bottom text-center ">
                            <p className="sm-text mx-auto"> <span>© 2021 </span><a target="_blank" rel="noreferrer" href="https://gungunerp.com/">Gungun ERP Solution Pvt Ltd.</a> <span> All Rights Reserved </span></p>
                        </div>
                    </div>
                    <div className="card2 flex items-center justify-center">
                        <div className="my-auto mx-md-5 px-md-5 right text-center p-3">
                          <img src={logo} alt="logo" className="logo-image inline-block mb-1"/>
                          <h3 className="text-white">We are more than just a College</h3> <small className="text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register