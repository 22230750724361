import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "userInfo",
    initialState: {
        username: "",
        password: "",
        isLoggedIn: false,
        token: "",
        id: ""
    }, 
    reducers: {
        setUsername: (state, action) => {
            state.username = action.payload;
        },
        setPassword: (state, action) => {
            state.password = action.payload;
        },
        loggedIn: (state) => {
            state.isLoggedIn= true;
        },
        loggedOut: (state) => {
            state.isLoggedIn = false;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setID: (state, action) => {
            state.id = action.payload;
        }
    }
})
 
export const { setID ,setToken,setUsername, setPassword, loggedIn, loggedOut } = userSlice.actions

export default userSlice.reducer; 