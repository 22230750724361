import React , {useState} from "react";
import { formServices } from "../../../services/formServices";
import Select from "react-select";

const ReceiptWiseForm = () => {

    const [startdate , setStartDate] = useState("");
    const [enddate , setEndDate] = useState("");
    const [course , setCourse] = useState("");
    const [payment , setPayment] = useState("");
    const [search , setSearch] = useState("");

    const handlePost = () => {
        return formServices.receiptwisePost( startdate , enddate , course , payment , search)
    }

    const courses = [
        {label: "Information Technology", value: "it"},
        {label: "CSE", value: "cse"},
        {label: "Demo", value: "demo"}
    ]

    const methods = [
        {label: "Method 1", value: "1"},
        {label: "Method 2", value: "2"},
    ]
    
    return (
    <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-md">
        <p className="text-xl font-bold gg-erp-color ">RECEIPT WISE FEE COLLECTION REPORT</p>
        
        <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-2">
            <label for="strt" className="mb-2 w-full py-2">Start Date
                <input className="form-input" type="date" name="start" onChange={e => setStartDate(e.target.value)}/>
            </label>
            
            <label for="end" className="mb-2 w-full py-2">End Date
                <input className="form-input" type="date" name="end" onChange={e => setEndDate(e.target.value)}/>
            </label>

            <label for="crse" className="mb-2 w-full py-2">Select Course
            <Select 
                options={courses}
                value={course}
                onChange = {(option)=>setCourse(option)}
                placeholder="Select Course" 
                isClearable={true} 
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                    ...theme.colors,
                    primary25: 'lightCyan',
                    primary: '#ff5722',
                    },
                })}
            />
            </label>

            <label for="payment" className="mb-2 w-full py-2">Payment Method
            <Select 
                options={methods}
                value={course}
                onChange = {(option)=>setPayment(option)}
                placeholder="Select Payment Method" 
                isClearable={true} 
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                    ...theme.colors,
                    primary25: 'lightCyan',
                    primary: '#ff5722',
                    },
                })}
            />
            </label>

            <label for="srch" className="mb-2 w-full py-2">Search
                <input className="form-input" placeholder="Search Name/Number/Designation" type="text" name="usr" onChange={e => setSearch(e.target.value)}/>
            </label>
            <div className="text-center ">
                <button type="submit" className="submit w-full py-1.5 mt-4" onClick = {handlePost}>Search</button>
            </div>
        </div>
        <hr className="my-2"/>
        <input type="checkbox" name="fee_details"/><label for="fee_detail">Show fee details</label>
        <input type="checkbox" name="father_name"/><label for="father_name">Show father name</label>
        <input type="checkbox" name="created_by"/><label for="show_creator">Show Created By</label>
    </div>
    )
}

export default ReceiptWiseForm