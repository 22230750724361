import React, { useEffect, useState } from "react";
import Empty from "./Empty";
import "../styles/table.css";
import Switch from "react-switch";

const Table = (props) => {
  const { tableItems, setTable , DeleteFunction, EditFunction, handleCheckbox, disableFunction} = props

  //dummy state
  const [check, setCheck] = useState(true)

  const handleChange = (id) => {

    const newTable = tableItems.map(item => item.id===id ? item.extra = !item.extra : item)
    setTable([...tableItems]);
  }


  if (tableItems.length === 1 && tableItems[0].empty === true) {
    return (
      <>
      <div className="w-11/12 mx-auto overflow-x-scroll">
        <table className="mx-auto w-full border-1">
          <tr key={"header"} className=" text-left">
            {Object.keys(tableItems[0]).map((key) => {
              if (key === "extraInfo") return null
              if (key === "id") return null
              if (key === "check") return (<th className="text-sm font-light p-2 border-1"></th>)
              return (key === "empty" ? null : <th className="text-sm hover:bg-gray-200 font-light bg-top p-2 border-1 border-gray-200"><nobr>{key}</nobr></th>)
            })}
          </tr>
        </table>
      </div>
      <div>
        <Empty />
      </div>
      </>
    )
  }
  
  return (
    <div className="w-11/12 h-auto overflow-x-scroll mx-auto">
      <table className="text-sm mx-auto w-full border-1 cursor-pointer">
        <tr key={"header"} className="text-left bg-top">
          {Object.keys(tableItems[0]).map((key) => {
            if (key === "id") return null;
            if (key === "extraInfo") return null;
            if (key === "extra") return (<th className="text-sm font-light p-2 border-1"></th>)
            if (key === "check") return (<th className="text-sm font-light p-2 border-1"></th>)
            return (<th className="text-sm font-light p-2 border-1"><nobr>{key}</nobr></th>)
          })}
        </tr>
        {tableItems.map((item) => (
          <>
          <tr key={item.id} className="bg-white hover:bg-gray-200 font-light">
            {
              Object.entries(item).map(pair => {
                if (pair[0] === "id") return null;
                if (pair[0] === "extraInfo") return null;
                if (pair[0] === "extra") return (
                  <>
                    <td className="border-1 p-2 border-gray-200 cursor-pointer">
                      <span onClick={() => handleChange(item.id)} className="flex justify-center gg-erp-color">
                        {/* <input value={item.id} id={item.id} type="checkbox" onChange={handleCheckbox} /> */}
                        {item.extra ? <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-dash-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/><path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/></svg>: <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-plus-square-fill" viewBox="0 0 16 16"><path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z"/></svg>}
                      </span>
                    </td>
                  </>
                  )
                if (pair[0] === "check") return (<td className="border-1 p-2 border-gray-200 cursor-pointer"><span className="flex justify-center"><input value={item.id} id={item.id} type="checkbox" onChange={() => handleCheckbox(item.id)} /></span></td>)
                if (pair[0] === "Action") {
                  return (
                    <td className=" border-1 p-2 border-gray-200 cursor-pointer">
                      <nobr>
                        {
                          EditFunction ? <button type="submit" className="bg-blue-200 px-2 py-1 rounded m-0.5 text-xs border-1 hover:bg-blue-100 text-blue-600 border-blue-600" onClick={() => EditFunction(item)}>Edit</button> : null
                        }
                        {
                          DeleteFunction ? <button type="submit" className="bg-red-200 px-2 py-1 rounded m-0.5 text-xs text-red-600 hover:bg-red-100 border-1 border-red-600" onClick={() => DeleteFunction(item.id)}>Remove</button> : null
                        }
                        {
                          disableFunction ? <button type="submit" className="bg-red-200 px-2 py-1 rounded m-0.5 text-xs text-red-600 hover:bg-red-100 border-1 border-red-600" onClick={() => disableFunction(item.id)}>Disable</button> : null
                        } 
                      </nobr>
                    </td>
                  )
                } else if (pair[0] === "Status" || pair[0] === "Current Session") {
                  return (
                    <td className="border-1 border-gray-200 cursor-pointer">
                      <span onClick={()=> { return pair[0] === "Current Session" ? props.current(item.id) : null }} className="p-2">
                        <Switch checkedIcon={null} activeBoxShadow={null} width={52} height={26} handleDiameter={21} uncheckedIcon={null} onChange={() => { }} checked={pair[1]} onColor="#ff5722" />
                      </span>
                    </td>
                  )
                } else {
                  return <td className=" border-1 p-2 border-gray-200 cursor-pointer"><nobr>{pair[1]}</nobr></td>
                }
              })
            }
          </tr>
          { item.extraInfo ? 
            <tr className={"text-left" + (item.extra ? " visible" : " hidden")}>
              <td className="border-r"></td>
              <td colSpan={Object.keys(tableItems[0]).length - 1} className="p-4">
                <ul className="grid grid-cols-2 lg:grid-cols-3 list-disc">
                  {
                  Object.keys(item.extraInfo).map(info =>  {
                      return <li className="text-xs"><span className="font-bold">{info} : </span> {item.extraInfo[String(info)] ? item.extraInfo[String(info)] : "N/A"} </li>
                    }
                  )}
                </ul>
              </td>
            </tr> : null
          }
          </>
        ))}
      </table>
    </div>
  );
}

export default Table