import React , {useState , useEffect} from "react";
import NavBar from "../../components/navbar-components/NavBar";
import Footer from "../../components/Footer";
import Table from "../../components/Table";
import { Link } from "react-router-dom";
import { authHeader } from "../../helper/authHeader";
import axios from "axios";

const tableItems = [
    { "S.no": null, "Supplier Name": null, "Invoice": null, "Date": null, "Total Amount": null, "Status": null, "Actions": null, empty: true }
]

const Purchase = () => {
    const [tableItems, setTableItems] = useState([
        { "S.no": null, "Supplier Name": null, "Invoice": null, "Date": null, "Total Amount": null, "Status": null, "Action": null, empty: true }
    ])

    const [currentPurchase, setCurrentPurchase] = useState({
        "id": "",
        "date": "",
        "invoice_num":0,
        "total_amount": 0,
        "supplier_name": "",
        "items": []
    })

    const [list, setList] = useState([])

    const handleCheckbox = id => {
        let newArr = [...list];
        if (list.includes(id)) {
            newArr = newArr.filter(item => item !== id)
            return setList([...newArr])
        }
        newArr.push(String(id))
        return setList(newArr)
    }

    const updatetable = async () => {
        try {
            var res = await axios.get("/api/v1/purchase", { headers: authHeader() });
            console.log(res.data)
            if (res.data.length > 0) {
                var newtable = res.data.map((item, idx) => {
                    return { "id": item.id,"check": "", "S.no": idx + 1, "Supplier Name": item.supplier_name.name, "Invoice": item.invoice_num, "Date": item.date, "Total Amount": item.total_amount, "Status": true, "Action": null }
                })
                setTableItems(newtable)
                console.log(newtable)
            }
        } catch (err) {
            console.log(err);
        }
    }

    const EditFunction = async (item) => {
        
    }

    const DeleteFunction = async (id) => {
        // console.log(id + "has been deleted")
        // try {
        //     // const url = SERVER + "/apiV1/designation/" + id + "/"
        //     const res = await axios.delete(`/api/v1/category/${id}`, { headers: authHeader() });
        //     mdtoast.success("Designation Deleted Successfully", { duration: 1000 })
        //     setTableItems(tableItems.filter((item) => item.id !== id))
        // } catch (err) {
        //     console.log(err)
        //     mdtoast.error("Error Occured", { duration: 1000 })
        // }

    }

    useEffect(async () => {
        updatetable()
    }, [window.url])

    return (
        <>
            <NavBar />
            <div className="bg-gray-200">
                <div className="mx-auto w-full bg-gray-200 py-5">
                    <div className="w-11/12 mx-auto bg-white py-3 rounded-t">
                        <p className="pt-2 w-11/12 mx-auto text-xl font-bold gg-erp-color ">Purchase</p>
                        <Link to="/inventory/addpurchase">
                            <div className="w-11/12 mx-auto text-left md:text-right mt-2">
                                <button onClick={() => null} type="submit" className="submit text-xs">Purchase Item</button>
                            </div>
                        </Link>

                        <div className="w-11/12 mx-auto gap-2 grid grid-cols-2">
                            <label for="start" className="w-full py-2">Start Date
                                <input type="date" placeholder="dd-mm-yyyy" name="start" className="form-input" />
                            </label>

                            <label for="end" className="w-full py-2">End Date
                                <input type="date" name="end" className="form-input" />
                            </label>
                        </div>
                        <div className="w-11/12 mx-auto text-right mt-2">
                            <button onClick={() => null} type="submit" className="submit w-full text-sm px-3">Search</button>
                        </div>
                    </div>
                    <div className=" bg-white py-3 w-11/12 mx-auto rounded-b-md md:rounded-md">                         
                        <div className="w-11/12 mx-auto">
                            <p className="pt-2 text-lg font-bold gg-erp-color ">SEND SMS</p>
                            <label for="name" className=" w-full mx-auto pb-2">
                                <textarea className="form-input" type="text" name="usr" onChange={e => (e.target.value)}/>
                            </label>
                            <hr className="mx-auto"/>
                            <div className=" mx-auto text-right py-2">
                                <button onClick={()=> null} type="submit" className="submit text-sm py-1 px-4">Send</button>
                            </div>
                        </div>
                        <Table updatetable={updatetable} handleCheckbox={handleCheckbox} DeleteFunction={DeleteFunction} EditFunction={EditFunction} tableItems={tableItems} />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Purchase