import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import { isExpired, decodeToken } from "react-jwt";
import { useDispatch } from "react-redux";
import { loggedIn, setID  } from "../redux/user";
import "../styles/app.css"

const App = () => {
    
    const { isLoggedIn } = useSelector(state => state.user)
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        
        const load = async () => {
            if(isLoggedIn){
                history.push("/dashboard")
            }else{
                if(localStorage.getItem('user')){
                    history.push("/dashboard")
                    dispatch(loggedIn())
                }else{
                    history.push("/login")
                }
            }
        };
        load()
    },[])

    return(
        <div className="w-screen flex items-center justify-center h-screen">
            <div className="sk-chase">
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
            </div>
        </div>
    )
}

export default App