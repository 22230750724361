import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router';
import NavBar from '../../components/navbar-components/NavBar';
import Footer from '../../components/Footer'
import InfoCard from '../../components/InfoCard';
import Tile from '../../components/Tile';
import { SERVER } from '../../utils'
import axios from 'axios';
import mdtoast from '@dmuy/toast'
import { setInfo, setLoaded } from '../../redux/info';
import { authHeader } from '../../helper/authHeader';

const Dashboard = () => {

    // const history = useHistory()
    const { logo, bgImg, isLoaded, institute,address, contactTwo, contactOne, email } = useSelector(state => state.info) 
    // const { isLoggedIn } = useSelector(state => state.user)
    const dispatch = useDispatch()

    console.log(JSON.parse(localStorage.getItem("user")))
    
    if(!isLoaded){
        // mdtoast.info('Loading Assets', { duration: 1000})
        axios.get( SERVER + "/apiV1/institute/2/",{ headers: authHeader() })
          .then(res => {
            dispatch(setInfo(res.data))
            console.log(res)
            // mdtoast.success('Success Loading', { duration: 1000})
          }).then(() => {
                dispatch(setLoaded())
          })
          .catch(err => {
            console.log(err)
            mdtoast.error('An Error Occured While Loading', { duration: 1000})
          })
    }

    const tileArr = [
        {name: 'Customer Balance Summary', logo: 'cash', color: 'red', link: "#"},
        {name: 'Stock Balance Summary', logo: 'cash', color: 'red', link: "#"},
        {name: 'Supplier Balance Summary', logo: 'cash', color: 'red', link: "#"},
        
        {name: "Today's Sell Amount", logo: 'cash', color: 'blue', link: "#"},
        {name: "Last Week's Sell Amount", logo: 'cash', color: 'blue', link: "#"},
        {name: "Current Month's Sell Amount", logo: 'cash', color: 'blue', link: "#"},
        {name: "Last Six Month's Sell Amount", logo: 'cash', color: 'blue', link: "#"},
        {name: "Current Year's Sell Amount", logo: 'cash', color: 'blue', link: "#"},
        
        {name: "Today's Received Amount", logo: 'cash', color: 'green', link: "#"},
        {name: "Last Week's Received Amount", logo: 'cash', color: 'green', link: "#"},
        {name: "Current Month's Received Amount", logo: 'cash', color: 'green', link: "#"},
        {name: "Last Six Month's Received Amount", logo: 'cash', color: 'green', link: "#"},
        {name: "Current Year's Month's Received Amount", logo: 'cash', color: 'green', link: "#"},
        
        {name: "Today's Purchase Amount", logo: 'cash', color: 'gray', link: "#"},
        {name: "Last Week's Purchase Amount", logo: 'cash', color: 'gray', link: "#"},
        {name: "Current Month's Purchase Amount", logo: 'cash', color: 'gray', link: "#"},
        {name: "Last Six Month's Purchase Amount", logo: 'cash', color: 'gray', link: "#"},
        {name: "Current Year's Purchase Amount", logo: 'cash', color: 'gray', link: "#"},
        
        {name: "Current Month's Supplier Payment", logo: 'cash', color: 'orange', link: "#"},
        {name: "Last Six Month's Supplier Payment", logo: 'cash', color: 'orange', link: "#"},
        {name: "Current Year's Supplier Payment", logo: 'cash', color: 'orange', link: "#"},
    ]
    const tileArrLg = [
        {name: 'Customer Balance Summary', logo: 'cash', color: 'red', link: "#"},
        {name: 'Stock Balance Summary', logo: 'cash', color: 'red', link: "#"},
        {name: 'Supplier Balance Summary', logo: 'cash', color: 'red', link: "#"},
        
        {name: "Today's Sell Amount", logo: 'cash', color: 'blue', link: "#"},
        {name: "Today's Received Amount", logo: 'cash', color: 'green', link: "#"},
        {name: "Today's Purchase Amount", logo: 'cash', color: 'gray', link: "#"},
        
        {name: "Last Week's Sell Amount", logo: 'cash', color: 'blue', link: "#"},
        {name: "Last Week's Received Amount", logo: 'cash', color: 'green', link: "#"},
        {name: "Last Week's Purchase Amount", logo: 'cash', color: 'gray', link: "#"},
        
        {name: "Current Month's Sell Amount", logo: 'cash', color: 'blue', link: "#"},
        {name: "Current Month's Received Amount", logo: 'cash', color: 'green', link: "#"},
        {name: "Current Month's Purchase Amount", logo: 'cash', color: 'gray', link: "#"},
        
        {name: "Last Six Month's Sell Amount", logo: 'cash', color: 'blue', link: "#"},
        {name: "Last Six Month's Received Amount", logo: 'cash', color: 'green', link: "#"},
        {name: "Last Six Month's Purchase Amount", logo: 'cash', color: 'gray', link: "#"},
        
        {name: "Current Year's Sell Amount", logo: 'cash', color: 'blue', link: "#"},
        {name: "Current Year's Received Amount", logo: 'cash', color: 'green', link: "#"},
        {name: "Current Year's Purchase Amount", logo: 'cash', color: 'gray', link: "#"},
        
        {name: "Current Month's Supplier Payment", logo: 'cash', color: 'orange', link: "#"},
        {name: "Last Six Month's Supplier Payment", logo: 'cash', color: 'orange', link: "#"},
        {name: "Current Year's Supplier Payment", logo: 'cash', color: 'orange', link: "#"},
    ]

    return (
        <div>
            <NavBar/>
            <div className=" h-auto w-100  bg-gray-200 mx-auto py-4">
                <InfoCard bgImg={bgImg} logo={logo} url={SERVER} address={address} email={email} contactOne={contactOne} contactTwo={contactTwo} institute={institute} />
                <div className="w-11/12 mx-auto gap-2 grid lg:hidden grid-cols-1 lg:grid-cols-3 mt-4">
                    {tileArr.map(tile => {
                        return <Tile name={tile.name} icon={tile.logo} link={tile.link} color={tile.color}/>  
                    })}
                </div>
                <div className="hidden w-11/12 mx-auto gap-2 lg:grid grid-cols-1 lg:grid-cols-3 mt-4">
                    {tileArrLg.map(tile => {
                        return <Tile name={tile.name} icon={tile.logo} link={tile.link} color={tile.color}/>  
                    })}
                </div>
           </div>
            <Footer/>
        </div>
    )
}

export default Dashboard;