import React, { useState } from "react";
import { formServices } from "../../../services/formServices";
import Select from "react-select";

const SemesterBalanceForm = () => {
    const [course, setCourse] = useState("");
    const [balancemore, setBalancemore] = useState("");
    const [balanceless, setBalanceless] = useState("");
    const [term, setTerm] = useState("");
    const [fee, setFee] = useState("");
    const [status, setStatus] = useState("");

    const handlePost = () => {
        return formServices.semesterbalancePost( course , balancemore , balanceless , term , fee , status)
    }

    const courses = [
        {label: "Information Technology", value: "it"},
        {label: "CSE", value: "cse"},
        {label: "Demo", value: "demo"}
    ]

    const statuses = [
        {label: "Current", value: "current"},
        {label: "Passout", value: "passout"},
        {label: "Dropout", value: "dropout"}
    ]

    const options = [
        {label: "Option 1", value: "current"},
        {label: "Option 2", value: "passout"},
        {label: "Option 3", value: "dropout"}
    ]

    return (
        <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-md">
            <p className="text-xl font-bold gg-erp-color ">SEMESTERWISE BALANCE REPORT</p>
            
            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-2">       

            <label for="crse" className="mb-2 w-full py-2">Select Course
            <Select 
                options={courses}
                value={course}
                onChange = {(option)=>setCourse(option)}
                placeholder="Select Course" 
                isClearable={true} 
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                    ...theme.colors,
                    primary25: 'lightCyan',
                    primary: '#ff5722',
                    },
                })}
            />
            </label>

            <label for="bal_more" className="mb-2 w-full py-2">Balance More than
                <input className="form-input" placeholder="Balance >=" type="text" name="bal_more" onChange={e => setBalancemore(e.target.value)}/>
            </label>

            <label for="bal_less" className="mb-2 w-full py-2">Balance Less than
                <input className="form-input" placeholder="Balance <=" type="text" name="bal_less" onChange={e => setBalanceless(e.target.value)}/>
            </label>

            <label for="term" className="mb-2 w-full py-2">Select Term
                <Select 
                    options={options}
                    value={term}
                    onChange = {(option)=>setTerm(option)}
                    placeholder="Select Term" 
                    isClearable={true} 
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                        ...theme.colors,
                        primary25: 'lightCyan',
                        primary: '#ff5722',
                        },
                    })}
                />
            </label>
                        
            <label for="type" className="mb-2 w-full py-2">Fee Type
                <Select 
                    options={options}
                    value={fee}
                    onChange = {(option)=>setFee(option)}
                    placeholder="Select Type" 
                    isClearable={true} 
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                        ...theme.colors,
                        primary25: 'lightCyan',
                        primary: '#ff5722',
                        },
                    })}
                />
            </label>

            <label for="status" className="mb-2 w-full py-2">Status
            <Select 
                    options={statuses}
                    value={status}
                    onChange = {(option)=>setStatus(option)}
                    placeholder="Select Status" 
                    isClearable={true} 
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                        ...theme.colors,
                        primary25: 'lightCyan',
                        primary: '#ff5722',
                        },
                    })}
                />
            </label>
            </div>
            <div className="text-center">
                <button type="submit" className="submit w-full" onClick = {handlePost}>Search</button>
            </div>
        </div>
    )
}

export default SemesterBalanceForm