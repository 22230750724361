import axios from "axios";
import { SERVER } from "../utils";
import mdtoast from '@dmuy/toast'

const url = SERVER;

const login = async (username, password) => {

    // const loginUrl = url + "/token-auth/";

    const bodyParameters = {
        username: username,
        password: password
    }
    return axios.post(
        "/api/v1/token",
        bodyParameters
    )
    .then(res => {
        mdtoast.success('Login Successful!', { duration: 1000 })
        localStorage.setItem('user',JSON.stringify(res.data))
        return res
    })
}

const refresh = async () => {
    const url = "http://3.109.186.155:8000/api/token/refresh"
    console.log(localStorage.getItem('user'))
    const user = JSON.parse(localStorage.getItem('user'))
    const body = {
        refresh: user.refresh
    }
    return axios.post(url, body)
      .then(res => {
          user.access = res.data.access
          localStorage.setItem('user',JSON.stringify(user))
      })
}

const logout = () => {
    return localStorage.removeItem('user')
}

export const userService = {
    login,
    logout,
    refresh
}