import React, { useState } from "react";
import axios from "axios";
import { SERVER } from "../../../utils";
// import { formServices } from "../../../services/formServices";
import mdtoast from '@dmuy/toast'
import Switch from "react-switch";
import { authHeader } from "../../../helper/authHeader";
import Select from "react-select"

const AddForm = ({ updatetable, currentsession, requesttype, setRequesttype, setCurrentsession , productcat }) => {

    // const url = SERVER + "api/";

    const categories = productcat.map((item)=>{
        return { label: item.name, value: item.id }
    })
     // const departments = [
    //     { label: "Information Technology", value: 1 },
    //     { label: "CSE", value: 2 },
    //     { label: "Demo", value: 3 }
    // ]

    const handlePost = async () => {
        const body = currentsession
        body.product_category = body.product_category.value
        // console.log(currentsession)
        // console.log(body)
        try {
            if (requesttype == 'post') {
                console.log(body)
                // const url = SERVER + "/apiV1/session/"
                const res = await axios.post("api/v1/product", body, { headers: authHeader() });
                mdtoast.success("Session Successfully Created", { duration: 1000 })
            } else {
                // const url = SERVER + + currentsession.id + "/"
                const res = await axios.put(`api/v1/product/${currentsession.id}`, body, {
                    headers: authHeader()
                });
                mdtoast.success("Session Successfully Edited", { duration: 1000 })
                setRequesttype("post")
            }
            setCurrentsession({
                'id' : null,
                'name' : '',
                'quantity' : '',
                'selling_price' : '',
                'description' : false,
                'product_category' : '',
                'status' : true
            })

            // formServices.sessionPost(session, start, end, current);
            updatetable()
        } catch (err) {
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }

    }

    return (
        <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-t-md md:rounded-md rounded-b-none">
            <p className="text-xl font-bold gg-erp-color ">{requesttype=="post" ? "Add Item" : "Edit Item"}</p>
            <label for="session_name" className="w-full py-2">Item Name
                <input className="form-input" value={currentsession.name} onChange={(e) => { setCurrentsession({ ...currentsession, 'name': e.target.value }) }} type="text" placeholder="Item Name" name="session" />
            </label>

            <label for="quantity" className="w-full py-2">Quantity
                <input className="form-input" value={currentsession.quantity} onChange={(e) => { setCurrentsession({ ...currentsession, 'quantity': e.target.value }) }} type="number" placeholder="" name="session" />
            </label>

            <label for="selling" className="w-full py-2">Selling Price
                <input className="form-input" value={currentsession.selling_price} onChange={(e) => { setCurrentsession({ ...currentsession, 'selling_price': e.target.value }) }} type="number" placeholder="" name="session" />
            </label>

            <label for="category" className="w-full py-2">Item Category
                {/* <input className="form-input" value={currentsession.quantity} onChange={(e) => { setCurrentsession({ ...currentsession, 'session': e.target.value }) }} type="text" placeholder="Session Name" name="session" /> */}
                <Select
                        options={categories}
                        value={currentsession.product_category}
                        onChange={(option) => setCurrentsession({ ...currentsession, 'product_category': option })}
                        placeholder="Select Category"
                        isClearable={true}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                                ...theme.colors,
                                primary25: 'lightCyan',
                                primary: '#ff5722',
                            },
                        })}
                    />
            </label>
            <label for="selling" className="w-full py-2">Description
                <input className="form-input" value={currentsession.description} onChange={(e) => { setCurrentsession({ ...currentsession, 'description': e.target.value }) }} type="text" placeholder="" name="session" />
            </label>

            <label for="religion" className="mb-2 w-full py-2">Status 
            <span className="p-2">
                <Switch checkedIcon={null} activeBoxShadow={null} width={52} height={26} handleDiameter={21} uncheckedIcon={null} checked={currentsession.status} onColor="#ff5722" onChange={(e) => { setCurrentsession({ ...currentsession, 'status': !currentsession.status }) }} />
            </span>
            </label>
            <hr />
            <div className="text-right mt-2">
                <button type="submit" onClick={handlePost} className="submit">Submit</button>
            </div>
        </div>
    )
}

export default AddForm