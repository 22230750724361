import React from "react";
import axios from "axios";
import mdtoast from '@dmuy/toast'
import { SERVER } from "../../../utils";
import {authHeader}  from "../../../helper/authHeader"

const DepartmentForm = ({updatetable, currentDepartment, requesttype, setRequesttype, setCurrentDepartment}) => {


    const handlePost = async () => {
        const body = {
            department_name: currentDepartment.department,
            status: true
        }
        try {
            if (requesttype == 'post') {
                console.log(body)
                // const url = SERVER + 
                const res = await axios.post("/apiV1/department/", body, { headers: {...authHeader()} });
                mdtoast.success("Department Successfully Created", { duration: 1000 })

            } else {
                console.log(body)
                // const url = SERVER + "" + currentDepartment.id + "/"
                const res = await axios.put(`/apiV1/department/${currentDepartment.id}/`, body, { headers: authHeader() });
                mdtoast.success("Department Successfully Edited", { duration: 1000 })
                setRequesttype("post")
            }
            setCurrentDepartment({
                department: "",
                status: true
            })

            updatetable()
        } catch (err) {
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }
    }
    


    return (
        <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-t-md md:rounded-md rounded-b-none">
            <p className="text-xl font-bold gg-erp-color ">ADD DEPARTMENT</p>
            <label for="religion" className="mb-2 w-full py-2">Department Name
                <input className="form-input" placeholder="Department Name" type="text" name="religion" value={currentDepartment.department} onChange={e => setCurrentDepartment({...currentDepartment, department: e.target.value})}/>
            </label>
            <hr/>
            <div className="text-right mt-2">
                <button onClick={handlePost} type="submit" className="submit">Submit</button>
            </div>
        </div>
    )
}

export default DepartmentForm