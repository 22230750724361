import React, { useEffect, useState } from "react";
// import AddForm from "../../components/forms/Inventory/Add";
import BundleForm from "../../components/forms/Inventory/Bundle";
import Navbar from "../../components/navbar-components/NavBar"
import Footer from "../../components/Footer"
import Table from "../../components/Table";
import axios from "axios";
import { SERVER } from "../../utils";
import mdtoast from '@dmuy/toast'
import { useDispatch } from 'react-redux';
import { setSessionLoaded } from "../../redux/info";
import { authHeader } from "../../helper/authHeader"
// import Switch from "react-switch";
// import Empty from "../../components/Empty";

const Bundle = () => {
    const dispatch = useDispatch()

    const [tableItems , setTableItems] = useState([
        {id: null,"Bundle Name": 0, "Items": 0, "Status" : 0 , "Action" : 0 , empty:true },
    ])


    const [requesttype, setRequesttype] = useState("post")
    const [currentsession, setCurrentsession] = useState({
        "bundle_name": "",
        "items": []
    })

    const updatetable = async ()=>{
        try{
            var res = await axios.get("/api/v1/bundle" , { headers:authHeader()});
            var getProducts = await axios.get("/api/v1/product", { headers:authHeader()})
            let count = 0

            if (res.data.length>0){
                var newtable = res.data.map(item=>{
                    const defItem = getProducts.data.filter(data => item.items.indexOf(data.id) > -1)
                    count++
                    return {"id": {id: item.id, items: defItem.map(product => { return { label: product.name, value : product.id}})},"Sr. No.":count, "Bundle Name": item.bundle_name, "Items": defItem.map(def => def.name).join(", "),"Status" : true , "Action" : "Action" }
                })
                setTableItems(newtable)
            }
        } catch (err) {
            console.log(err);
        }
    }

    const EditFunction =  async (item)=>{
    setRequesttype("put");
    console.log(item.id.items)
    setCurrentsession({
        'id' : item.id.id,
        "bundle_name": item["Bundle Name"],
        "items": [...item.id.items]
    })
    }
    useEffect(() => {
        const getData = async () => {
            try {
                var res = await axios.get("/api/v1/product", { headers: authHeader() })
                var productlist = res.data.map((item) => {
                    return { "label": item.name, "value": item.id }
                })
            } catch (err) {
                console.log(err)
            }
        }
        getData()
    }, [window.url])


    const DeleteFunction = async (id) => {
        console.log(id + "has been deleted")
        try {
            const res = await axios.delete(`/apiV1/bundle/${id.id}/`, { headers: authHeader() });
            mdtoast.success("Bundle Deleted Successfully ", { duration: 1000 })
            setTableItems(tableItems.filter((item)=>item.id !== id))
        }catch (err) {
            console.log(err)
            mdtoast.error("Bundles are dependent and cannot be deleted", { duration: 1000 })
        }
    }
    useEffect(async () => {
        updatetable()
    }, [window.url])

    return (
        <>
        <Navbar/>
        <div className="bg-gray-200">
            <div className="mx-auto md:flex md:w-11/12 justify-center bg-gray-200 py-5">
                <div className="md:w-3/12 flex-shrink-0">
                    <BundleForm updatetable={updatetable} requesttype ={requesttype} currentsession={currentsession} setCurrentsession = {setCurrentsession} setRequesttype = {setRequesttype}/>
                </div>
                <div className=" bg-white py-3 w-11/12 md:w-9/12 mx-auto rounded-b-md md:rounded-md">
                <p className="text-xl pb-4 pt-2 w-11/12 mx-auto font-bold gg-erp-color ">ITEM BUNDLE LIST</p>
                    <div className="w-full">
                        <Table tableItems={tableItems} EditFunction={EditFunction} DeleteFunction={DeleteFunction}/>
                    </div> 
                </div>
            </div>
        </div>
            <Footer />
        </>
    )
}

export default Bundle