import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios"
import mdtoast from '@dmuy/toast'
import { SERVER } from "../../../utils";
import { authHeader } from "../../../helper/authHeader";

const SemesterSessionForm = ({updatetable, currentSemester, requesttype, setRequesttype, setCurrentSemester}) => {

    const [sessions, setSessions] = useState([])
    const [semesters, setSemesters] = useState([])
    const [courses, setCourses] = useState([])

    const handlePost = async () => {
        const body = {
            session: Number(currentSemester.session.value),
            semester: currentSemester.semester.value,
            course: currentSemester.course.map(item => item.value)
        }
        try {
            if (requesttype == 'post') {
                // const url = SERVER + 
                const res = await axios.post("/apiV1/semester-session/", body, { headers: authHeader() });
                mdtoast.success("Semester Successfully Created", { duration: 1000 })
            } else {
                const url = SERVER + "/apiV1/semester-session/" + currentSemester.id + "/"
                const res = await axios.put(`/apiV1/semester-session/${currentSemester.id}/`, body, { headers: {       
                    ...authHeader(),      
                    'content-type': 'application/json',
                    'Accept': 'application/json' } });
                mdtoast.success("Semester Successfully Edited", { duration: 1000 })
                setRequesttype("post")
            }      
            setCurrentSemester({
                "semester": "",
                "session": "",
                "course": null,
            })

            updatetable()
        } catch (err) {
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }
    }
    
    useEffect(()=>{
        const getData = async () => {
            try{
                var res = await axios.get("https://apidemocollege.gungunerp.in/apiV1/session/" ,  { headers: authHeader() })
                var list = res.data.map((item)=>{
                    return {"label": item.session_name , "value" : item.id}
                })
                setSessions(list)
            }catch(err){
                console.log(err)
            }
        }
        getData()
    } , [window.url])

    useEffect(() => {
        console.log(sessions)
    },[sessions])

    useEffect(()=>{
        const getData = async () => {
            try{
                var res = await axios.get("https://apidemocollege.gungunerp.in/apiV1/course/" ,  { headers: authHeader() })
                var validcourses = res.data.map(item => {
                    return {"label": item.course_name , "value" : item.id }
                })
                setCourses(validcourses)
            }catch(err){
                console.log(err)
            }
        }
        getData()
    } , [window.url])

    useEffect(()=>{
        const getData = async () => {
            try{
                var res = await axios.get("https://apidemocollege.gungunerp.in/apiV1/semester/" ,  { headers: authHeader() })
                var validcourses = res.data.map(item => {
                    return {"label": item.semester_name , "value" : item.id }
                })
                setSemesters(validcourses)
            }catch(err){
                console.log(err)
            }
        }
        getData()
    } , [window.url])

    return (
        <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-md">
            <p className="text-xl font-bold gg-erp-color ">SEMESTER SESSION</p>

            <label for="session" className="w-full py-2">Select Semester
                    <Select
                        options={semesters}
                        placeholder="Select..."
                        // value={currentSemester.semester === null ? sessions.filter(session => session.value === currentSemester.session) : null}
                        value={currentSemester.semester}
                        onChange={(option) => setCurrentSemester({...currentSemester, semester:option})}
                        isClearable={true}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                                ...theme.colors,
                                primary25: 'lightCyan',
                                primary: '#ff5722',
                            },
                        })}
                    />
                </label>
            
            <label for="session" className="w-full py-2">Select Session
                    <Select
                        options={sessions}
                        placeholder="Select..."
                        // value={currentSemester.session === null ? sessions.filter(session => session.value === currentSemester.session) : null}
                        value={currentSemester.session}
                        onChange={(option) => setCurrentSemester({...currentSemester, session:option})}
                        isClearable={true}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                                ...theme.colors,
                                primary25: 'lightCyan',
                                primary: '#ff5722',
                            },
                        })}
                    />
                </label>

                <label for="department" className="w-full py-2">Select Course
                    <Select
                        options={courses}
                        value={currentSemester.course}
                        onChange={(option) => setCurrentSemester({...currentSemester, course:[...option]})}
                        isMulti
                        placeholder="Select..."
                        isClearable={true}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                                ...theme.colors,
                                primary25: 'lightCyan',
                                primary: '#ff5722',
                            },
                        })}
                    />
                </label>
            <hr/>

            <div className="text-right mt-2">
                <button type="submit" onClick={handlePost} className="submit">Submit</button>
            </div>
        </div>
    )
}

export default SemesterSessionForm