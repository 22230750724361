import React , {useEffect, useState} from "react";
// import { formServices } from "../../../services/formServices";
import { authHeader } from "../../../helper/authHeader";
import axios from "axios"
// import { SERVER } from "../../../utils";
import mdtoast from "@dmuy/toast";
import { setInfo, setLoaded } from '../../../redux/info';
import { useDispatch} from 'react-redux';

const InfoForm = () => {

    const dispatch = useDispatch()

    const [institute, setInstitute] = useState("")
    const [shortname, setShortname] = useState("")
    const [address, setAddress] = useState("")
    const [affiliation, setAffiliation] = useState("")
    const [registration, setRegistration] = useState("")
    const [officeno, setOfficeno] = useState("");
    const [help, setHelp] = useState("")
    const [email, setEmail] = useState("")
    const [api, setApi] = useState(String(axios.getUri))
    const [website, setWebsite] = useState("")

    const [instituteLogo, setInstituteLogo] = useState(null)
    const [authority, setAuthority] = useState(null)
    const [background, setBackgroud] = useState(null)
    const [board, setBoard] = useState(null)

    const [logoUrl, setLogoUrl] = useState(null)
    const [signUrl, setSignUrl] = useState(null)
    const [backUrl, setBackUrl] = useState(null)
    const [boardUrl, setboardUrl] = useState(null)

    const [change, setChange] = useState(false)
    // {
    //     "id": 2,
    //     "college_name": "Demo College",
    //     "college_image": "https://d1ee6aozb8ipmj.cloudfront.net/democollege/media/college_image/android.png",
    //     "short_name": null,
    //     "address": "Bangalore",
    //     "background_image": null,
    //     "signature": null,
    //     "image": null,
    //     "phone_number": "9876543210",
    //     "email": "test@trixno.com",
    //     "affilation_no": null,
    //     "registration_no": null,
    //     "office_no": "01",
    //     "website_address": null,
    //     "api_url": null
    // }

    const handlePost = async () => {
        const body = new FormData()
        body.append("college_name", institute)
        body.append("short_name", shortname)
        body.append("address",address)
        body.append("phone_number",help)
        body.append("email", email)
        body.append("affilation_no", affiliation)
        body.append("registration_no", registration)
        body.append("office_no", officeno)
        body.append("website_address", website)
        body.append("api_url", api)

        if(instituteLogo !== null && instituteLogo !== ""){
            body.append("college_image",instituteLogo)
        }
        
        if(authority !== null && authority !== ""){
            body.append("signature", authority)
        }
        
        if(background !== null && background !== ""){
            body.append("background_image", background)
        }
        
        if(board !== null && board !== ""){
            body.append("image", board)
        }
        // console.log(body)
        try{
            // const url = SERVER + 
            const res = axios.put(
                "/apiV1/institute/2/",
                body, 
                { headers : {
                    ...authHeader(),
                    'Content-Type': 'multipart/form-data'
                }
            })
            mdtoast.success("College Information Successfully Created", { duration: 1000 })
            setChange(!change)
            setInstituteLogo(null)
            setAuthority(null)
            setBackgroud(null)
            setBoard(null)
            axios.get("/apiV1/institute/",{ headers: authHeader() })
            .then(res => {
              dispatch(setInfo(res.data))
              console.log(res)
              // mdtoast.success('Success Loading', { duration: 1000})
            }).then(() => {
                  dispatch(setLoaded())
            })
            .catch(err => {
              console.log(err)
              mdtoast.error('An Error Occured While Loading', { duration: 1000})
            })
        }catch (err){
            console.log(err)
        }
    }

    useEffect(() => {
        const getInfo = async () => {
            var res = await axios.get( "/apiV1/institute/2/" , { headers: authHeader() });
            console.log(res.data)
            setInstitute(res.data.college_name)
            setShortname(res.data.short_name)
            setAddress(res.data.address)
            setAffiliation(res.data.affilation_no)
            setRegistration(res.data.registration_no)
            setOfficeno(res.data.office_no)
            setHelp(res.data.phone_number)
            setEmail(res.data.email)
            setWebsite(res.data.website_address)
            setApi(res.data.api_url)

            setLogoUrl(res.data.college_image)
            setSignUrl(res.data.signature)
            setboardUrl(res.data.image)
            setBackUrl(res.data.background_image)
        }
        getInfo()
    },[change])

    return (
        <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-md">
            <p className="text-xl font-bold gg-erp-color ">College</p>
            <div className="grid lg:grid-cols-5 md:grid-cols-2 gap-2">

                <label for="college_name" className="w-full py-2"> Institute Name
                <input className="form-input" type="text" name="college_name" placeholder="Institute Name" value={institute == null ? "" : institute} onChange={e => setInstitute(e.target.value)}/>
                </label>

                <label for="" className="w-full py-2"> Short Name
                <input className="form-input" type="text" name="college_name" placeholder="Short Name" value={shortname == null ? "" : shortname} onChange={e => setShortname(e.target.value)}/>
                </label>
                <label for="" className="w-full py-2"> College Address
                <input className="form-input" type="text" name="address" placeholder="College Address" value={address == null ? "" : address} onChange={e => setAddress(e.target.value)}/>
                </label>

                <label for="" className="w-full py-2"> Affiliation No.
                <input className="form-input" type="number" name="affilition_num" placeholder="Affiliation No." value={affiliation == null ? "" : affiliation} onChange={e => setAffiliation(e.target.value)}/>
                </label>

                <label for="" className="w-full py-2"> Registration No.
                <input className="form-input" type="number" name="registration_num" placeholder="Registration No." value={registration == null ? "" : registration} onChange={e => setRegistration(e.target.value)}/>
                </label>

                <label for="" className="w-full py-2"> Office No. (Contact)
                <input className="form-input" type="number" name="contact_office" placeholder="Office No. (Contact)" value={officeno == null ? "" : officeno} onChange={e => setOfficeno(e.target.value)}/>
                </label>

                <label for="" className="w-full py-2"> Help No. (Contact)
                <input className="form-input" type="number" name="help_office" placeholder="Help No. (Contact)" value={help == null ? "" : help} onChange={e => setHelp(e.target.value)}/>
                </label>

                <label for="" className="w-full py-2"> Email
                <input className="form-input" type="email" name="email" placeholder="Email" value={email == null ? "" : email} onChange={e => setEmail(e.target.value)}/>
                </label>

                <label for="" className="w-full py-2"> API URL
                <input type="text" className="form-input" name="api_url" placeholder="API URL" value={api == null ? "" : api} disabled/>
                </label>

                <label for="" className="w-full py-2 mb-2"> Website Address
                <input className="form-input" type="text" name="website_url" placeholder="Website Address" value={website == null ? "" : website} onChange={e => setWebsite(e.target.value)}/>
                </label>
            </div>
            <hr/>
            <div className="grid md:grid-cols-2 gap-2 mt-2">

                <label for="" className="w-full py-2 gg-erp-color text-base"> Upload Institute Logo
                <input className="border-0 w-full mt-2 text-sm text-black" onChange={e => setInstituteLogo(e.target.files[0])} type="file"  name="college_name" placeholder="Upload Image"/>
                    <div className="h-16">
                        {/* {logoUrl == null && logoUrl !== "" ? <img src={logoUrl} alt="institute logo" className=""/> : null} */}
                    </div> 
                </label>

                <label for="" className="w-full py-2 gg-erp-color text-base"> Upload Authority Signature
                <input className="border-0 w-full mt-2 text-sm text-black" onChange={e => setAuthority(e.target.files[0]) } type="file" name="college_name" placeholder="Upload Image"/>
                    <div className="h-16">
                        {/* {signUrl !== null && signUrl !== "" ? <img src={signUrl} alt="institute logo" className=""/> : null} */}
                    </div> 
                </label>

                <label for="" className="w-full py-2 gg-erp-color text-base"> Upload Dashboard Image
                <input className="border-0 w-full mt-2 text-sm text-black" onChange={e => setBackgroud(e.target.files[0])} type="file" name="college_name" placeholder="Upload Image"/>
                    <div className="h-16">
                        {/* {backUrl !== null && backUrl !== ""  ? <img src={backUrl} alt="institute logo" className=""/> : null} */}
                    </div> 
                </label>

                <label for="" className="w-full py-2 gg-erp-color text-base"> Upload Board Logo
                <input className="border-0 w-full mt-2 text-sm text-black" onChange={e => setBoard(e.target.files[0])} type="file" name="college_name" placeholder="Upload Image"/>
                    <div className="h-16">
                        {/* {boardUrl !== null && boardUrl !== "" ? <img src={boardUrl} alt="institute logo" className=""/> : null} */}
                    </div> 
                </label>

            </div>
            <hr/>
            <div className="mt-2 text-right">
                <button type="submit" className="submit" onClick={handlePost}>Submit</button>
            </div>
        </div>
    )
}

export default InfoForm