import React, { useEffect, useState } from "react"
import NavBar from "../../components/navbar-components/NavBar";
import Footer from "../../components/Footer";
import mdtoast from "@dmuy/toast";
import axios from "axios";
import { authHeader } from "../../helper/authHeader";
import Table from "../../components/Table"

const CreateUser = () => {

    const [data,setData] = useState({
        username: "",
        password: "",
        name: "",
        contact: "",
        email: "",
        address: ""
    })

    const [tableItems , setTableItems] = useState([
        {id: null,"Item Name": 0, "Quantity": 0, "Selling Price": 0, "Product Category": 0, "Status" : 0 , "Action" : 0 , empty:true },
    ])

    const handlePost = async () => {
        const body = {...data}
        try {
            const res = await axios.post("api/v1/user", body, { headers: authHeader() });
            mdtoast.success("User Successfully Created", { duration: 1000 })
            setData({
                username: "",
                password: "",
                name: "",
                contact: "",
                email: "",
                address: ""
            })
        } catch (err) {
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }
    }

    const updatetable = async ()=>{
        try{
            var res = await axios.get("/api/v1/user" , { headers:authHeader()});
            console.log(res.data)
            let count = 0
            if (res.data.length>0){
                var newtable = res.data.map(item=>{
                    count++
                    return {"id": item.id,extra: false,"Sr. No.":count, "Username": item.username,"Name": item.name, Email: item.email, Contact: item.contact, "Status" : true , "Action" : "Action", extraInfo: {Password: item.password, Address: item.address, "Date Of Joining": item.date_joined} }
                })
                setTableItems(newtable)
            }
        }catch(err){
            console.log(err);
        }
    }

    const disableFunction = async (id) => {
        try{
            var res = await axios.post(`/api/v1/user/${id}` , { headers:authHeader()});
            console.log(res.data)
        }catch(err){
            console.log(err);
        }
    }
    useEffect(() => {
        updatetable()
    },[window.url])

    return (
        <>
        <NavBar/>
        <div className="mx-auto w-full bg-gray-200 py-5">
            <div className="w-11/12 mx-auto pt-4 rounded-t-md bg-white">
                <p className="text-xl w-11/12 py-4 mx-auto font-bold gg-erp-color ">CREATE USER</p>
                <div className="w-11/12 mx-auto grid md:grid-cols-2 lg:grid-cols-3 gap-2">
                    
                    <label for="name" className="mb-2 w-full py-2">Username
                        <input className="form-input py-1" value={data.username}  type="text" name="usr" onChange={e => setData({...data, username: e.target.value})}/>
                    </label>
                    
                    <label for="name" className="mb-2 w-full py-2">Password
                        <input className="form-input py-1" value={data.password} type="text" name="usr" onChange={e => setData({...data, password: e.target.value})}/>
                    </label>
                    
                    <label for="name" className="mb-2 w-full py-2">Name
                        <input className="form-input py-1"  type="text" value={data.name} name="usr" onChange={e => setData({...data,name: e.target.value})}/>
                    </label>
                    
                    <label for="name" className="mb-2 w-full py-2">Contact
                        <input className="form-input py-1"  type="text" value={data.contact} name="usr" onChange={e => setData({...data,contact: e.target.value})}/>
                    </label>
                    
                    <label for="name" className="mb-2 w-full py-2">Email
                        <input className="form-input py-1"  type="email" value={data.email} name="usr" onChange={e => setData({...data,email: e.target.value})}/>
                    </label>
                    
                    <label for="name" className="mb-2 w-full py-2">Address
                        <input className="form-input py-1"  type="text" name="usr" value={data.address} onChange={e => setData({...data,address: e.target.value})}/>
                    </label>
                    
                </div>
                <hr className="w-11/12 mt-4 mx-auto"/>
                <div className="w-11/12 mx-auto text-right mt-2 py-2 pb-4">
                    <button onClick={handlePost} type="submit" className="submit text-sm px-4">Submit</button>
                </div>
                <div className="pb-6">
                <p className="text-xl w-11/12 py-4 mx-auto font-bold gg-erp-color ">USERS</p>
                    <Table tableItems={tableItems} setTable={setTableItems} disableFunction={disableFunction} />   
                </div>
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default CreateUser