import React from "react";
import Footer from "../../components/Footer";
import NavBar from "../../components/navbar-components/NavBar";
import SMSForm from "../../components/forms/Configuration/SMS"

const SMS = () => {
    return (
        <>
        <NavBar/>
        <div className="lg:flex bg-gray-200 py-5">
            <SMSForm/>
        </div>
        <Footer/>
        </>
    )
}

export default SMS