import React from "react";
import NavBar from "../../components/navbar-components/NavBar";
import Footer from "../../components/Footer";
import Table from "../../components/Table";
import { Link } from "react-router-dom";

const tableItems = [
    {"S.no": null,"Name": null, "Registration No": null, empty:true}
]

const Sell = () => {
    return (
        <>
        <NavBar/>
        <div className="bg-gray-200">
            <div className="mx-auto w-full bg-gray-200 py-5">
                <div className="w-11/12 mx-auto bg-white py-3 rounded-t">   
                    <p className="pt-2 w-11/12 mx-auto text-2xl font-bold gg-erp-color ">SELL</p>
                    <div className="w-11/12 mx-auto text-left md:text-right mt-2">
                        <Link to="/inventory/addsell">
                            <button onClick={() => null} type="submit" className="submit text-xs">ADD SELL</button>
                            </Link>
                    </div>
                    <div className="grid md:grid-cols-2 gap-2 w-11/12 mx-auto">    
                        <div className=" mx-auto gap-2 grid grid-cols-2">   
                            <label for="start" className="w-full py-2">Start Date
                                <input type="date" placeholder="dd-mm-yyyy" name="start" className="form-input p-1" />
                            </label>

                            <label for="end" className="w-full py-2">End Date
                                <input type="date" name="end" className="form-input p-1" />
                            </label>
                        </div> 
                        <div className="w-full mx-auto">
                            <label for="text" className="py-2 w-full">Username
                                <input type="text" name="sur" className="form-input p-1" />
                            </label>
                        </div>
                    </div>

                    <div className="w-11/12 mx-auto text-right mt-2">
                        <button onClick={() => null} type="submit" className="submit text-sm md:w-full">Search</button>
                    </div>
                </div> 
                <div className=" bg-white py-3 w-11/12 mx-auto rounded-b-md md:rounded-md">
                    <div className="w-11/12 mx-auto">
                        <p className="pt-2 text-lg font-bold gg-erp-color ">SEND SMS</p>
                        <label for="name" className="w-full mx-auto pb-2">
                            <textarea className="form-input" type="text" name="usr" onChange={e => (e.target.value)}/>
                        </label>
                        <hr className="mx-auto"/>
                        <div className=" mx-auto text-right py-2">
                            <button onClick={()=> null} type="submit" className="submit text-sm py-1 px-4">Send</button>
                        </div>
                    </div>
                    <Table tableItems={tableItems}/>
                </div>
            </div>
        </div>
        <Footer/>
        </> 
    )
}

export default Sell