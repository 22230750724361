import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import store from './redux/store';
import { Provider } from 'react-redux';
import '@dmuy/toast/dist/mdtoast.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import "@material-tailwind/react/tailwind.css";
import "./styles/index.css"
import axios from "axios"

let user = JSON.parse(localStorage.getItem('user'));
axios.defaults.baseURL = 'https://store-backend-v1.herokuapp.com'
// axios.defaults.headers.common['Authorization'] = `JWT ${localStorage.getItem(user.token)}`
axios.defaults.headers.post['Content-Type'] = 'application/json'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Routes />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
