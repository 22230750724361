import React , {useState} from "react";
import { formServices } from "../../../services/formServices";
import Select from "react-select"

const BackDuesForm = () => {

    const [username , setUsername] = useState("");
    const [course , setCourse] = useState("");
    const [rollno , setRollno] = useState("");
    const[status , setStatus] = useState("")
    const [admissionno , setAdmissionno] = useState("");
    const [search , setSearch] = useState("");

    const handlePost = () => {
        return formServices.backduesPost(username, course , rollno , status , admissionno , search)
    }

    const courses = [
        {label: "Information Technology", value: "it"},
        {label: "CSE", value: "cse"},
        {label: "Demo", value: "demo"}
    ]

    const statuses = [
        {label: "Current", value: "current"},
        {label: "Passout", value: "passout"},
        {label: "Dropout", value: "dropout"}
    ]

    return (
        <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-md">
            <p className="text-xl font-bold gg-erp-color ">STUDENT DUES LIST</p>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-2">
            <label for="usr" className="w-full py-2">Username
                <input className="form-input" type="text" placeholder="Username" name="usr" onChange={e => setUsername(e.target.value)} /> 
            </label>

            <label for="crse" className="w-full py-2">Course
            <Select 
                options={courses}
                value={course}
                onChange = {(option)=>setCourse(option)}
                placeholder="Select Course" 
                isClearable={true} 
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                    ...theme.colors,
                    primary25: 'lightCyan',
                    primary: '#ff5722',
                    },
                })}
            />
            </label>

            <label for="roll_no" className="w-full py-2">Roll No.
                <input className="form-input" type="text" placeholder="Roll No." name="roll_no" onChange={e => setRollno(e.target.value)} /> 
            </label>

            <label for="status" className="w-full py-2">Status
            <Select 
                options={statuses}
                value={status}
                onChange = {(option)=>setStatus(option)}
                placeholder="Select Status" 
                isClearable={true} 
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                    ...theme.colors,
                    primary25: 'lightCyan',
                    primary: '#ff5722',
                    },
                })}
            />
            </label>

            <label for="adm_no" className="w-full py-2">Admission No.
                <input className="form-input" type="text" placeholder="Admission No." name="adm_no" onChange={e => setAdmissionno(e.target.value)} /> 
            </label>
  
            <label for="search" className="w-full py-2">Search
                <input className="form-input" type="search" placeholder="Search" name="srch" onChange={e => setSearch(e.target.value)}/> 
            </label>
          
            </div>
            <hr/>
            <div className=" text-center mt-2">
                <button type="submit" className="submit w-full" onClick = {handlePost}>Search</button>
            </div>
        </div>
    )
}

export default BackDuesForm