import React, { useState, useEffect } from "react";
import NavBar from "../../components/navbar-components/NavBar";
import Footer from "../../components/Footer";
import Table from "../../components/Table";
// import {Multiselect} from 'multiselect-react-dropdown';
import Select from "react-select";


// const tableItems = [
//     {"S.no": null, "Name": null, "Account Head": null, "Payment Method": null, "Amount": null, "Date": null, "Description":null, empty: true}
// ]



// const style = {
//     searchBox: { // To change search box element look
//         padding: "3px",
//         "border-radius": "0px" 
//     },
//     chips: { // To change css chips(Selected options)
//         background: 'red',
//         border: "none"
//     },
//     optionContainer: { // To change css for option container 
//         border: "none",
//     },
//     option: { // To change css for dropdown options
//         color: 'black'
//     },
// }
const AccountSummary = () => {
    const [startdate, setStartdate] = useState("");
    const [enddate, setEnddate] = useState("");
    const [category, setCategory] = useState("");
    const [course, setCourse] = useState("");
    const [session, setSession] = useState("");
    const [accountcheck, setAccountcheck] = useState(false);
    const [feecheck, setFeecheck] = useState(false);
    const [inventorycheck, setInventorycheck] = useState(false);


    const [tableItems, setTableItems] = useState([
        { "S.no": null, "Date": null, "Category": null, "Sub Category": null, "Description": null, "Type": null, "Amount": null, empty: true }
    ])

    const options = [
        {label: "Option 1", value: "opt1"},
        {label: "Option 2", value: "opt2"},
        {label: "Option 3", value: "opt3"},
        {label: "Option 4", value: "opt4"},
    ]

    // useEffect(() => {
    //     console.log("groupby changed", groupby)
    //     if (groupby) {
    //         setTableItems([
    //             { "S.no": null, "Head": null, empty: true }
    //         ])
    //     } else {
    //         setTableItems([
    //             { "S.no": null, "Name": null, "Account Head": null, "Payment Method": null, "Amount": null, "Date": null, "Description": null, empty: true }
    //         ])
    //     }
    // }, [groupby])

    const handlePost = () => {

    }
    return (
        <>
            <NavBar />
            <div className="bg-gray-200">
                <div className="mx-auto w-full bg-gray-200 py-5">
                    <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-md">
                        <p className="text-xl font-bold gg-erp-color ">OVERALL ACCOUNTS SUMMARY</p>

                        <div className="my-3" style={{ display: "flex", justifyContent: "space-evenly" }}>
                            <div>
                                <h2 className="text-lg text-center">0</h2>
                                <p className="text-lg font-bold gg-erp-color ">Total Income</p>
                            </div>

                            <div>
                                <h2 className="text-lg text-center">0</h2>
                                <p className="text-lg font-bold gg-erp-color ">Total Expense</p>
                            </div>

                            <div>
                                <h2 className="text-lg text-center">0</h2>
                                <p className="text-lg font-bold gg-erp-color ">Total Balance</p>
                            </div>

                        </div>


                        <div className="grid md:grid-cols-2 gap-2">
                            <label for="usr" className="w-full py-2">Start Date
                                <input className="form-input" type="date" placeholder="Start Date" name="usr" onChange={e => setStartdate(e.target.value)} />
                            </label>

                            <label for="crse" className="w-full py-2">End Date
                                <input className="form-input" type="date" placeholder="End Date" name="crse" onChange={e => setEnddate(e.target.value)} />
                            </label>
                        </div>
                        <div className="grid md:grid-cols-3 gap-2">
                            <label for="crse" className="w-full py-2"> Category
                            <Select 
                                options={options}
                                value={category}
                                onChange = {(option)=>setCategory(option)}
                                isMulti
                                placeholder="Select Category" 
                                isClearable={true} 
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                    ...theme.colors,
                                    primary25: 'lightCyan',
                                    primary: '#ff5722',
                                    },
                                })}
                            />
                            </label>

                            <label for="crse" className="w-full py-2"> Course
                            <Select 
                                options={options}
                                value={course}
                                onChange = {(option)=>setCourse(option)}
                                isMulti
                                placeholder="Select Course" 
                                isClearable={true} 
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                    ...theme.colors,
                                    primary25: 'lightCyan',
                                    primary: '#ff5722',
                                    },
                                })}
                            />
                            </label>

                            <label for="crse" className="w-full py-2"> Session
                            <Select 
                                options={options}
                                value={session}
                                onChange = {(option)=>setSession(option)}
                                isMulti
                                placeholder="Select Session" 
                                isClearable={true} 
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                    ...theme.colors,
                                    primary25: 'lightCyan',
                                    primary: '#ff5722',
                                    },
                                })}
                            />
                            </label>

                        </div>
                        <hr />
                        <div className="grid lg:grid-cols-5 md:grid-cols-3 gap-2 m-3">
                            <label>
                            <span className="mr-2">Fee</span>
                                <input
                                    name="isGoing"
                                    type="checkbox"
                                    checked={feecheck}
                                    onChange={e => setFeecheck(e.target.checked)} />
                            </label>
                            <label>
                            <span className="mr-2">Inventory</span>
                                <input
                                    name="isGoing"
                                    type="checkbox"
                                    checked={inventorycheck}
                                    onChange={e => setInventorycheck(e.target.checked)} />
                            </label>
                            <label>
                            <span className="mr-2">Account</span>
                                <input
                                    name="isGoing"
                                    type="checkbox"
                                    checked={accountcheck}
                                    onChange={e => setAccountcheck(e.target.checked)} />
                            </label>
                        </div>
                        <div className=" text-center mt-2">
                            <button type="submit" className="submit w-full" onClick={handlePost}>Search</button>
                        </div>
                        <div style={{ display: "flex" }}>

                            <div className=" text-center m-2">
                                INCOME REPORT <button type="submit" className="export text-sm" onClick={handlePost}><i class="bi bi-file-earmark-spreadsheet mr-1 text-lg"></i>Export to Excel</button>
                            </div>
                        </div>
                    </div>
                    <div className=" bg-white py-3 w-11/12 mx-auto rounded-b-md md:rounded-md">
                        <Table tableItems={tableItems} />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}


export default AccountSummary