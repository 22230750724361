import React, {useState, useEffect} from "react";
import NavBar from "../../components/navbar-components/NavBar";
import Footer from "../../components/Footer";
import SemesterSessionForm from "../../components/forms/Academic/SemesterSession"
import Table from "../../components/Table";
import mdtoast from '@dmuy/toast'
import { SERVER } from "../../utils";
import axios from "axios";
import { authHeader } from "../../helper/authHeader";

const tableItems = [
    {"Course Name": null, Duration: null, empty: true}
]

const SemesterSession = () => {

    const [tableItems , setTableItems] = useState([
        {"Semester Name": 0, "Session": 0, "Status" : 0 , "Action" : 0 , empty:true },
    ])
    const [requesttype , setRequesttype] = useState("post")
    const [courses, setCourses] = useState([])
    const[currentSemester , setCurrentSemester] = useState({
        "session": "",
        "semester": "",
        "course": []
    })

    const updatetable = async ()=>{
        try{
            var res = await axios.get( "/apiV1/semester-session/" , { headers: authHeader() });
            console.log(res.data)
            if (res.data.length>0){
                var newtable = res.data.map(item=>{
                    return {"id": {id: item.id, course: [...item.course.map(course => {return { label: course.course_name, value: course.id }})], session: item.session.id, semester: item.semester.id}, "Course Code": item.course.map(course => course.course_code).join(", "), "Course Name": item.course.map(course => course.course_name).join(", "), Semester: item.semester.semester_name, "Session Name": item.session.session_name ,"Status" : true , "Action" : "Action" }
                })
                setTableItems(newtable)
                console.log(newtable)
            }
        }catch(err){
            console.log(err);
        }
    }

    const EditFunction =  async (item) => {
    setRequesttype("put");
    setCurrentSemester({
        'id' : item.id.id,
        'session' : {label:item["Session Name"], value: item.id.session},
        'semester': {label:item["Semester"], value: item.id.semester},
        'course': [...item.id.course]
    })
    }

    const DeleteFunction =  async (id)=>{
        // console.log(id + "has been deleted")
        try{
            // const url = SERVER + "/apiV1/semester-session/" + id.id
            const res = await axios.delete(`/apiV1/semester-session/${id.id}/`, { headers: authHeader() });
            mdtoast.success("Session Deleted Successfully ", { duration: 1000 })
            setTableItems(tableItems.filter((item)=>item.id !== id.id))
            updatetable()
        }catch (err) {
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }
        
    }

    useEffect(async ()=>{
        updatetable()
    },[window.url])

    return (
        <>
        <NavBar/>
        <div className="bg-gray-200">
            <div className="mx-auto md:flex md:w-11/12 justify-center bg-gray-200 py-5">
                <div className="md:w-3/12 flex-shrink-0">
                        <SemesterSessionForm updatetable={updatetable}  requesttype={requesttype} setTableItems={setTableItems} setCurrentSemester={setCurrentSemester} currentSemester={currentSemester} setRequesttype={setRequesttype}/>
                </div>
                <div className="flex items-center bg-white py-3 w-11/12 md:w-9/12 mx-auto rounded-b-md md:rounded-md">
                    <div className="w-full">
                        <Table updatetable={updatetable} DeleteFunction={DeleteFunction} EditFunction={EditFunction} tableItems={tableItems}/>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default SemesterSession