import React from "react";
import DemandForm from "../../components/forms/Fee/Demand";
import NavBar from "../../components/navbar-components/NavBar";
import Footer from "../../components/Footer";
import Table from "../../components/Table";

const tableItems = [
    {"S.no": null, "Username": null, "Name": null, "Course": null, empty:true}
]
const Demand = () => {
    return (
        <>
        <NavBar/>
        <div className="bg-gray-200">
            <div className="mx-auto w-full bg-gray-200 py-5">
                    <DemandForm/>
                <div className=" bg-white py-3 w-11/12 mx-auto rounded-b-md md:rounded-md">
                    <Table tableItems={tableItems}/>
                </div>
            </div>
        </div>
        <Footer/>
        </> 
    )
}

export default Demand