import React , {useState} from "react";
import { formServices } from "../../../services/formServices";

const TypeForm = () => {

    const [fee, setFee] = useState("");
    const [description, setDescription] = useState("");

    const handlePost = () => {
        return formServices.typePost(fee , description)
    }
   

    return (
        <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-md">
            <p className="text-xl font-bold gg-erp-color ">ADD FEE TYPE</p>
            
            <label for="fee_type" className="mb-2 w-full py-2">Fee Type
                <input className="form-input" placeholder="Fee Type Name" type="text" name="fee_type" onChange={e => setFee(e.target.value)}/>
            </label>

            <label for="desc" className="mb-2 w-full py-2">Description
                <textarea className="form-input"  name="desc" onChange={e => setDescription(e.target.value)}/>
            </label>

            <hr/>
            <div className="text-right mt-2">
                <button type="submit" className="submit" onClick = {handlePost}>Submit</button>
            </div>
        </div>
    )
}

export default TypeForm