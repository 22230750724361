import React from "react";
import Footer from "../../components/Footer";
import NavBar from "../../components/navbar-components/NavBar";
import CategoryForm from "../../components/forms/Configuration/Category"
import Table from "../../components/Table";
import IncomeCategoryForm from "../../components/forms/Accounts/IncomeCategoryForm";
import ExpenseCategoryForm from "../../components/forms/Accounts/ExpenseCategoryForm";

const tableItems = [
    { "Sr no.": null, "Head": null, "Status": null, "Action": null, empty: true }
]

const ExpenseCategory = () => {
    return (
        <>
            <NavBar />
            <div className="bg-gray-200">
                <div className="mx-auto md:flex md:w-11/12 justify-center bg-gray-200 py-5">
                    <div className="md:w-3/12 flex-shrink-0">
                        <ExpenseCategoryForm/>
                    </div>
                    <div className="flex items-center bg-white py-3 w-11/12 md:w-9/12 mx-auto rounded-b-md md:rounded-md">
                        <div className="w-full">
                            <div className="w-11/12 mx-auto text-sm text-right mr-5">
                                <button type="submit" className="submit">Inactive</button>
                            </div>
                            <Table tableItems={tableItems} />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ExpenseCategory