import React , {useState} from "react";
import { formServices } from "../../../services/formServices";

const ParticularForm = () => {
    const [num , setNum] = useState("");
    const [message , setMessage] = useState("");

    // const handlePost = () => {
    //     return formServices.messagestudentPost(tostudent , message)
    // }

    return (
        <div className="text-sm bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-md">
            <p className="text-xl font-bold gg-erp-color ">PARTICULAR NUMBER SMS</p>

            <div className="grid gap-2 md:grid-cols-2">   
                <label for="num" className="mb-2 w-full py-2">Number
                    <textarea className="form-input" placeholder="" type="text" name="num" onChange={e => setNum(e.target.value)}/>
                </label>

                <label for="message" className="mb-2 w-full py-2">Message
                    <textarea className="form-input" placeholder="" type="text" name="message" onChange={e => setMessage(e.target.value)}/>
                </label>
            </div>

            <hr/>
            <div className="text-right mt-2">
                <button type="submit" className="submit" >Send SMS</button>
            </div>
        </div>
    )
}

export default ParticularForm