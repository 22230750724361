import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer";
import Select from "react-select"
import axios from "axios";
// import { SERVER } from "../../../utils";
import mdtoast from '@dmuy/toast'
import { authHeader } from "../../helper/authHeader";
import { useHistory } from "react-router";
import NavBar from "../../components/navbar-components/NavBar";
import Table from "../../components/Table";

const AddPurchase = () => {
    const [supplierlist, setSupplierlist] = useState([]);
    const [suppliername, setSuppliername] = useState([]);
    const [date, setDate] = useState("");
    const [invoice, setInvoice] = useState("");
    const [discount, setDiscount] = useState(0);
    const [totalamount, setTotalamount] = useState("");
    const [sumtotal, setSumtotal] = useState("");
    const [discounttype, setDiscounttype] = useState({ label: "Amount", value: "amount" });
    const [itemname, setItemname] = useState("");
    const [unitprice, setUnitprice] = useState("");
    const [quantity, setQuantity] = useState("")
    const [productdd, setProductdd] = useState([])
    const [productdata, setProductdata] = useState([])


    const history = useHistory();

    const [tableItems, setTableItems] = useState([
        { "Item Name": "", "Unit Price": "", "Quantity": "", "Action": null, empty: true }
    ])

    const discountlist = [
        { label: "Amount", value: "amount" },
        { label: "Percentage", value: "percentage" },
    ]


    useEffect(() => {
        const getData = async () => {
            try {
                var res = await axios.get("/api/v1/product", { headers: authHeader() })
                // var productlist = res.data.map((item) => {
                //     return { "label": item.department_name, "value": item.id }
                // })
                if (res.data.length > 0) {
                    setProductdata(res.data)
                }
                var res = await axios.get("/api/v1/supplier", { headers: authHeader() })
                if (res.data.length > 0) {
                    setSupplierlist(res.data)
                }

            } catch (err) {
                console.log(err)
            }
        }
        getData()
    }, [window.url])

    const DeleteFunction = (id) => {
        var x = []
        tableItems.forEach((item) => {
            if (item.id != id) {
                x.push(item)
            }
        })
        if (x.length == 0) {
            x.push({ "Item Name": "", "Unit Price": "", "Quantity": "", "Action": null, empty: true })
        }
        setTableItems(x)
    }


    const handlePost = async () => {
        

        var supplier=""
        supplierlist.forEach((sup)=>{
            if(sup.id==suppliername.value){
                supplier=sup
            }
        })

        var items = []
        tableItems.forEach((item)=>{
            var x="";
            productdata.forEach((prod)=>{
                if(prod.id==item["id"]){
                    x=prod
                }
            })
            items.push({
                "quantity" : item["Quantity"],
                "price": item["Unit Price"],
                "item" : x
            })
        })

        const body = {
            "date": date,
            "invoice_num": invoice,
            "total_amount": totalamount,
            "supplier_name": supplier,
            "items": items
        }
        // console.log(body)
        try {
            const res = await axios.post("/api/v1/product", body, { headers: authHeader() });
            // console.log(body)
            mdtoast.success("Student Successfully Created", { duration: 1000 })
            history.push("/inventory/purchase")
        } catch (err) {
            mdtoast.error("Error Occured", { duration: 1000 })
        }
        // console.log(tableItems)
    }

    useEffect(() => {
        var x = ""
        productdata.forEach((prod) => {
            if (prod.id == itemname.value) {
                x = prod.selling_price
            }
            setUnitprice(x)
        })
    }, [itemname])

    useEffect(() => {
        var tot = 0
        tableItems.forEach((item) => {
            tot += (item["Unit Price"] * item["Quantity"])
        })
        setSumtotal(tot)
        if (discounttype.value == "percentage") {
            if (discount < 100 && discount >= 0) {
                tot = (1 - discount / 100) * tot
            } else {
                tot = 0
            }
        } else {
            if (discount > tot) {
                tot = 0
            } else {
                tot = tot - discount
            }
        }
        setTotalamount(tot)
    }, [tableItems, discounttype, discount])

    const handleAdd = () => {
        var x = []
        tableItems.forEach((item) => {
            if (item.id) {
                x.push(item)
            }
        })
        setTableItems([...x, { "id": itemname.value, "Item Name": itemname.label, "Unit Price": unitprice, "Quantity": quantity, "Action": "action" }])
        setItemname("")
        setUnitprice("")
        setQuantity("")
    }

    return (
        <>
            <NavBar />
            <div className="bg-gray-200 py-5">
                <div className="text-sm  bg-white pt-4 px-5 pb-4  w-11/12 mx-auto rounded-md">
                    <p className="text-xl font-bold gg-erp-color ">ADD ITEM RECEIVE</p>
                    <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-2">

                        {/* <label for="username" className="w-full py-2">Username
                    <input className="form-input" disabled={true} type="text" placeholder="Username" name="username" onChange={e => setUsername(e.target.value)} />
                </label> */}

                        <label for="department" className="w-full py-2">Supplier Name
                            <Select
                                options={supplierlist.map((item) => { return { "label": item.name, "value": item.id } })}
                                value={suppliername}
                                onChange={(option) => setSuppliername(option)}
                                placeholder="Enter Here..."
                                isClearable={true}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        primary25: 'lightCyan',
                                        primary: '#ff5722',
                                    },
                                })}
                            />
                        </label>

                        <label for="dob" className="w-full py-2">Date
                            <input type="date" placeholder="dd-mm-yyyy" name="dob" className="form-input" value={date} onChange={e => setDate(e.target.value)} />
                        </label>

                        <label for="username" className="w-full py-2">Invoice Number
                            <input className="form-input" type="number" placeholder="Enter Here.." name="username" value={invoice} onChange={e => setInvoice(e.target.value)} />
                        </label>

                        <label for="department" className="w-full py-2">Discount Type
                            <Select
                                options={discountlist}
                                value={discounttype}
                                onChange={(option) => setDiscounttype(option)}
                                placeholder="Enter Here..."
                                isClearable={true}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        primary25: 'lightCyan',
                                        primary: '#ff5722',
                                    },
                                })}
                            />
                        </label>

                        <label for="username" className="w-full py-2">Discount
                            <input className="form-input" type="number" placeholder="Enter Here.." name="username" value={discount} onChange={e => setDiscount(e.target.value)} />
                        </label>

                        <label for="username" className="w-full py-2">Sum Total
                            <input className="form-input" type="number" placeholder="Enter Here.." name="username" value={sumtotal} onChange={e => setSumtotal(e.target.value)} />
                        </label>

                        <label for="username" className="w-full py-2">Amount Total
                            <input className="form-input" type="number" placeholder="Enter Here.." name="username" value={totalamount} onChange={e => setTotalamount(e.target.value)} />
                        </label>
                        <br />

                        <label for="department" className="w-full py-2">Item Name
                            <Select
                                options={productdata.map((item) => { return { "label": item.name, "value": item.id } })}
                                value={itemname}
                                onChange={(option) => setItemname(option)}
                                placeholder="Enter Here..."
                                isClearable={true}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        primary25: 'lightCyan',
                                        primary: '#ff5722',
                                    },
                                })}
                            />
                        </label>


                        <label for="username" className="w-full py-2">Unit Price
                            <input className="form-input" type="number" disabled placeholder="Enter Here.." name="username" value={unitprice} onChange={e => setUnitprice(e.target.value)} />
                        </label>

                        <label for="username" className="w-full py-2">Quanity
                            <input className="form-input" type="number" placeholder="Enter Here.." name="username" value={quantity} onChange={e => setQuantity(e.target.value)} />
                        </label>
                    </div>

                    <div className="text-right mt-2" style={{ width: "100%" }}>
                        <button type="submit" style={{ width: "100%" }} className="submit" onClick={handleAdd}>Add</button>
                    </div>



                    <div className=" bg-white py-3 mx-auto rounded-b-md md:rounded-md">
                        <Table tableItems={tableItems} setTable={setTableItems} DeleteFunction={DeleteFunction} />
                    </div>


                    <div className="text-right mt-2" style={{ width: "100%" }}>
                        <button type="submit" style={{ width: "100%" }} className="submit" onClick={handlePost}>Submit</button>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default AddPurchase