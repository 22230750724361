import React, { useEffect, useState } from 'react';
import DropDownMenu from './DropDownMenu';
import MobileMenu from './MobileMenu';
import UserDropdown from './UserDDM';
import { useSelector, useDispatch  } from 'react-redux';
import { isExpired, decodeToken } from "react-jwt";
import { setID, setUsername } from '../../redux/user';
import axios from 'axios';
import { SERVER } from '../../utils';
import { authHeader } from '../../helper/authHeader';
import mdtoast from '@dmuy/toast';

export default function NavBar() {
  const { institute, sessionLoaded } = useSelector(state => state.info)
  const { id, username } = useSelector(state => state.user)
  const dispatch = useDispatch()
  const [session, setSession] = useState([])

  if(id === null){
    const user = JSON.parse(localStorage.getItem('user'))
    const decoded = decodeToken(user.access)
    console.log(decoded.user_id)
    dispatch(setID(decoded.user_id))
    console.log({id, username})
  }

  const navigation = [
    { name: "Dashboard", list: [], link: "/dashboard" },
    { name: "Shop", list: [],link: "/inventory/shop" },
    { name: "Item Category", list: [], link: "/inventory/category" },
    { name: "Add Item", list: [],link: "/inventory/add" },
    { name: "Item Bundle", list: [],link: "/inventory/bundle" },
    { name: "Supplier List", list: [],link: "/inventory/supplier" },
    { name: "Purchase", list: [],link: "/inventory/purchase" },
    { name: "Sell", list: [],link: "/inventory/sell" },
    
    // { name: "Configuration", list: [{name:"Session", link:"configuration/session"},
    //                                 {name:"Designation", link:"configuration/designation"},
    //                                 {name:"College Info", link:"configuration/info"},
    //                                 {name:"SMS Settings", link:"configuration/sms"}
    //                               ] },

    // { name: "Academic", list: [{name:"Course", link:"academic/course"},
    //                             {name:"Semester", link:"academic/semester"},
    //                             {name:"Department", link:"academic/department"},
    //                             {name:"Semester-Session", link:"academic/semester-session"},
    //                             {name: "Extra Subject", link: "academic/extra"}
    //                           ] },

    // { name: "Communicate", list: [{name:'Group SMS', link: "communicate/sms/group"}, 
    //                               {name:'Particular Student SMS', link: "communicate/sms/student"},
    //                               {name:'Particular Staff SMS', link: "communicate/staff"},
    //                               {name:'Particular Number SMS', link: "communicate/number"},
    //                               {name:'Holiday', link: "communicate/holiday"},
    //                               {name:'Event', link: "communicate/event"},
    //                               {name:'Postal', link: "communicate/postal"}
    //                             ] },

    // { name: "Manage Students", list: [{name:'Admission',link: "students/admission"},
    //                                   {name:'Manage Current Student',link: "students/current"},
    //                                   {name:'Manage Passout Student',link: "students/passout"},
    //                                   {name:'Manage Dropout Student',link: "students/dropout"},
    //                                   {name:'Dropout/Passout Student',link: "students/manage"},
    //                                   {name:'Certificates',link: "students/certificates"}
    //                                 ] },

    // { name: "Manage Staff", list: [{name: "Add Staff", link: "staff/add"},
    //                                 {name: "Manage Staff", link: "staff/manage"}
    //                               ] },

    // { name: "Student fee", list: [{name:'Fee Type',link: "students/fee/type"},
    //                               {name:'Fee Group',link: "students/fee/group"},
    //                               {name:'Fee Term',link: "students/fee/term"},
    //                               {name:'Multi Group Assign',link: "students/assign"},
    //                               {name:'Student Back Dues',link: "students/dues"},
    //                               {name:'Fee Payment',link: "students/payment"},
    //                               {name:'Fee Demand Notice',link: "students/notice"},
    //                               {name:'Fee Reminder',link: "students/reminder"}
    //                             ] },

    // { name: "Accounts", list: [{name:"Income Category", link:"accounts/IncomeCategory"},
    //                             {name:"Expense Category", link:"accounts/ExpenseCategory"},
    //                             {name:"Account Head", link:"accounts/AccountHead"},
    //                             {name:"Income", link:"accounts/Income"},
    //                             {name:"Expenses", link:"accounts/Expenses"},
    //                             {name:"Income Report", link:"accounts/IncomeReport"},
    //                             {name:"Expense Report", link:"accounts/ExpenseReport"},
    //                             {name:"Daily/Monthly Account Report", link:"accounts/AccountReport"},
    //                             {name:"Overall Accounts Summary", link:"accounts/AccountSummary"}
    //                           ] },
    
    // { name: "Reports", list: [{name: 'Receipt Wise Fee Collection', link: 'reports/receipt'},
    //                           {name: 'Fee Collection Report', link: 'reports/fee/collection'},
    //                           {name: 'Semester Wise Fee Collection Report', link: 'reports/semester'},
    //                           {name: 'Fee Balance Report', link: 'reports/balance'},
    //                           {name: 'Semester Wise Balance Report', link: 'reports/semester/balance'},
    //                           {name: 'Fee Adjustment Report', link: 'reports/adjustment'},
    //                           {name: 'Course Wise Balance Summary', link: 'reports/course'},
    //                           {name: 'Student With More Balance', link: 'reports/student/balance'},
    //                           {name: 'Deleted Fee Receipt Report', link: 'reports/deleted'},
    //                           {name: 'Dise Report By Religion', link: 'reports/religion'},
    //                           {name: 'Dise Report By Category', link: 'reports/category'},
    //                           {name: 'Dise Report By Gender', link: 'reports/gender'}
    //                           ] }
  ]

  // useEffect(() => {
  //   const getSession = async ()=>{
  //     try{
  //         var res = await axios.get( SERVER + "/apiV1/session" , { headers: authHeader() });
  //         if (res.data.length>0){
  //             var newtable = res.data.map(item=>{
  //                 return {"id": item.id, "name": item.session_name, "Start Date": item.start_date, "End Date": item.end_date, "Current Session": item.current_session, "Status" : item.status , "Action" : "Action" }
  //             })
  //             setSession(newtable)
  //         }
  //     }catch(err){
  //         console.log(err);
  //     }
  //   }
  //   getSession()
  // },[sessionLoaded])

//   const current = async (id) => {
//     try{
//         const url = SERVER + "/apiV1/active-session/" + id + "/"
//         const res = await axios.get(url, { headers: authHeader() });
//         mdtoast.success("Current Session Successfully Updated", {duration: 500})
//     }catch (err){
//         console.log(err)
//         mdtoast.error("Error Occured", { duration: 1000 })
//     }
// }


  return (
    <>
    <div className="hidden bg-gray-800 text-white xl:flex justify-center items-center px-1 py-1 overflow-hidden flex-1">
            {navigation.map(item => {
              return (
                <DropDownMenu item={item} block={false}/>
              )
            })}
      
    </div>
    <div className="px-2 py-3 text-center flex justify-between bg-orng items-center">
        <div className="font-bold text-center text-2xl text-white px-2">
          {institute === "" ? "Demo College" : institute}
        </div>
        <div className="flex items-center gap-2">
            {/* <div className="flex gap-1 text-white p-1 text-sm items-center bg-yellow-400 px-1 cursor-pointer rounded-md select-none">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z">
                    </path>
                </svg>
                123
            </div> */}

            <UserDropdown/>
        </div>
        </div>
        <MobileMenu navigation={navigation}/>
    </>

  );
}