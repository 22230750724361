import React , {useEffect , useState} from "react";
import SessionForm from "../../components/forms/Configuration/Session";
import Navbar from "../../components/navbar-components/NavBar"
import Footer from "../../components/Footer"
import Table from "../../components/Table";
import axios from "axios";
import { SERVER } from "../../utils";
import mdtoast from '@dmuy/toast'
import { useDispatch  } from 'react-redux';
import { setSessionLoaded } from "../../redux/info";
import { authHeader } from "../../helper/authHeader"
// import Switch from "react-switch";
// import Empty from "../../components/Empty";

const Session = () => {
    const dispatch = useDispatch()

    const [tableItems , setTableItems] = useState([
        {id: null,"Session Name": 0, "Start Date": 0, "End Date": 0, "Current Session": 0, "Status" : 0 , "Action" : 0 , empty:true },
    ])

    const [requesttype , setRequesttype] = useState("post")
    const[currentsession , setCurrentsession] = useState({
        'id' : null,
        'session' : '',
        'start' : '',
        'end' : '',
        'current_session' : false,
        'status' : true
    })

    const updatetable = async ()=>{
        try{
            var res = await axios.get("/apiV1/session" , { headers:authHeader()});
            console.log(res.data)
            if (res.data.length>0){
                var newtable = res.data.map(item=>{
                    return {"id": item.id, "Session Name": item.session_name, "Start Date": item.start_date, "End Date": item.end_date, "Current Session": item.current_session, "Status" : item.status , "Action" : "Action" }
                })
                setTableItems(newtable)
                dispatch(setSessionLoaded())
            }
        }catch(err){
            console.log(err);
        }
    }

    const EditFunction =  async (item)=>{
    setRequesttype("put");
    setCurrentsession({
        'id' : item.id,
        'session' : item['Session Name'],
        'start' : item["Start Date"],
        'end' : item["End Date"],
        'current_session' : item["Current Session"],
        'status' : item["Status"]
    })
    }

    const DeleteFunction =  async (id)=>{
        // console.log(id + "has been deleted")
        try{
            // const url = SERVER + "/apiV1/session/" + id
            const res = await axios.delete(`/apiV1/session/${id}/`, { headers: authHeader() });
            mdtoast.success("Session Deleted Successfully ", { duration: 1000 })
            setTableItems(tableItems.filter((item)=>item.id !== id))
        }catch (err) {
            console.log(err)
            mdtoast.error("Sessions are dependent and cannot be deleted", { duration: 1000 })
        }
    }

    const current = async (id) => {
        try{
            // const url = SERVER +  + id + "/"
            const res = await axios.get(`/apiV1/active-session/${id}/`, { headers: authHeader() });
            mdtoast.success("Session Updated Successfully", { duration: 1000 })
            updatetable()
        }catch (err){
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }
    }

    useEffect(async ()=>{
        updatetable()
    },[window.url])

    return (
        <>
        <Navbar/>
        <div className="bg-gray-200">
            <div className="mx-auto md:flex md:w-11/12 justify-center bg-gray-200 py-5">
                <div className="md:w-3/12 flex-shrink-0">
                    <SessionForm updatetable={updatetable} currentsession = {currentsession} requesttype ={requesttype} setCurrentsession = {setCurrentsession} setRequesttype = {setRequesttype}/>
                </div>
                <div className="flex items-center bg-white py-3 w-11/12 md:w-9/12 mx-auto rounded-b-md md:rounded-md">
                    <div className="w-full">
                        <Table tableItems={tableItems} current={current} EditFunction={EditFunction} DeleteFunction={DeleteFunction}/>
                    </div> 
                </div>
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default Session