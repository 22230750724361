import React, { useState } from "react";
import useDebouncedSearch from "../../helper/debounce";
import Search from "../Search";
import axios from 'axios';
import { formServices } from "../../services/formServices";

const EditProfileForm = () => {
    const [profilepicture, setProfilepicture] = useState("")
    const [name, setName] = useState("");
    const [contactno, setContact] = useState("");
    const [whatsappno, setWhatsappno] = useState("");
    const [email, setEmail] = useState("");
    const [newpassword, setNewpassword] = useState("");
    const [oldpassword, setOldpassword] = useState("");
    const [confirmpassword, setConfirmpassword] = useState("");

    const handlePost = () => {
        return formServices.editprofilePost(profilepicture, name, email, contactno, whatsappno)
    }

    const handlePassword = () => {
        // return formServices.editprofilePost(profilepicture, name, email, contactno, whatsappno, password)
    }

    const handleEditpicture = () => {
        return "";
    }


    return (
        <div className="md:flex">
            <div className="text-sm bg-white pt-4 px-5 pb-4 w-11/12 md:w-6/12 mx-auto rounded-t-md md:rounded-md">
                <p className="text-xl font-bold gg-erp-color ">Edit Profile</p>
                <div>
                    <img src='../' />
                </div>
                <div className="text-left mt-2">
                    <button type="submit" className="submit" onClick={handleEditpicture}>Edit Profile Picture</button>
                </div>
                <label for="usr" className="w-full py-2">Name
                    <input className="form-input" type="text" placeholder="Enter Here..." name="usr" onChange={e => setName(e.target.value)} />
                </label>

                <label for="crse" className="w-full py-2">Contact No.
                    <input className="form-input" type="text" placeholder="Enter Here..." name="crse" onChange={e => setContact(e.target.value)} />
                </label>

                <label for="roll_no" className="w-full py-2">Whatsapp No.
                    <input className="form-input" type="text" placeholder="Enter Here..." name="roll_no" onChange={e => setWhatsappno(e.target.value)} />
                </label>

                <label for="adm_no" className="w-full py-2">Email
                    <input className="form-input" type="text" placeholder="Enter Here..." name="adm_no" onChange={e => setEmail(e.target.value)} />
                </label>

                <hr />
                <div className="text-right mt-2">
                    <button type="submit" className="submit" onClick={handlePost}>Submit</button>
                </div>

            </div>
            <div className="text-sm bg-white pt-4 px-5 pb-4 w-11/12 md:w-4/12 mx-auto rounded-b-md md:rounded-md">
            <p className="text-xl font-bold gg-erp-color ">Change Password</p>
                <label for="adm_no" className="w-full py-2"> Old Password
                    <input className="form-input" type="text" placeholder="Enter Here..." name="adm_no" onChange={e => setOldpassword(e.target.value)} />
                </label>
                <label for="adm_no" className="w-full py-2">New Password
                    <input className="form-input" type="text" placeholder="Enter Here..." name="adm_no" onChange={e => setNewpassword(e.target.value)} />
                </label>
                <label for="adm_no" className="w-full py-2">Confirm Password
                    <input className="form-input" type="text" placeholder="Enter Here..." name="adm_no" onChange={e => setConfirmpassword(e.target.value)} />
                </label>
                <div className="text-right mt-2">
                    <button type="submit" className="submit" onClick={handlePassword}>Submit</button>
                </div>
            </div>
        </div>

    )
}

export default EditProfileForm