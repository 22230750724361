import React, { useState } from "react";
import { formServices } from "../../../services/formServices";

const GroupForm = () => {
    const [superadmin , setSuperadmin] = useState("");
    const [admin , setAdmin] = useState("");
    const [teacher , setTeacher] = useState("");
    const [librarian , setLibrarian] = useState("");
    const [employee , setEmployee] = useState("");
    const [driver , setDriver] = useState("");
    const [parent , setParent] = useState("");
    const [student , setStudent] = useState("");
    const [principal , setPrincipal] = useState("");
    const [academicinc , setAcademicinc] = useState("");
    const [message , setMessage] = useState("");

    const handlePost = () => {
        return formServices.groupPost(superadmin, admin , teacher , librarian , employee , driver , parent , student , principal , academicinc , message)
    }

    return (
        <div className="text-sm bg-white pt-4 px-5 pb-4 w-11/12 mx-auto rounded-md">
            <p className="text-xl font-bold gg-erp-color ">GROUP</p> 
            <div className="grid md:grid-cols-2 gap-2">
                <div className="md:w-11/12">
                <label for="message" className="mb-2 w-full py-2">Message:
                    <textarea className="" type="text" name="message" onChange={e => setMessage(e.target.value)}/>
                </label>
                <p className="text-sm">Character Count: {message.length}</p>
                </div>

                <div className="md:flex items-stretch">
                    <div className="flex-grow">
                        <p className="mt-1">Send To:</p>

                        <input type="checkbox" value="SUPERADMIN" name="superadmin" onChange={e => setSuperadmin(e.target.value)}/><label for="superadmin">SUPERADMIN</label><br/>
                        <input type="checkbox" value="ADMIN" name="admin" onChange={e => setAdmin(e.target.value)}/><label for="admin">ADMIN</label><br/>
                        <input type="checkbox" value="TEACHER" name="teacher" onChange={e => setTeacher(e.target.value)}/><label for="teacher">TEACHER</label><br/>
                        <input type="checkbox" value="LIBRARIAN" name="librarian" onChange={e => setLibrarian(e.target.value)}/><label for="librarian">LIBRARIAN</label><br/>
                        <input type="checkbox" value="EMPLOYEE" name="employee" onChange={e => setEmployee(e.target.value)}/><label for="employee">EMPLOYEE</label><br/>
                    </div>

                    <div className="md:mt-6 flex-grow">
                        <input type="checkbox" value="DRIVER" name="driver" onChange={e => setDriver(e.target.value)}/><label for="driver">DRIVER</label><br/>
                        <input type="checkbox" value="PARENT" name="parent" onChange={e => setParent(e.target.value)}/><label for="parent">PARENT</label><br/>
                        <input type="checkbox" value="STUDENT" name="student" onChange={e => setStudent(e.target.value)}/><label for="student">STUDENT</label><br/>
                        <input type="checkbox" value="PRINCIPAL" name="principal" onChange={e => setPrincipal(e.target.value)}/><label for="principal">PRINCIPAL</label><br/>
                        <input type="checkbox" value="ACADEMICINC" name="academicinc" onChange={e => setAcademicinc(e.target.value)}/><label for="academicinc">ACADEMICINC</label>
                    </div>
                </div>
            </div>
            <hr className="mt-3"/>
            <div className="text-right mt-2">
                <button type="submit" className="submit" onClick={handlePost}>Send SMS</button>
            </div>
 
        </div>
    )
}

export default GroupForm