import { Fragment, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import DropDownMenu from './DropDownMenu'

const MobileMenu = (props) => {
  const {navigation} = props
  const [open, setOpen] = useState(false)

  return (
      <div className={"bg-gray-200 z-50" + (open ? "" : " fixed")}>
          <Popover>
                <div className="-mr-2 xl:hidden" onClick={() => { return setOpen(!open)}}>
                    <Popover.Button className="shadow-md bg-white rounded-r-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Open main menu</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 gg-erp-color" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </Popover.Button>
                </div>
            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-md bg-gray-800 ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="px-5 pt-4 flex items-right justify-between">
                    <a href="#">
                      <span className="sr-only">Workflow</span>
                    </a>
                    <div className="-mr-2 " onClick={() => { return setOpen(!open)}}>
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-600 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </Popover.Button>
                    </div>
                  </div>
                  <div className=" pt-2 pb-3 space-y-1">
                    {navigation.map((item) => (
                        <DropDownMenu item={item} block={true}/>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
      </div>
  )
}

export default MobileMenu