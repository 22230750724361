import React from "react";
import Navbar from "../../components/navbar-components/NavBar"
import Footer from "../../components/Footer"
import InfoForm from "../../components/forms/Configuration/Info"

const Info = () => {
    return (
        <>
        <Navbar/>
        <div className="bg-gray-200 py-5">
            <InfoForm/>
        </div>
        <Footer/>
        </>
    )
}

export default Info