import React, {useEffect, useState} from "react";
import NavBar from "../../components/navbar-components/NavBar";
import Footer from "../../components/Footer";
import Table from "../../components/Table";
import { Link } from "react-router-dom";
import { authHeader } from "../../helper/authHeader";
import axios from "axios";
import mdtoast from "@dmuy/toast";

const tableItems = [
    {"Class": null, "General": null, "OBC": null, "EBC": null, "SC": null, "ST": null,empty: true}
]
// "name": "Arthur",
// "company_name": "indain pole",
// "company_address": "Affiliated to CBSE New Delhi Upto (10+2) Level, Ashiyana-Digha Road, Nepali Nagar, Near Aasra Home Patna-800025.",
// "email": "contact@gungunerp.com",
// "phone_no": 1123,
// "desc": null

const Supplier = () => {

    const [tableItems , setTableItems] = useState([
        {id: null,"Name": 0, "Company Name": 0, "Contact": 0, "Email": 0, "Status" : 0 , "Action" : 0 , empty:true },
    ])

    useEffect(() => {
        updatetable()
    },[window.url])

    const updatetable = async () => {
        try{
            var res = await axios.get("/api/v1/supplier" , { headers:authHeader()});
            console.log(res.data)
            let count = 0
            if (res.data.length>0){
                var newtable = res.data.map(item=>{
                    count++
                    return {id: item.id,extra: false,"Sr. No": count,"Name": item.name, "Company Name": item.company_name, "Contact": item.phone_no, "Email": item.email, "Status" : true , extraInfo: {Address:item.address, Description: item.desc} ,"Action" : "action"}
                })
                setTableItems(newtable)
            }
        }catch(err){
            console.log(err);
        }
    }

    const DeleteFunction =  async (id)=>{
        try{
            const res = await axios.delete(`/api/v1/supplier/${id}/`, { headers: authHeader() });
            mdtoast.success("Supplier Deleted Successfully ", { duration: 1000 })
            setTableItems(tableItems.filter((item)=>item.id !== id))
        }catch (err) {
            console.log(err)
            mdtoast.error("Error Occured", { duration: 1000 })
        }
    }

    return (
        <>
        <NavBar/>
        <div className="bg-gray-200">
            <div className="mx-auto w-full bg-gray-200 py-5">
                <div className="w-11/12 mx-auto pt-4 rounded-t-md bg-white">
                    <div className="w-11/12 mx-auto">
                        <p className="text-xl font-bold gg-erp-color ">Supplier List</p>
                        <Link to="/inventory/supplierform">
                            <button type="submit" className="submit px-3 py-1 text-sm">Add Supplier</button> 
                        </Link>
                    </div>
                </div>
                <div className="pb-4 bg-white py-3 w-11/12 mx-auto rounded-b-md">
                    <Table setTable={setTableItems} tableItems={tableItems} DeleteFunction={DeleteFunction} />
                </div>
            </div>
        </div>
        <Footer/>
        </> 
    )
}

export default Supplier